<div *ngIf="calculationError; then error else analytics">

</div>
<ng-template #error>
    <div class="error-container">
        <p class="warning-message">Calculation Failed. Please try again later.</p>
    </div>
</ng-template>
<ng-template #analytics>
    <div class="drawdown-chart-drawer"
         *ngIf="strategyPools && drawdownData; else loading">
        <div class="arrow">
            <div class="line"
                 [ngStyle]="{'border-right-color': scenarioCardBorderColor}"></div>
            <div class="pointer"
                 [ngStyle]="{'border-top-color': scenarioCardBorderColor}"></div>
        </div>
        <div *ngIf="isFormDirty">
            <sei-car-dfs-alert-notification [alertOptions]="alertOptions"></sei-car-dfs-alert-notification>
        </div>
        <div class="charts-container">
            <div class="drawdown-chart-container">
                <p class="sub-header">{{strategyName}}</p>
                <sei-car-drawdown-chart [drawdownData]="drawdownData"></sei-car-drawdown-chart>
            </div>
            <div class="strategy-breakdown-container">
                <p class="sub-header">Summary</p>
                <div class="statistics-container">
                    <div class="statistic-card">
                        <span class="statistic">{{getDuration()}}</span>
                        <p class="statistic-label">Time Horizon (Years)</p>
                    </div>
                    <div class="statistic-card">
                        <span class="statistic">{{getConfidenceLevel()}}</span>
                        <p class="statistic-label">Confidence Level</p>
                    </div>
                </div>
                <p class="sub-header">Strategy Breakdown</p>
                <sei-car-strategy-breakdown-chart [strategyPools]="strategyPools"></sei-car-strategy-breakdown-chart>
            </div>
        </div>
    </div>
    <div class="clear-values-button-container"
         *ngIf="strategyPools && drawdownData">
        <button (click)="clearValues()"
                class="clear-values-button"
                [ngStyle]="{ 'border-color': scenarioCardBorderColor }">
            Clear Values
        </button>
    </div>
    <div class="add-to-proposal-button-container"
         *ngIf="strategyPools && drawdownData">
         <div class="replace-alert"
              *ngIf="showReplaceStarategyAlert">
             <div class="alert-text-box">
                 <div><i class="fa-fw fa-lg fa-solid fa-triangle-exclamation"></i></div>
                 <div class="alert-text-offet">{{ replaceStrategyAlertText }}</div>
             </div>
             <span class="action-buttons-container">
                <button class="action-button"
                    (click)="replaceStrategyCancel()">Cancel</button>
                <button class="action-button"
                    (click)="replaceStrategyConfirm()">Continue</button>
             </span>
        </div>
        <button (click)="addToProposal()"
                [matTooltip]="toolTipMessages.RECALCULATE_TO_CONTINUE"
                [matTooltipDisabled]="!isFormDirty"
                matTooltipPosition="above"
                class="add-to-proposal-button"
                [disabled]="!canStrategyBeAddedToAccount || isFormDirty"
                [class.button-disabled]="!canStrategyBeAddedToAccount || isFormDirty"
                [ngStyle]="{'border-color': scenarioCardBorderColor, 'background-color': scenarioCardBorderColor}">
            Add to Account
        </button>
    </div>
    <div *ngIf="strategyPools && drawdownData">
        <span class="disclosure"><span class="disclosure-label">Important Information: </span>The probability distributions are generated using SEI's proprietary modeling tool and simulated capital market behavior.
        Capital market behavior is simulated for 1,000 possible scenarios based on expected performance of each asset class and reflecting current economic conditions.
        We use these 1,000 scenarios to create 1,000 output scenarios for each variable being considered.
        This projection is hypothetical in nature, does not reflect actual investment results and is not a guarantee of future results.</span>
    </div>
</ng-template>
<ng-template #loading>
    <div class="loading-container">
        <sei-car-dfs-loading-spinner [spinnerSize]="spinnerSize"></sei-car-dfs-loading-spinner>
    </div>
</ng-template>
