<div class="form-inline">
    <div class="form-group"
         [class.has-error]="hasError">
        <div class="input-group">
            <input class="form-control"
                   type="text"
                   [(ngModel)]="inputText"
                   placeholder="required"
                   autofocus>
            <span *ngIf="showDeleteButton" class="input-group-addon"
              (click)="onRemoveNameClick()">
                <a><i class="fas fa-times-circle"></i></a>
            </span>
            <p class="help-block"
               *ngIf="hasError">{{ errorMessage }}</p>
        </div>
        <a>
            <i class="fas fa-check"
            (click)="onEditConfirmClick()">
            </i>
        </a>
    </div>
</div>
