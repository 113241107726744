/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DfsData, DistributionAnalyticsResponse, ScenarioCardFormAndChartData } from '../model/dfs-illustrator.model';
import { ScenarioCardComponent } from '../scenario-card/scenario-card.component';
import { DfsIllustratorService } from '../service/dfs-illustrator.service';

@Component({
    selector: 'sei-car-dfs-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    standalone: true,
    imports: [CommonModule, ScenarioCardComponent]
})
export class CarouselComponent implements OnDestroy, OnInit {

    @Input() public scenarioCards: ScenarioCardFormAndChartData[] = [];
    @Input() public distributionAnalyticsResponse: DistributionAnalyticsResponse;
    @Input() public startingIndex: number = 0;
    @Input() public doesAccountAlreadyHaveADfsStrategy: boolean = false;
    @Input() public addScenarioCardBorderColor: string;
    @Input() public backupFormGroupForFormReset: FormGroup;
    @Input() public isYShareAllowedForDfsIllustrator: boolean = false;
    @Output() public dfsIllustratorDeleted: EventEmitter<number> = new EventEmitter(undefined);
    @Output() public newScenarioCardClicked: EventEmitter<boolean> = new EventEmitter(false);

    public currentActiveIndex: number = 0;
    public isNavigationEnabled: boolean = true;
    private isFirstNavigation: boolean = true;
    private maxIndex: number = 0;
    private readonly NAVIGATION_TIMEOUT: number = 300;

    constructor(private dfsIllustratorService: DfsIllustratorService) { }

    public ngOnInit(): void {
        this.maxIndex = this.scenarioCards?.length - 1;
        if (this.startingIndex > 0) {
            setTimeout(() => {
                this.currentActiveIndex = this.startingIndex;
                this.handleNavigation('right', this.startingIndex);
            }, this.NAVIGATION_TIMEOUT);
        }
    }

    public ngOnDestroy(): void {
        this.dfsIllustratorService.isCarouselModeActive.next(undefined);
    }

    public handleNavigation(direction: string, offSet: number = 1): void {
        if (this.isNavigationEnabled) {
            setTimeout(() => {
                this.isNavigationEnabled = true;
            }, 700);
            this.isNavigationEnabled = false;
            const carouselList: HTMLElement = document.querySelector('.list');
            const carouselItem: HTMLElement = document.querySelector('.item');
            const itemWidth: number = (carouselItem?.offsetWidth * offSet);
            const moveLeft: boolean = direction === 'left';
            if (!moveLeft) {
                if (this.currentActiveIndex < this.maxIndex || this.isFirstNavigation) {
                    if (!this.isFirstNavigation || this.currentActiveIndex === 0) {
                        this.currentActiveIndex++;
                    }
                    this.isFirstNavigation = false;
                    carouselList.scrollBy({ left: moveLeft ? -itemWidth : itemWidth, behavior: 'smooth' });
                }
            } else {
                if (this.currentActiveIndex > 0) {
                    this.currentActiveIndex--;
                    carouselList.scrollBy({ left: moveLeft ? -itemWidth : itemWidth, behavior: 'smooth' });
                }
            }
        }
    }

    public getAnalyticsResponseForDfsData(dfsData: DfsData,
        distributionAnalyticsResponse: DistributionAnalyticsResponse): DistributionAnalyticsResponse {
        return this.dfsIllustratorService.getAnalyticsResponseForDfsData(dfsData, distributionAnalyticsResponse);
    }

    public deleteDfsIllustrationByIndex(indexToDelete: number): void {
        const isLastElement: boolean = (this.dfsIllustratorService.MAX_ALLOWED_DFS_SCENARIOS - 1) === indexToDelete;
        this.maxIndex--;
        this.dfsIllustratorDeleted.emit(indexToDelete);
        if (isLastElement) {
            setTimeout(() => {
                this.handleNavigation('left');
            }, this.NAVIGATION_TIMEOUT);
        } else {
            this.currentActiveIndex--;
        }
    }

    public addNewScenario(): void {
        this.newScenarioCardClicked.emit(true);
        setTimeout(() => {
            this.maxIndex++;
            this.handleNavigation('right');
        }, this.NAVIGATION_TIMEOUT);
    }
}
