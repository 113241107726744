/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { StrategyPool } from '@CarComponents/dfs-illustrator/model/dfs-illustrator.model';
import { StrategyBreakdownPoolColor, StrategyBreakdownPoolType } from '@CarComponents/dfs-illustrator/model/enums';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'sei-car-strategy-breakdown-chart',
    templateUrl: './strategy-breakdown-chart.component.html',
    standalone: true,
    imports: [CommonModule]
})
export class StrategyBreakdownChartComponent {
    @Input()
    set strategyPools(value: StrategyPool[]) {
        if (value) {
            this._strategyPools = value;
            setTimeout(() => {
                this.createChart();
            });
        }
    }
    get strategyPools(): StrategyPool[] {
        return this._strategyPools;
    }

    @ViewChild('chartContainer', { static: true })
    private chartContainer: ElementRef;

    private _strategyPools: StrategyPool[];
    private chartData: Highcharts.Options;
    private readonly CHART_FONT_SIZE: string = '14px';

    private createChart(): void {
        this.createChartData();
        Highcharts.chart(this.chartContainer.nativeElement, this.chartData);
    }

    private createSeriesData(): Highcharts.SeriesPieOptions {
        const aggressivePool: StrategyPool = this.strategyPools
            ?.find((pool: StrategyPool) => pool?.description === StrategyBreakdownPoolType.AGGRESSIVE);
        const moderatePool: StrategyPool = this.strategyPools
            ?.find((pool: StrategyPool) => pool?.description === StrategyBreakdownPoolType.MODERATE);
        const defensivePool: StrategyPool = this.strategyPools
            ?.find((pool: StrategyPool) => pool?.description === StrategyBreakdownPoolType.DEFENSIVE);
        const data: Highcharts.PointOptionsObject[] = [];
        if (aggressivePool) {
            data.push({
                name: aggressivePool?.description,
                y: aggressivePool?.percentage,
                color: StrategyBreakdownPoolColor.AGGRESSIVE
            });
        }
        if (moderatePool) {
            data.push({
                name: moderatePool?.description,
                y: moderatePool?.percentage,
                color: StrategyBreakdownPoolColor.MODERATE
            });
        }
        if (defensivePool) {
            data.push({
                name: defensivePool?.description,
                y: defensivePool?.percentage,
                color: StrategyBreakdownPoolColor.DEFENSIVE
            });
        }
        const pieData: Highcharts.SeriesPieOptions = {
            type: 'pie',
            name: '',
            data,
            /* This method of disabling clicking in the legend is deprecated in newer versions of Highcharts.
               For version 11.4.4 or newer the option should be controlled in legend.events.itemClick */
            point: {
                events: {
                    legendItemClick() {
                        return false;
                    }
                }
            }
        };
        return pieData;
    }

    private createChartData(): void {
        this.chartData = {
            chart: {
                type: 'pie',
                height: 350
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            title: {
                text: '',
                margin: 0
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    innerSize: '60%',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            tooltip: {
                style: {
                    fontSize: this.CHART_FONT_SIZE
                },
                pointFormat: '<b>{point.percentage:.1f}%</b>'
            },
            series: [
                this.createSeriesData()
            ],
            legend: {
                layout: 'vertical',
                align: 'center',
                verticalAlign: 'bottom',
                squareSymbol: true,
                symbolRadius: 0,
                itemStyle: {
                    fontSize: this.CHART_FONT_SIZE
                },
                labelFormatter() {
                    const point: Highcharts.Point = this as Highcharts.Point;
                    const svgElements: string = `<tspan><tspan>${point.name}</tspan>` +
                        `<tspan x="105">${point.percentage.toFixed(2)}%</tspan></tspan>`;
                    return svgElements;
                }
            },
            credits: {
                enabled: false
            }
        };
    }


}
