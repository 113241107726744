<div class="row account-ribbon section-margin">
    <div class="col-xs-4">
        <label for="input-fee">Your flat fee rate</label>
    </div>
    <div class="col-xs-4" *ngIf="feeScheduleSetting === feeTypesOptions.Percentage">
        <div class="row">
            <input
                seiEditPercent
                type="text"
                [percentOptions]="percentOptions"
                class="form-control"
                placeholder="%"
                id="flatFeeRate-{{ feeGroup?.id }}"
                name="flatFeeRate"
                [class.alert]="!editPercentValid"
                (change)="onRateValueChange($event)"
                (error)="onPercentError($event)"
                [value]="flatFeeRate"
                onClick="this.select();"
                ngDefaultControl
            />
        </div>
        <div class="row">
            <car-alert-popover
                [hidden]="editPercentValid"
                [alertType]="alertPopoverTypes.Error"
                [alertMessage]="editPercentError"
            ></car-alert-popover>
        </div>
    </div>
    <div class="col-xs-4" *ngIf="feeScheduleSetting === feeTypesOptions.Bps">
        <div class="row">
            <input
                seiEditQuantity
                id="flatFeeBps-{{ feeGroup?.id }}"
                name="flatFeeBPS"
                type="text"
                class="form-control"
                [quantityOptions]="quantityOptions"
                [value]="bps"
                [class.alert]="!flatFeeValid"
                (change)="onRateValueChange($event)"
                (error)="onQuantityError($event)"
                onClick="this.select();"
                ngDefaultControl
            />
        </div>
        <div class="row">
            <car-alert-popover
                [hidden]="flatFeeValid"
                [alertType]="alertPopoverTypes.Error"
                [alertMessage]="flatFeeErrorMessage"
            ></car-alert-popover>
        </div>
    </div>
    <div class="col-xs-4 align-right">
        <car-toggle-button
            [toggleOptions]="toggleOptions"
            [selectedValue]="feeScheduleSetting"
            (onChange)="onTogglePercentBPSChange($event)"
        >
        </car-toggle-button>
    </div>
</div>

<div class="row account-ribbon section-margin">
    <div class="col-xs-6">
        <label for="input-fee">Save as my default</label>
    </div>

    <div class="col-xs-6 align-right">
        <car-toggle-button
            [toggleOptions]="toggleYesNoOptions"
            [selectedValue]="advisorFee.isDefault ? yesNoOptions.Yes : yesNoOptions.No"
            (onChange)="onToggleChangeYesNO($event)"
        >
        </car-toggle-button>
    </div>
</div>

<sei-car-fee-schedule-ribbon (onCancelEvent)="onCancelEventRibbon()" (onDoneFeeRibbonEvent)="onDoneFlatFeeRibbon()">
</sei-car-fee-schedule-ribbon>
