/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { AlertAccentColors, AlertIconClass } from '@CarComponents/dfs-illustrator/model/enums';

export interface AlertOptions {
    status: string;
    message: string;
    showRefresh?: boolean;
}

export interface AlertStyling {
    backgroundColor: string;
    borderColor: string;
    iconClass: string;
    iconColor: string;
}

export class Alert {

    public static readonly error: AlertStyling = {
        backgroundColor: AlertAccentColors.ERROR_BACKGROUND_RED,
        borderColor: AlertAccentColors.ERROR_BORDER_RED,
        iconClass: AlertIconClass.ERROR_ICON,
        iconColor: AlertAccentColors.ERROR_ICON_RED
    };

    public static readonly warning: AlertStyling = {
        backgroundColor: AlertAccentColors.WARNING_BACKGROUND_YELLOW,
        borderColor: AlertAccentColors.WARNING_BORDER_YELLOW,
        iconClass: AlertIconClass.WARNING_ICON,
        iconColor: AlertAccentColors.WARNING_ICON_YELLOW
    };

    public static readonly success: AlertStyling = {
        backgroundColor: AlertAccentColors.SUCCESS_BACKGROUND_GREEN,
        borderColor: AlertAccentColors.SUCCESS_BORDER_GREEN,
        iconClass: AlertIconClass.SUCCESS_ICON,
        iconColor: AlertAccentColors.SUCCESS_ICON_GREEN
    };

    public static readonly default: AlertStyling = {
        backgroundColor: AlertAccentColors.NOTIFICATION_BACKGROUND_BLUE,
        borderColor: AlertAccentColors.NOTIFICATION_BORDER_BLUE,
        iconClass: AlertIconClass.NOTIFICATION_ICON,
        iconColor: AlertAccentColors.NOTIFICATION_ICON_BLUE
    };

}
