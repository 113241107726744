/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertNotificationComponent } from '../alert-notification/alert-notification.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { DrawdownData, DrawdownProjection, StrategyPool } from '../model/dfs-illustrator.model';
import { AlertMessages, AlertStatus, DrawdownSeries, ScenarioCardColors, SpinnerSizes, ToolTipMessages } from '../model/enums';
import { DfsIllustratorService } from '../service/dfs-illustrator.service';
import { AlertOptions } from '../alert-notification/model/alert-styles';
import { DrawdownChartComponent } from './drawdown-chart/drawdown-chart.component';
import { StrategyBreakdownChartComponent } from './strategy-breakdown-chart/strategy-breakdown-chart.component';

@Component({
    selector: 'sei-car-drawdown-chart-drawer',
    templateUrl: './drawdown-chart-drawer.component.html',
    styleUrls: ['./drawdown-chart-drawer.component.scss'],
    standalone: true,
    imports: [AlertNotificationComponent, LoadingSpinnerComponent, StrategyBreakdownChartComponent, DrawdownChartComponent, CommonModule,
        MatTooltipModule]
})
export class DrawdownChartDrawerComponent {

    @Input() public scenarioCardBorderColor: string = ScenarioCardColors.SCENARIO_1_BLUE;

    @Input() public drawdownData: DrawdownData;

    @Input() public strategyPools: StrategyPool[];

    @Input() public strategyName: string;

    @Input() public doesAccountAlreadyHaveADfsStrategy: boolean = false;

    @Input() public canStrategyBeAddedToAccount: boolean = false;

    @Input() public calculationError: boolean = false;

    @Input() public isFormDirty: boolean = false;

    @Output() public addToProposalClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);
    @Output() public clearValuesClicked: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    public spinnerSize: SpinnerSizes = SpinnerSizes.LARGE;
    public showReplaceStarategyAlert: boolean = false;
    public replaceStrategyAlertText: string = AlertMessages.REPLACE_STRATEGY;
    public toolTipMessages: typeof ToolTipMessages = ToolTipMessages;

    public alertOptions: AlertOptions = {
        status: AlertStatus.WARNING,
        message: AlertMessages.WARNING_MODIFIED_FORM
    };

    constructor(private dfsIllustratorService: DfsIllustratorService) {
        this.dfsIllustratorService.resizeScenarioCards.subscribe(() => {
            Highcharts.charts.forEach((chart: Highcharts.Chart) => {
                setTimeout(() => chart?.reflow());
            });
        });
    }

    public getConfidenceLevel(): string {
        if (this.drawdownData) {
            const projection: DrawdownProjection =
                this.dfsIllustratorService?.getProjectionFromDrawdownData(this.drawdownData, DrawdownSeries.DIST_CONFIDENCE);
            if (projection) {
                return projection.series;
            }
        }
        return '- %';
    }

    public getDuration() {
        if (this.drawdownData) {
            return this.drawdownData.duration.toFixed(2);
        }
        return '-';
    }

    replaceStrategyConfirm(): void {
        this.addToProposalClicked.emit(true);
    }

    replaceStrategyCancel(): void {
        this.showReplaceStarategyAlert = false;
    }

    public addToProposal(): void {
        if (this.doesAccountAlreadyHaveADfsStrategy) {
            this.showReplaceStarategyAlert = true;
        } else {
            this.addToProposalClicked.emit(true);
        }
    }

    public clearValues(): void {
        this.clearValuesClicked.emit(true);
    }
}
