/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerSizes } from '../model/enums';

@Component({
    selector: 'sei-car-dfs-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule]
})
export class LoadingSpinnerComponent implements OnInit {

    @Input() public useSeiDefaultSpinner: boolean = true;
    @Input() public spinnerSize: string = SpinnerSizes.MEDIUM;
    public materialSpinnerDiameter: number = 20;

    public ngOnInit(): void {
        if (!this.useSeiDefaultSpinner) {
            this.materialSpinnerDiameter = this.setMaterialSpinnerDiameter(this.spinnerSize);
        }
    }

    private setMaterialSpinnerDiameter(spinnerSize: String): number {
        let computedSpinnerSize: number = 0;
        switch (spinnerSize) {
            case SpinnerSizes.SMALL:
                computedSpinnerSize = 30;
                break;
            case SpinnerSizes.MEDIUM:
                computedSpinnerSize = 50;
                break;
            case SpinnerSizes.LARGE:
                computedSpinnerSize = 70;
                break;
            case SpinnerSizes.XLARGE:
                computedSpinnerSize = 90;
                break;
            default:
                computedSpinnerSize = 30;
                break;
        }
        return computedSpinnerSize;
    }
}
