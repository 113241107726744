/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { HttpClient } from '@angular/common/http';
import { DfsIllustratorService } from '@CarComponents/dfs-illustrator/service/dfs-illustrator.service';
import { UserLevelEntitlement } from '../model/enums';
import { Advisor, Proposal } from '../model/proposal';
import { Firm, User, UserProfile } from '../model/user-profile';
import { AuthState } from '../service/auth/auth-slice.reducers';
import { getCarUserProfile } from '../service/auth/auth.selectors';
import { GlobalService } from './system/global.service';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {
    private currentUserProfileSubject: BehaviorSubject<UserProfile>;
    private authAdvisor: User;

    public currentUserProfile: Observable<UserProfile>;
    public entitledAdvisorsEntityIds = new BehaviorSubject<number[]>([]);

    constructor(
        private http: HttpClient,
        protected carGlobal: GlobalService,
        private readonly store: Store<AuthState>,
        private dfsIllustratorService: DfsIllustratorService
    ) {
        // if (userProfile) { this.currentUserProfile = of(userProfile); }
        this.currentUserProfileSubject = new BehaviorSubject<UserProfile>(undefined);
        this.currentUserProfile = this.currentUserProfileSubject.asObservable();

        this.store.select(getCarUserProfile).subscribe((data: UserProfile) => {
            this.currentUserProfileSubject.next(data);
        });
    }

    public get getCurrentUserProfile(): UserProfile {
        return this.currentUserProfileSubject.value;
    }

    public get firm(): Firm {
        if (!this.getCurrentUserProfile || !this.getCurrentUserProfile.firm) {
            // TODO Handle exception of no user profile and firm object for instance users - MVP2
            return undefined;
        }
        return this.getCurrentUserProfile.firm;
    }

    public updateCurrentUserProfileFirm(firm: Firm) {
        const userProfile: UserProfile = _.cloneDeep(this.currentUserProfileSubject.value);
        userProfile.firm = firm;
        this.currentUserProfileSubject.next(userProfile);
    }

    public setCurrentUserProfile(data: UserProfile): void {
        this.currentUserProfileSubject.next(data);
    }

    public setEtitledAdvisorsEntityIds(data: number[]): void {
        this.entitledAdvisorsEntityIds.next(data);
    }

    public getEtitledAdvisorsEntityIds(): number[] {
        return this.entitledAdvisorsEntityIds.value;
    }

    public verifyAccess(proposal: Proposal): boolean {
        if (!this.getCurrentUserProfile) {
            return false;
        }

        if (this.getCurrentUserProfile && this.getCurrentUserProfile.entitlements
            && this.getCurrentUserProfile.entitlements.userLevelId === UserLevelEntitlement.PO
            || this.getCurrentUserProfile.entitlements.userLevelId === UserLevelEntitlement.Instance) {
            return true;
        }

        if (this.authenticatedAdvisor) {
            return true;
        }

        // Note: any Advisor on the account should get access to the proposal.
        const primaryAdvisorForProposal: Advisor = this.getFirstAdvisorFromProposal(proposal);

        // Note: check any one advisor in proposal is in the logged in userprofile entitled advisors list they should have acess
        const primaryAdvisorForProposalHasAccess = this.getCurrentUserProfile.entitlements.advisors
            .some((entitledAdvisor) => entitledAdvisor.entityId === +primaryAdvisorForProposal.entityId);

        return primaryAdvisorForProposalHasAccess;
    }

    private getFirstAdvisorFromProposal(proposal: Proposal): Advisor {
        if (proposal) {
            for (const scenario of proposal.scenarios) {
                if (scenario.accounts.length !== 0) {
                    for (const account of scenario.accounts) {
                        if (account.advisors && account.advisors.length && account.advisors[0]) {
                            return account.advisors[0];
                        }
                    }
                } else {
                    return proposal.advisors.find((advisor) => advisor.role.id === 1);
                }
            }
        }
        return undefined;
    }

    public get authenticatedAdvisor(): User {
        if (!this.authAdvisor && this.firm && this.firm.advisors) {
            this.authAdvisor = this.firm.advisors.find(
                (advisor: User) =>
                    advisor.entityId === this.getCurrentUserProfile.entityId
            );
        }
        return this.authAdvisor;
    }

    public get isReadUser(): boolean {
        const functionPoint = this.getCurrentUserProfile.functionPoints.find(
            (funcPoint) => funcPoint.functionPointId === 6466
        );

        return (
            functionPoint &&
            functionPoint.permissionLevel === 2);
    }

    public get isAllowCustomFirmNameOnProposal(): boolean {
        let allowCustomFirmNameOnProposal: boolean = false;
        if (this.getCurrentUserProfile.processingRules.AllowCustomFirmNameOnProposal === 'Yes') {
            allowCustomFirmNameOnProposal = true;
        }
        return allowCustomFirmNameOnProposal;
    }

    public getDefaultAdvisorFeeForIllustrator(userProfile: UserProfile, proposal: Proposal): number {
        const currentUserId: number = userProfile?.userId;
        const currentUserEntityId: number = userProfile?.entityId;
        const matchingProposalAdvisor: Advisor = proposal?.advisors?.find((advisor: Advisor): boolean => {
            return Number(advisor?.id) === Number(currentUserId) &&
                Number(advisor?.entityId) === Number(currentUserEntityId);
        });
        return matchingProposalAdvisor?.defaultAdvisorFeeForIllustrator || this.dfsIllustratorService.DEFAULT_ADVISOR_FEE;
    }

}
