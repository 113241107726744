/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProposalAccountService, ProposalService, StrategiesService } from '@sei/advisor-client-review-proposal-ux';
import { DisplayNullOptions, DropdownItem, EditAmountOptions, EditPercentOptions } from '@sei/common-components-lib-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { OrderedSet } from 'immutable';
import * as _ from 'lodash';
import moment from 'moment';
import { AlertPopoverTypes, CurrencyTypes, DefaultSelection, GoalDropdownLabelHelperText, GoalFrequencyIds, GoalFundingType, GoalOptionTypeId, GoalSelectionOptions, ToolTipLabels, WipChecklistLabels } from '../../model/enums';
import { ErrorMessages } from '../../model/error-messages';
import { ToolTipOptionsFactory } from '../../model/factory/tooltip-options-factory';
import { InfoMessages } from '../../model/info-messages';
import { Account, AchievabilityAnalysisRequest, GoalCatalogs, GoalDetail, MonteCarloFormOptions, Proposal, Scenario, Strategy, Type, WipCheckList } from '../../model/proposal';
import { ToolTipOptions } from '../../model/tooltip-options';
import { GoalService } from '../../service/goal.service';

@Component({
    selector: 'sei-car-edit-goal-ribbon',
    templateUrl: './edit-goal-ribbon.component.html',
    styleUrls: ['./edit-goal-ribbon.component.scss']
})
export class EditGoalRibbonComponent extends ParentComponentSubscriptionManager implements OnInit {

    @Input()
    public goal: GoalDetail;

    @Input()
    public goalCatalogs: GoalCatalogs;

    @Input()
    public scenario: Scenario;

    @Input()
    set index(value: number) {
        this._index = value;
        this.titleText = `Goal ${this._index}`;
    }

    get index(): number {
        return this._index;
    }

    @Input()
    proposalId: number;

    @Output()
    public removeGoal = new EventEmitter();

    @Output()
    public isFormValid = new EventEmitter();

    @ViewChild('goalFundingSelection')
    private goalFundingDropdown: FormControl;

    @ViewChild('goalType')
    private goalTypeDropdown: FormControl;

    @ViewChild('goalPriority')
    private goalPriorityDropdown: FormControl;

    @ViewChild('contributionFrequency')
    private goalContributionFrequencyDropdown: FormControl;

    @ViewChild('goalName')
    private goalNameInput: FormControl;

    public editModeOn: boolean = false;
    public toolTipOptions: ToolTipOptions[] = [];
    public dateInfoText: string;
    public minDateAllowed: Date = new Date(2000, 6, 1);
    public amountOptions: EditAmountOptions;
    public balanceErrorMessage: string;
    public nameDuplicatedErrorMessage: string;
    public endValueIsValid: boolean = true;
    public isNameValid: boolean = true;
    public isNameOverMaximumLength: boolean = false;
    public isNewGoal: boolean = true;
    public currencyTypes: typeof CurrencyTypes = CurrencyTypes;
    public alertPopoverTypes: typeof AlertPopoverTypes = AlertPopoverTypes;
    public defaultSelection: typeof DefaultSelection = DefaultSelection;
    public goalSelectionOptions: typeof GoalSelectionOptions = GoalSelectionOptions;
    public selectedOptionValues: GoalCatalogs;
    public titleText: string;

    public readonly today: Date = new Date();
    public tomorrow: Date = new Date();

    public isMonteCarloFeatureEnabled: boolean = false;
    public regularContributionIsValid: boolean = true;
    public targetAmountIsValid: boolean = true;
    public goalOptionTypeIds: typeof GoalOptionTypeId = GoalOptionTypeId;
    // icon toggle settings
    public offIsGrey: boolean = false;
    public iconClass: string = 'info';

    public percentOptions: EditPercentOptions;
    public goalFundingDropdownItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public selectedGoalFundingDropdownItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public distributionFrequencyDropdownItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public selectedDistributionFrequencyDropdownItems: OrderedSet<DropdownItem> = OrderedSet<DropdownItem>([]);
    public goalDropDownLabelHelperText: typeof GoalDropdownLabelHelperText = GoalDropdownLabelHelperText;

    // Error tracking
    public targetAmountHasErrors: boolean = false;
    public contributionAmountHasErrors: boolean = false;
    public inflationRateHasErrors: boolean = false;
    public distributionAmountHasErrors: boolean = false;
    public currentAnnualIncomeHasErrors: boolean = false;
    public percentOfIncomeNeededHasErrors: boolean = false;

    public isDrawerCollapsed: boolean = false;
    public isFormDirty = false;
    public isMonteCarloRequestLoading: boolean = false;
    public isMonteCarloRequestError: boolean = false;
    public showMonteCarloDrawer: boolean = false;
    public monteCarloCalculationAttributes: AchievabilityAnalysisRequest;
    public monteCarloFormOptions: MonteCarloFormOptions;

    private _index: number;
    private readonly NAME_MAX_LENGTH: number = 65;
    private readonly DATE_FORMAT: string = 'YYYY-MM-DD';
    private readonly uniqueIdForNewlyCreatedGoal: number = Number(_.uniqueId());
    private readonly DEFAULT_INFLATION_RATE: number = 2;

    constructor(
        private readonly goalService: GoalService,
        private featureFlagService: FeatureFlagService,
        private proposalService: ProposalService,
        private proposalAccountService: ProposalAccountService,
        private strategiesService: StrategiesService
    ) {
        super('EditGoalRibbonComponent');
        this.tomorrow.setDate(this.today.getDate() + 1);
        this.isMonteCarloFeatureEnabled = this.featureFlagService.isMonteCarloFeatureEnabled();
    }

    public ngOnInit(): void {
        this.initializeComponent();
    }

    public onGoalNameChange(name: string) {
        this.isNameOverMaximumLength = name?.length > this.NAME_MAX_LENGTH;
        this.goal.name = name;
        this.validateForm();
    }

    public isProposalEligibleForMonteCarlo(): boolean {
        return this.scenario?.accounts?.length > 0 &&
            this.scenario?.accounts?.some((account: Account) => account?.strategies?.length > 0);
    }

    public getToggleHoverText(): string {
        return this.isProposalEligibleForMonteCarlo() ?
            '' : 'Please add a completed account to the Proposal to enable monte carlo calculations.';
    }

    public onGoalFundingDropdownChange(selectedItems: OrderedSet<DropdownItem>): void {
        let dropdownItemsNoLongerSelected: DropdownItem[] = _.cloneDeep(this.selectedGoalFundingDropdownItems).toArray();
        this.goalService.resetGoalAssignmentDataForDropdownsForGivenGoal(this.getGoalId(this.goal));
        this.selectedGoalFundingDropdownItems = OrderedSet<DropdownItem>([]);
        const allSelectedDropdownItemsAsArray: DropdownItem[] = selectedItems?.toArray();
        const selectedAccountElements: DropdownItem[] = [];
        let selectedStrategyElements: DropdownItem[] = [];
        this.goal.monteCarloAnalytics.accounts = [];
        this.goal.monteCarloAnalytics.strategies = [];
        allSelectedDropdownItemsAsArray?.forEach((dropdownItem: DropdownItem): void => {
            dropdownItemsNoLongerSelected =
                dropdownItemsNoLongerSelected
                    .filter((preExistingDropdownItem) => preExistingDropdownItem.id !== dropdownItem.id);
            if (this.doesDropdownItemMatchGivenElementType(dropdownItem, GoalDropdownLabelHelperText.ENTIRE_ACCOUNT)) {
                selectedAccountElements.push(dropdownItem);
            } else if (this.doesDropdownItemMatchGivenElementType(dropdownItem, GoalDropdownLabelHelperText.INDIVIDUAL_STRATEGY)) {
                selectedStrategyElements.push(dropdownItem);
            }
        });

        if (selectedAccountElements?.length) {
            selectedAccountElements.forEach((accountDropdown: DropdownItem): void => {
                if (accountDropdown) {
                    const accountId: number = this.goalService.getIdFromDropdown(accountDropdown);
                    const matchingAccount: Account = this.scenario?.accounts?.find((account) => account?.id === accountId);
                    if (matchingAccount) {
                        matchingAccount.strategies?.forEach((strategy: Strategy): void => {
                            strategy.proposalScenarioGoalId = null;
                        });
                        this.goal.monteCarloAnalytics.accounts.push(matchingAccount.id);
                        this.goalService
                            .addOrRemoveAccountOrStrategyFromFundingStatus(true, GoalDropdownLabelHelperText.ENTIRE_ACCOUNT,
                                accountId, this.getGoalId(this.goal));
                        this.toggleSelectionEligibilityStatusOfAccountStrategies(matchingAccount?.id, true);
                        const entireAccountDropdownItem: DropdownItem =
                            this.createEntireAccountDropdownItemForGivenAccount(matchingAccount);
                        this.selectedGoalFundingDropdownItems = this.selectedGoalFundingDropdownItems.add(entireAccountDropdownItem);
                        selectedStrategyElements =
                            selectedStrategyElements
                                .filter((strategyDropdownItem: DropdownItem) => strategyDropdownItem.parentId !== matchingAccount?.id);
                    }
                }
            });
        }

        if (selectedStrategyElements?.length) {
            selectedStrategyElements.forEach((strategyDropdown: DropdownItem): void => {
                const strategyIdFromDropdown: number = this.goalService.getIdFromDropdown(strategyDropdown);
                this.goal.monteCarloAnalytics.strategies.push(strategyIdFromDropdown);
                this.goalService.addOrRemoveAccountOrStrategyFromFundingStatus(true, GoalDropdownLabelHelperText.INDIVIDUAL_STRATEGY,
                    strategyIdFromDropdown, this.getGoalId(this.goal), Number(strategyDropdown?.parentId));
                this.selectedGoalFundingDropdownItems = this.selectedGoalFundingDropdownItems.add(strategyDropdown);
            });
        }
        this.resetDropdownItemsNoLongerSelected(dropdownItemsNoLongerSelected);
        this.updateFundingOptionsOnAccountsAndStrategiesObjects();
        this.goal.monteCarloAnalytics.initialContribution = this.calculateInitialContribution();
        this.validateForm();
    }

    public onGoalDropdownTouch(): void {
        const allScenarioAccounts: Account[] = this.scenario?.accounts;
        const goalFundingElements: DropdownItem[] = this.goalFundingDropdownItems.toArray();
        this.goalService.updateDropdownItemsSelectionEligibilityStatus(goalFundingElements, this.getGoalId(this.goal), allScenarioAccounts);
    }

    public collapseClicked(): void {
        this.isDrawerCollapsed = !this.isDrawerCollapsed;
    }

    public validateName(): void {
        if (!this.isGoalNameValid()) {
            this.goal.isValid = false;
            this.isNameValid = false;
            this.isFormValid.emit(this.goal.isValid);
        } else {
            this.isNameValid = true;
            this.validateForm();
        }
    }

    public validateForm(): void {
        this.goal.isValid = this.goalService.isThisGoalValid(this.goal) && !this.isNameOverMaximumLength;
        this.isFormValid.emit(this.goal.isValid);
        this.isFormDirty = true;
    }

    public onDropDownChange(event: OrderedSet<DropdownItem>, dropdownSelected: GoalSelectionOptions): void {

        const selectedId: number = +event?.first()?.id !== 0
            ? +event?.first()?.id
            : undefined;

        const selectedDescription: string = selectedId
            ? event?.first()?.name
            : '';

        switch (dropdownSelected) {
            case GoalSelectionOptions.GoalType:
                this.goal.goalType.id = selectedId;
                this.goal.goalType.description = selectedDescription;
                this.monteCarloFormOptions = this.goalService.retrieveMonteCarloFormOptionsForGoalTypeId(this.goal.goalType.id);
                this.handleMonteCarloFormOptionsChange();
                break;
            case GoalSelectionOptions.Priority:
                this.goal.priority.id = selectedId;
                this.goal.priority.description = selectedDescription;
                break;
            case GoalSelectionOptions.Frequency:
                this.goal.frequency.id = selectedId;
                this.goal.frequency.description = selectedDescription;
                break;
            case GoalSelectionOptions.DistributionFrequency:
                if (!this.goal.monteCarloAnalytics.distributionFrequency) {
                    this.goal.monteCarloAnalytics.distributionFrequency = {};
                }
                this.goal.monteCarloAnalytics.distributionFrequency.id = selectedId;
                this.goal.monteCarloAnalytics.distributionFrequency.description = selectedDescription;
                break;
            default:
                break;
        }
        this.validateForm();
    }

    public onEndValueChange(endValue: number): void {
        this.goal.endValue = endValue;
        this.validateForm();
    }

    public onStartDateChange(startDate: Date): void {
        this.goal.startDate = startDate;
        if (this.goal?.endDate && this.goal?.startDate >= this.goal?.endDate) {
            const newEndDate: Date = new Date(this.goal.startDate);
            newEndDate.setDate(newEndDate.getDate() + 1);
            this.goal.endDate = newEndDate;
        }
        this.validateForm();
    }

    public onEndDateChange(endDate: Date): void {
        this.goal.endDate = endDate;
        this.validateForm();
    }

    public onEditClick(): void {
        this.editModeOn = true;
    }

    public onOptionEllipsisSelected(event: ToolTipLabels): void {
        switch (event) {
            case ToolTipLabels.EditGoal:
                this.editModeOn = true;
                break;
            case ToolTipLabels.RemoveGoal:
                this.removeGoal.emit(this.goal);
                break;
            default:
                break;
        }
    }

    public onEditConfirmClick(): void {
        if (this.isGoalNameValid()) {
            this.editModeOn = false;
            this.isNewGoal = false;
            this.isNameValid = true;
            this.validateForm();
        } else {
            this.isNameValid = false;
        }
    }
    public onEndValueAmountValidation(): void {
        this.endValueIsValid = this.goal.endValue > Number(this.amountOptions.minValueAllowed);
        if (!this.endValueIsValid) {
            this.goal.isValid = false;
            this.isFormValid.emit(this.goal.isValid);
        } else {
            this.validateForm();
        }
    }

    public validateMinimumEndDate(): void {
        if (this.goal.startDate > this.goal.endDate) {
            this.goal.endDate = this.goal.startDate;
        }
    }

    public fireRemoveEvent(): void {
        this.goalService.removeAssociatedAccountAndStrategySelectionsForGoal(this.getGoalId(this.goal));
        this.deleteGoalFromWipChecklist();
        this.removeGoal.emit(this.goal);
    }

    public onInitialContributionChange(initialContribution: number): void {
        this.goal.monteCarloAnalytics.initialContribution = _.isNil(initialContribution) ? 0 : Number(initialContribution);
        this.validateForm();
    }

    public onContributionAmountChange(contributionAmount: number): void {
        this.goal.endValue = _.isNil(contributionAmount) ? 0 : Number(contributionAmount);
        this.validateForm();
    }

    public setContributionAmountError(errors: string[]): void {
        if (errors?.length || this.goal.endValue < 0) {
            this.contributionAmountHasErrors = true;
        } else {
            this.contributionAmountHasErrors = false;
        }
    }

    public onCurrentAnnualIncomeChange(currentAnnualIncome: number): void {
        this.goal.monteCarloAnalytics.currentAnnualIncome = _.isNil(currentAnnualIncome) ? 0 : Number(currentAnnualIncome);
        if (this.goal?.monteCarloAnalytics?.distributionFrequency?.id !== GoalFrequencyIds.ANNUALLY) {
            const annuallyItem: DropdownItem = _.cloneDeep(this.distributionFrequencyDropdownItems
                .find((item: DropdownItem) => Number(item.id) === GoalFrequencyIds.ANNUALLY));
            this.onDropDownChange(OrderedSet([annuallyItem]), this.goalSelectionOptions.DistributionFrequency);
        }
        this.validateForm();
    }

    public setCurrentAnnualIncomeError(errors: string[]): void {
        if (errors?.length || this.goal.monteCarloAnalytics.currentAnnualIncome <= 0) {
            this.currentAnnualIncomeHasErrors = true;
        } else {
            this.currentAnnualIncomeHasErrors = false;
        }
    }

    public onPercentOfIncomeNeededChange(percentOfIncomeNeeded: number): void {
        this.goal.monteCarloAnalytics.percentOfIncomeNeeded = _.isNil(percentOfIncomeNeeded) ? 0 : Number(percentOfIncomeNeeded);
        this.validateForm();
    }

    public setPercentOfIncomeNeededError(errors: string[]): void {
        if (errors?.length || this.goal.monteCarloAnalytics.percentOfIncomeNeeded <= 0) {
            this.percentOfIncomeNeededHasErrors = true;
        } else {
            this.percentOfIncomeNeededHasErrors = false;
        }
    }

    public onTargetAmountChange(targetAmount: number): void {
        this.goal.monteCarloAnalytics.targetAmount = _.isNil(targetAmount) ? 0 : Number(targetAmount);
        if (this.monteCarloFormOptions.distributionAmount.defaultValue !== undefined &&
            this.monteCarloFormOptions.distributionAmount.disable) {
            this.onDistributionAmountChange(targetAmount);
        }
        this.validateForm();
    }

    public setTargetAmountError(errors: string[]): void {
        if (errors?.length || this.goal.monteCarloAnalytics.targetAmount <= 0) {
            this.targetAmountHasErrors = true;
        } else {
            this.targetAmountHasErrors = false;
        }
    }

    public onInflationRateChange(inflationRate: number): void {
        this.goal.monteCarloAnalytics.inflationRate = _.isNil(inflationRate) ? 0 : Number(inflationRate);
        this.validateForm();
    }

    public setInflationRateError(errors: string[]): void {
        if (errors?.length || this.goal.monteCarloAnalytics.inflationRate < 0) {
            this.inflationRateHasErrors = true;
        } else {
            this.inflationRateHasErrors = false;
        }
    }

    public onDistributionAmountChange(distributionAmount: number): void {
        this.goal.monteCarloAnalytics.distributionAmount = _.isNil(distributionAmount) ? 0 : Number(distributionAmount);
        this.validateForm();
    }

    public setDistributionAmountError(errors: string[]): void {
        if ((errors?.length || this.goal.monteCarloAnalytics.distributionAmount <= 0) &&
            !this.monteCarloFormOptions.distributionAmount.disable) {
            this.distributionAmountHasErrors = true;
        } else {
            this.distributionAmountHasErrors = false;
        }
    }

    public onAnnualExpensesChange(event: number): void {
        this.goal.monteCarloAnalytics.annualExpenses = Number(event);
        this.goal.monteCarloAnalytics.distributionAmount = Number(event);
        if (this.goal?.monteCarloAnalytics?.distributionFrequency?.id !== GoalFrequencyIds.ANNUALLY) {
            const annuallyItem: DropdownItem = _.cloneDeep(this.distributionFrequencyDropdownItems
                .find((item: DropdownItem) => Number(item.id) === GoalFrequencyIds.ANNUALLY));
            this.onDropDownChange(OrderedSet([annuallyItem]), this.goalSelectionOptions.DistributionFrequency);
        }
        this.validateForm();
    }

    public isRetirementTypeSelected(): boolean {
        return this.goal?.goalType?.id === GoalOptionTypeId.Retirement;
    }

    private initializeComponent(): void {
        if (!this.goal?.monteCarloAnalytics) {
            this.goal.monteCarloAnalytics = {};
        }
        this.amountOptions = {
            minValueAllowed: '0',
            nullDisplay: DisplayNullOptions.ZERO
        };

        this.distributionFrequencyDropdownItems = _.cloneDeep(this.goalCatalogs.frequencies);

        this.percentOptions = {
            displayPercentSymbol: true,
            displayMinDecimals: 2,
            minValueAllowed: '0',
            maxValueAllowed: '100',
            maxDecimalPlaces: 2
        };

        this.toolTipOptions = [
            new ToolTipOptionsFactory().createOption(
                ToolTipLabels.EditGoal
            ),
            new ToolTipOptionsFactory().createOption(
                ToolTipLabels.RemoveGoal
            )
        ];

        this.editModeOn = !this.goal.name;
        this.isNewGoal = this.editModeOn;

        this.dateInfoText = InfoMessages.goalDateSelection();

        this.balanceErrorMessage = ErrorMessages.clientDisplayInvalidTierAmount();
        this.nameDuplicatedErrorMessage = ErrorMessages.goalDisplayDuplicatedOrEmpty();

        if (!this.goal.id || !this.goal.startDate || !this.goal.endDate) {
            this.goal.startDate = new Date();
            this.goal.endDate = _.cloneDeep(this.tomorrow);
        }

        this.goal.startDate = this.setDateValue(this.goal.startDate);
        this.goal.endDate = this.setDateValue(this.goal.endDate);

        this.goal.priority = this.formatGoalSection(this.goal.priority);
        this.goal.frequency = this.formatGoalContributionSection(this.goal.frequency);
        this.goal.goalType = this.formatGoalSection(this.goal.goalType);

        this.selectedOptionValues = {
            priorities: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.priority)),
            frequencies: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.frequency)),
            types: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.goalType)),
            distributionFrequency: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.monteCarloAnalytics.distributionFrequency))
        };

        this.isNameOverMaximumLength = this.goal?.name?.length > this.NAME_MAX_LENGTH;
        this.initializeGoalfundingDropdown();
        if (this.goal?.monteCarloAnalytics?.inflationRate === undefined) {
            this.goal.monteCarloAnalytics.inflationRate = this.DEFAULT_INFLATION_RATE;
        }
        this.goal.monteCarloAnalytics.initialContribution = this.calculateInitialContribution();
        this.monteCarloFormOptions = this.goalService.retrieveMonteCarloFormOptionsForGoalTypeId(this.goal.goalType.id);
        if (this.isMonteCarloFeatureEnabled && this.goal?.goalType?.id === GoalOptionTypeId.Education) {
            this.onAnnualExpensesChange(_.cloneDeep(this.goal.monteCarloAnalytics.distributionAmount));
        }
    }

    private setDateValue(date: Date | string): Date {
        if (typeof date === 'string') {
            return new Date(date);
        }
        return date;
    }

    private formatGoalContributionSection(type: Type): Type {
        if (!type || !type.id) {
            type = {
                id: 0,
                description: ''
            };
        }
        return type;
    }

    private formatGoalSection(type: Type): Type {
        if (!type || !type.id) {
            type = {
                id: undefined,
                description: ''
            };
        }
        return type;
    }

    private getDefaultValue(type: Type): DropdownItem[] {
        const dropdownList: DropdownItem[] = [];
        dropdownList.push(new DropdownItem(type?.id, type?.description));
        return dropdownList;
    }

    private isGoalNameValid(): boolean {
        return !!this.goal.name.trim() && !this.goalService.isNameDuplicated();
    }

    private initializeGoalfundingDropdown(): void {
        const accountIdsAssignedToGoals: number[] = this.goal?.monteCarloAnalytics?.accounts;
        const strategyIdsAssignedToGoals: number[] = this.goal?.monteCarloAnalytics?.strategies;
        this.scenario?.accounts?.forEach((account: Account) => {
            if (account.strategies?.length > 0) {
                const accountDropdownItem: DropdownItem = new DropdownItem(
                    `${GoalDropdownLabelHelperText.ACCOUNT_HEADER} ${account.id}`,
                    account.name,
                    undefined, // No parent ID for account items
                    undefined, // No child dropdown elements
                    true,
                    account.name
                );
                this.goalFundingDropdownItems = this.goalFundingDropdownItems.add(accountDropdownItem);
                const entireAccountDropdownItem: DropdownItem = this.createEntireAccountDropdownItemForGivenAccount(account);
                this.goalFundingDropdownItems = this.goalFundingDropdownItems.add(entireAccountDropdownItem);
                const isAccountSelected: boolean =
                    accountIdsAssignedToGoals?.includes(account?.id);
                if (isAccountSelected) {
                    this.selectedGoalFundingDropdownItems = this.selectedGoalFundingDropdownItems.add(entireAccountDropdownItem);
                }
                if (account?.strategies?.length > 0) {
                    account?.strategies?.forEach((strategy: Strategy) => {
                        const strategyDropdownItem: DropdownItem = new DropdownItem(
                            `${GoalDropdownLabelHelperText.INDIVIDUAL_STRATEGY} ${Number(strategy.proposalAccountStrategyId)}`,
                            strategy.strategyName,
                            account.id,
                            undefined,
                            isAccountSelected
                        );
                        this.goalFundingDropdownItems = this.goalFundingDropdownItems.add(strategyDropdownItem);
                        if (strategyIdsAssignedToGoals?.includes(strategy?.proposalAccountStrategyId)) {
                            this.selectedGoalFundingDropdownItems = this.selectedGoalFundingDropdownItems.add(strategyDropdownItem);
                        }
                    });
                }
            }
        });
    }

    private createEntireAccountDropdownItemForGivenAccount(account: Account): DropdownItem {
        return new DropdownItem(
            `${GoalDropdownLabelHelperText.ENTIRE_ACCOUNT} ${account.id}`,
            'Entire Account',
            undefined,
            undefined,
            false,
            account.name
        );
    }

    private handleMonteCarloFormOptionsChange(): void {
        if (this.monteCarloFormOptions?.distributionAmount?.defaultValue !== undefined) {
            this.onDistributionAmountChange(_.cloneDeep(this.goal.monteCarloAnalytics.targetAmount));
        }
        if (this.monteCarloFormOptions?.distributionFrequency?.defaultValue) {
            const item: DropdownItem = this.distributionFrequencyDropdownItems
                .find((dropdownItem: DropdownItem) =>
                    Number(dropdownItem.id) === this.monteCarloFormOptions?.distributionFrequency?.defaultValue);
            this.selectedOptionValues.distributionFrequency = OrderedSet([_.cloneDeep(item)]);
            this.onDropDownChange(this.selectedOptionValues.distributionFrequency, this.goalSelectionOptions.DistributionFrequency);
        }
    }

    public resetGoalForm(): void {
        this.goal.name = '';
        this.goal.startDate = this.today;
        this.goal.endDate = this.tomorrow;
        this.goal.monteCarloAnalytics.targetAmount = 0;
        this.selectedGoalFundingDropdownItems = OrderedSet<DropdownItem>([]);
        this.goal.monteCarloAnalytics.initialContribution = 0;
        this.goal.endValue = 0;
        this.goal.goalType.id = undefined;
        this.goal.goalType.description = '';
        this.goal.priority.id = undefined;
        this.goal.priority.description = '';
        this.goal.frequency.id = undefined;
        this.goal.monteCarloAnalytics.distributionFrequency.id = undefined;
        this.goal.monteCarloAnalytics.distributionFrequency.description = '';
        this.selectedOptionValues = {
            priorities: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.priority)),
            frequencies: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.frequency)),
            types: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.goalType)),
            distributionFrequency: OrderedSet<DropdownItem>(this.getDefaultValue(this.goal.monteCarloAnalytics.distributionFrequency))
        };
        this.goal.frequency.description = '';
        this.goal.monteCarloAnalytics.inflationRate = this.DEFAULT_INFLATION_RATE;
        this.goal.monteCarloAnalytics.currentAnnualIncome = 0;
        this.goal.monteCarloAnalytics.percentOfIncomeNeeded = 0;
        this.goal.monteCarloAnalytics.annualExpenses = 0;
        this.goal.monteCarloAnalytics.distributionAmount = undefined;
        this.selectedDistributionFrequencyDropdownItems = OrderedSet<DropdownItem>([]);
        this.goalService.resetGoalAssignmentDataForDropdownsForGivenGoal(this.getGoalId(this.goal));
        this.validateForm();
        setTimeout(() => {
            this.resetErrorFlagsAfterFormReset();
        });
    }

    public isGoalTypeInvalid(wasDropdownTouched: boolean, index: number): boolean {
        const dropdownElement: HTMLElement = document.getElementById(`goal-type-${index}`);
        const isInvalid: boolean = this.goal?.goalType?.description?.length === 0 && _.isNil(this.goal?.goalType?.id) && wasDropdownTouched;
        if (isInvalid && dropdownElement) {
            dropdownElement.classList.add('border-red');
        }
        return isInvalid;
    }

    public isGoalPriorityInvalid(): boolean {
        return this.goal?.priority?.description?.length === 0 && _.isNil(this.goal?.priority?.id);
    }

    public isEnteredAmountInvalid(wasInputTouched: boolean, enteredAmount: number): boolean {
        return wasInputTouched && enteredAmount <= 0;
    }

    public isContributionFrequencyInvalid(): boolean {
        return this.goal?.frequency?.description?.length === 0 &&
            (_.isNil(this.goal?.frequency?.id) || this.goal.frequency.id === 0) &&
            !!this.goal?.endValue;
    }

    public isDistributionFrequencyInvalid(): boolean {
        return this.goal?.monteCarloAnalytics?.distributionFrequency?.description?.length === 0 &&
            _.isNil(this.goal?.monteCarloAnalytics?.distributionFrequency?.id);
    }

    public isGoalFundingDropdownInvalid(): boolean {
        return this.selectedGoalFundingDropdownItems?.size === 0;
    }

    public doesDropdownItemMatchGivenElementType(dropdownItem: DropdownItem, givenType: GoalDropdownLabelHelperText): boolean {
        const dropdownItemPrefix: string = this.goalService.getDropdownType(dropdownItem);
        return dropdownItemPrefix === givenType;
    }

    public toggleSelectionEligibilityStatusOfAccountStrategies(accountId: number, eligibilityStatus: boolean): void {
        if (accountId) {
            const availableDropdownItems: DropdownItem[] = this.goalFundingDropdownItems.toArray();
            if (availableDropdownItems) {
                availableDropdownItems.forEach((dropdownItem: DropdownItem): void => {
                    if (dropdownItem?.parentId === accountId) {
                        dropdownItem.disabled = eligibilityStatus;
                    }
                });
            }
        }
    }

    public isGoalDropdownItemDisabled(dropdownItem: DropdownItem): boolean {
        const dropdownElementType: string = this.goalService.getDropdownType(dropdownItem);
        return dropdownItem?.disabled && (dropdownElementType !== GoalDropdownLabelHelperText.ACCOUNT_HEADER);
    }

    private resetDropdownItemsNoLongerSelected(dropdownItemsNoLongerSelected: DropdownItem[]): void {
        if (dropdownItemsNoLongerSelected?.length) {
            dropdownItemsNoLongerSelected.forEach((dropdownItem: DropdownItem): void => {
                if (this.doesDropdownItemMatchGivenElementType(dropdownItem, GoalDropdownLabelHelperText.ENTIRE_ACCOUNT)) {
                    const accountId: number = this.goalService.getIdFromDropdown(dropdownItem);
                    this.toggleSelectionEligibilityStatusOfAccountStrategies(accountId, false);
                    this.goalService.addOrRemoveAccountOrStrategyFromFundingStatus(false, GoalDropdownLabelHelperText.ENTIRE_ACCOUNT,
                        accountId, this.getGoalId(this.goal));
                    this.goal.monteCarloAnalytics.accounts =
                        this.goal.monteCarloAnalytics.accounts
                            .filter((previouslySelectedAccountId) => Number(previouslySelectedAccountId) !== Number(accountId));
                    const matchingAccount: Account = this.scenario?.accounts?.find((account) => account?.id === accountId);
                    if (matchingAccount) {
                        matchingAccount.proposalScenarioGoalId = null;
                        matchingAccount.goalFundingTypeId = null;
                    }
                } else if (this.doesDropdownItemMatchGivenElementType(dropdownItem, GoalDropdownLabelHelperText.INDIVIDUAL_STRATEGY)) {
                    const strategyId: number = this.goalService.getIdFromDropdown(dropdownItem);
                    this.goalService.addOrRemoveAccountOrStrategyFromFundingStatus(false, GoalDropdownLabelHelperText.INDIVIDUAL_STRATEGY,
                        strategyId, this.getGoalId(this.goal));
                    const matchingStrategy: Strategy = this.strategiesService.getStrategyByProposalAccountStrategyId(strategyId);
                    if (matchingStrategy) {
                        matchingStrategy.proposalScenarioGoalId = null;
                    }
                    const entireAccountOptionForGivenStrategy: DropdownItem =
                        this.goalFundingDropdownItems
                            .filter((element) => this.goalService.getDropdownType(element) === GoalDropdownLabelHelperText.ENTIRE_ACCOUNT)
                            .find((element) => this.goalService.getIdFromDropdown(element) === dropdownItem?.parentId);
                    const isAnyOtherStrategyFromAccountSelected: boolean =
                        this.goalService.isAnyOtherStrategyFromAccountSelected(strategyId, this.scenario);
                    if (entireAccountOptionForGivenStrategy && !isAnyOtherStrategyFromAccountSelected) {
                        entireAccountOptionForGivenStrategy.disabled = false;
                    }
                    this.goal.monteCarloAnalytics.strategies =
                        this.goal.monteCarloAnalytics.strategies
                            .filter((previouslySelectedStrategyId) => previouslySelectedStrategyId !== strategyId);
                }
            });
        }
    }

    private updateFundingOptionsOnAccountsAndStrategiesObjects(): void {
        this.goal?.monteCarloAnalytics?.accounts?.forEach((selectedAccountId: number): void => {
            const matchingAccount: Account = this.scenario?.accounts?.find((account: Account) => account?.id === selectedAccountId);
            if (matchingAccount) {
                matchingAccount.proposalScenarioGoalId = this.goal?.isNewGoal ? 0 : this.goal?.id;
                matchingAccount.goalFundingTypeId = GoalFundingType.Account;
            }
        });

        this.goal?.monteCarloAnalytics?.strategies?.forEach((selectedStrategyId: number): void => {
            this.scenario?.accounts?.forEach((account: Account): void => {
                account?.strategies?.forEach((strategy: Strategy): void => {
                    if (strategy?.proposalAccountStrategyId === selectedStrategyId) {
                        account.goalFundingTypeId = GoalFundingType.Strategy;
                        account.proposalScenarioGoalId = null;
                        strategy.proposalScenarioGoalId = this.goal?.isNewGoal ? 0 : this.goal?.id;
                    }
                });
            });
        });
    }

    private getTargetDate(): Date {
        if (GoalOptionTypeId.MajorPurchase === this.goal.goalType.id ||
            GoalOptionTypeId.SecondHome === this.goal.goalType.id ||
            GoalOptionTypeId.WealthTransfer === this.goal.goalType.id) {
            return this.goal.endDate;
        }
        return this.goal.startDate;

    }

    private getPercentageOfCurrentExpensePostRetirement(): number {
        return GoalOptionTypeId.Retirement === this.goal?.goalType?.id ?
            this.goal?.monteCarloAnalytics?.percentOfIncomeNeeded : undefined;
    }

    private getGoalAmount(): number {
        let goalAmount: number;
        if (this.monteCarloFormOptions?.distributionAmount?.show && !this.monteCarloFormOptions?.distributionAmount?.disable) {
            goalAmount = this.goal.monteCarloAnalytics.distributionAmount;
        } else if (GoalOptionTypeId.Education === this.goal.goalType.id) {
            goalAmount = this.goal.monteCarloAnalytics.annualExpenses;
        } else if (GoalOptionTypeId.Retirement === this.goal.goalType.id) {
            goalAmount = this.goal.monteCarloAnalytics.currentAnnualIncome;
        } else {
            goalAmount = this.goal.monteCarloAnalytics.targetAmount;
        }
        return goalAmount;
    }

    private getGoalFrequency(): number {
        let goalFrequency: number;
        if (this.monteCarloFormOptions?.distributionAmount?.show && !this.monteCarloFormOptions?.distributionAmount?.disable) {
            goalFrequency = this.goal.monteCarloAnalytics.distributionFrequency.id;
        } else if (GoalOptionTypeId.Education === this.goal.goalType.id || GoalOptionTypeId.Retirement === this.goal.goalType.id) {
            goalFrequency = GoalFrequencyIds.ANNUALLY;
        } else {
            goalFrequency = this.goal.monteCarloAnalytics.distributionFrequency.id;
        }
        return goalFrequency;
    }

    private getEndDate(): Date {
        const endDate: Date = _.cloneDeep(this.goal.endDate);
        return endDate;
    }

    private getStartDate(): Date {
        if (this.today > this.goal.startDate) {
            return this.goal.startDate;
        }
        return this.today;
    }

    private getContributionEndDate(): Date {
        if (Number(this.goal.frequency.id) === GoalFrequencyIds.ONCE) {
            return;
        }
        if (GoalOptionTypeId.WealthTransfer === this.goal.goalType.id ||
            GoalOptionTypeId.SecondHome === this.goal.goalType.id ||
            GoalOptionTypeId.MajorPurchase === this.goal.goalType.id) {
            return this.goal.endDate;
        }
        return this.goal.startDate;
    }

    private formatDate(date: Date): string {
        return date ? moment(date).format(this.DATE_FORMAT) : undefined;
    }

    public onCalculateAchievabilityClicked(): void {
        this.showMonteCarloDrawer = true;
        this.isDrawerCollapsed = false;
        this.isFormDirty = false;
        const endDate: Date = this.getEndDate();
        const goalAmount: number = this.getGoalAmount();
        const goalFrequency: number = this.getGoalFrequency();
        const targetDate: Date = this.getTargetDate();
        const percentageOfCurrentExpensePostRetirement: number = this.getPercentageOfCurrentExpensePostRetirement();

        this.monteCarloCalculationAttributes = {
            proposalId: this.proposalId,
            inflationRate: this.goal?.monteCarloAnalytics?.inflationRate,
            initialContribution: this?.goal.monteCarloAnalytics?.initialContribution,
            goalType: this.goal?.goalType?.id,
            contributions: this.goal?.endValue >= 1 ? [{
                includeInflation: true,
                frequency: this?.goal?.frequency?.id,
                amount: this?.goal?.endValue,
                startDate: this.formatDate(this.getStartDate()),
                endDate: this.formatDate(this.getContributionEndDate())
            }] : [],
            goalStartDate: this.formatDate(this.getStartDate()),
            goalEndDate: this.formatDate(endDate),
            goalTargetDate: this.formatDate(targetDate),
            goalAmount,
            percentageOfCurrentExpensePostRetirement,
            goalFrequency,
            accountIdList: this.goal?.monteCarloAnalytics?.accounts,
            proposalAccountStrategyIdList: this.goal?.monteCarloAnalytics?.strategies
        };
    }

    public setMonteCarloRequestLoading(isLoading: boolean) {
        this.isMonteCarloRequestLoading = isLoading;
    }

    public setMonteCarloRequestError(isError: boolean) {
        this.isMonteCarloRequestError = isError;
    }

    private getGoalId(goal: GoalDetail): number {
        return goal?.id > 0 ? goal.id : this.uniqueIdForNewlyCreatedGoal;
    }

    public getGoalStartDateLabelText(): string {
        if (this?.goal?.goalType?.id === GoalOptionTypeId.Retirement) {
            return 'Retirement Start Date';
        } else if (this?.goal?.goalType?.id === GoalOptionTypeId.Education) {
            return 'Education Start Date';
        } else {
            return 'Start Date';
        }
    }

    public getGoalEndDateLabelText(): string {
        if (this?.goal?.goalType?.id === GoalOptionTypeId.Retirement) {
            return 'Retirement End Date';
        } else if (this?.goal?.goalType?.id === GoalOptionTypeId.Education) {
            return 'Education End Date';
        } else if (this?.goal?.goalType?.id === GoalOptionTypeId.WealthTransfer ||
            this?.goal?.goalType?.id === GoalOptionTypeId.MajorPurchase ||
            this?.goal?.goalType?.id === GoalOptionTypeId.SecondHome) {
            return 'Goal Date';
        } else {
            return 'End Date';
        }
    }

    public showStartDate(): boolean {
        const isValidGoalTypeForStartDate: boolean = this.goal?.goalType?.id !== GoalOptionTypeId.MajorPurchase &&
            this.goal?.goalType?.id !== GoalOptionTypeId.SecondHome &&
            this.goal?.goalType?.id !== GoalOptionTypeId.WealthTransfer;
        return isValidGoalTypeForStartDate;
    }

    public calculateInitialContribution(): number {
        let contributionAmount: number = 0;
        this.goal?.monteCarloAnalytics?.strategies?.forEach((proposalAccountStrategyId: number) => {
            contributionAmount += Number(this.strategiesService.getStrategyByProposalAccountStrategyId(proposalAccountStrategyId)?.amount);
        });

        this.goal?.monteCarloAnalytics?.accounts?.forEach((accountId: number) => {
            const matchingAccount: Account = this.proposalAccountService.getAccountByProposalAccountId(accountId);
            contributionAmount += matchingAccount?.balance;
        });

        return contributionAmount;
    }

    private deleteGoalFromWipChecklist(): void {
        const currentProposal: Proposal = this.proposalService.getCurrentProposal();
        const wipChecklists: WipCheckList[] = currentProposal?.wipCheckList;
        const proposalScenarioChecklist: WipCheckList =
            wipChecklists?.find((checklist: WipCheckList) => checklist?.sectionType === WipChecklistLabels.ProposalScenario);
        if (proposalScenarioChecklist) {
            const goalsSubChecklist: WipCheckList =
                proposalScenarioChecklist?.subCheckList
                    ?.find((checklist: WipCheckList) => checklist?.name === WipChecklistLabels.ScenarioGoal);
            if (goalsSubChecklist) {
                const goalIdAsString: string = this.getGoalId(this.goal)?.toString();
                goalsSubChecklist.subCheckList =
                    goalsSubChecklist?.subCheckList?.filter((goalChecklist) => !goalChecklist?.route.includes(goalIdAsString));
                this.proposalService.changedProposal(currentProposal);
            }
        }
    }

    private resetErrorFlagsAfterFormReset(): void {
        this.targetAmountHasErrors = false;
        this.contributionAmountHasErrors = false;
        this.inflationRateHasErrors = false;
        this.distributionAmountHasErrors = false;
        this.currentAnnualIncomeHasErrors = false;
        this.percentOfIncomeNeededHasErrors = false;

        this.goalFundingDropdown.reset();
        this.goalTypeDropdown.reset();
        this.goalPriorityDropdown.reset();
        this.goalContributionFrequencyDropdown.reset();
        this.goalNameInput.reset('');
    }
}
