/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription, debounceTime } from 'rxjs';
import { DrawdownChartDrawerComponent } from '../drawdown-chart-drawer/drawdown-chart-drawer.component';
import { DfsData, DistributionAnalyticsResponse, DrawdownData, ScenarioCardFormAndChartData, StrategyPool } from '../model/dfs-illustrator.model';
import { AdjustForInflationOptions, ButtonText, DfsFormControls, DfsFormErrorTypes, DfsInflationSelection, Frequency, GoalOrObjective, ImplementationTypes, InflationPlaceholderValues, ScenarioCardColors, ToolTipMessages } from '../model/enums';
import { DfsIllustratorService } from '../service/dfs-illustrator.service';

@Component({
    selector: 'sei-car-scenario-card',
    templateUrl: './scenario-card.component.html',
    styleUrls: ['./scenario-card.component.scss'],
    standalone: true,
    imports: [CommonModule, DrawdownChartDrawerComponent, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatInputModule,
        MatTooltipModule]
})
export class ScenarioCardComponent implements OnDestroy, OnInit {

    @Input() public scenarioCardFormAndChartData: ScenarioCardFormAndChartData;
    @Input() public isYShareAllowedForDfsIllustrator: boolean = false;
    @Input() public doesAccountAlreadyHaveADfsStrategy: boolean = false;

    @Input() public set dfsIllustratorIndex(value: number) {
        this._dfsIllustratorIndex = value;
        this.setScenarioCardBorderColor();
    }

    public get dfsIllustratorIndex(): number {
        return this._dfsIllustratorIndex;
    }

    @Input() public set distributionAnalyticsResponse(value: DistributionAnalyticsResponse) {
        if (value) {
            this.dfsDrawdownData = value?.distributionDetails;
            this.strategyPools = value?.modelDetail?.pools;
            this.strategyName = value?.modelDetail?.modelName;
            this.isDrawdownChartVisible = true;
        }
    }

    @Input() public areMultipleScenariosPresent: boolean = false;

    @Input() public disableCalculateButton: boolean = false;

    @Output() public dfsIllustratorDeleted: EventEmitter<number> = new EventEmitter(undefined);

    @Input() public backupFormGroupForFormReset: FormGroup;

    public dfsData: DfsData;
    public scenarioCardBorderColor: string = ScenarioCardColors.SCENARIO_1_BLUE;
    public isDrawdownChartVisible: boolean = false;
    public showModifyCardOption: boolean = false;
    public dfsIllustratorForm: FormGroup;
    public isDfsIllustratorRequestInProgress: boolean = false;
    public inflationOptions: typeof AdjustForInflationOptions = AdjustForInflationOptions;
    public implementationTypes: typeof ImplementationTypes = ImplementationTypes;
    public goalOrObjectiveOptions: typeof GoalOrObjective = GoalOrObjective;
    public frequencyOptions: typeof Frequency = Frequency;
    public inflationAdjustmentOptions: typeof DfsInflationSelection = DfsInflationSelection;
    public percentValueFormElementPlaceholder: string = InflationPlaceholderValues.PERCENT_ONLY;
    public toolTipMessages: typeof ToolTipMessages = ToolTipMessages;
    public dfsDrawdownData: DrawdownData;
    public calculateButtonText: string = ButtonText.CALCULATE_VALUES;
    public strategyPools: StrategyPool[];
    public strategyName: string;
    public isFormReadOnly: boolean = false;
    public calculationError: boolean = false;
    public isCarouselModeActive: boolean = false;
    public isScenarioRedrawn: boolean = false;
    private _dfsIllustratorIndex: number;
    private componentSubscriptions: Subscription[] = [];
    private doNotIncludeInflation: boolean = false;
    private specifyPercentage: boolean = false;
    private useStandardInflation: boolean = false;
    private readonly DEBOUNCE_TIME: number = 200;

    constructor(private dfsIllustratorService: DfsIllustratorService) { }

    public ngOnInit(): void {
        this.dfsData = this.scenarioCardFormAndChartData?.dfsData;
        this.isFormReadOnly = this.dfsData?.isFormReadOnly;
        if (this.isFormReadOnly) {
            this.percentValueFormElementPlaceholder = InflationPlaceholderValues.EMPTY_VALUE;
        }
        this.dfsIllustratorForm = this.scenarioCardFormAndChartData?.dfsFormGroup;
        const formChangeSubscription = this.dfsIllustratorForm?.valueChanges
            .pipe(debounceTime(this.DEBOUNCE_TIME))
            .subscribe((changes): void => {
                if (changes?.initialInvestment) {
                    const incomingTransformValued: string =
                        this.dfsIllustratorService.sanitizeAndFormatToDollarAmount(changes?.initialInvestment);
                    if (changes?.initialInvestment !== incomingTransformValued) {
                        this.dfsIllustratorForm?.patchValue({ initialInvestment: incomingTransformValued });
                        return;
                    }
                }
                if (changes?.incomeNeeded) {
                    const incomingTransformValued: string =
                        this.dfsIllustratorService.sanitizeAndFormatToDollarAmount(changes?.incomeNeeded);
                    if (changes?.incomeNeeded !== incomingTransformValued) {
                        this.dfsIllustratorForm?.patchValue({ incomeNeeded: incomingTransformValued });
                        return;
                    }
                }
                if (changes?.percentValue && changes?.percentValue !== this.percentValueFormElementPlaceholder) {
                    const incomingTransformValued: string = this.dfsIllustratorService.sanitizeAndFormatToPercent(changes?.percentValue);
                    if (changes?.percentValue !== incomingTransformValued) {
                        this.dfsIllustratorForm.patchValue({ percentValue: incomingTransformValued });
                        return;
                    }
                }
                if (changes?.advisorFee) {
                    const incomingTransformValued: string = this.dfsIllustratorService.sanitizeAndFormatToPercent(changes?.advisorFee);
                    if (changes?.advisorFee !== incomingTransformValued) {
                        this.dfsIllustratorForm.patchValue({ advisorFee: incomingTransformValued });
                        return;
                    }
                }
                if (changes?.adjustForInflation) {
                    this.updateInflationFormElements(changes.adjustForInflation);
                    return;
                }
            });
        this.componentSubscriptions?.push(formChangeSubscription);

        const carouselOpenSubscription: Subscription =
            this.dfsIllustratorService.isCarouselModeActive.subscribe((selectedIndex: number): void => {
                this.isCarouselModeActive = selectedIndex >= 0;
            });
        this.componentSubscriptions?.push(carouselOpenSubscription);

        this.updateInflationFormElements(this.adjustForInflation.value);
    }

    public ngOnDestroy(): void {
        this.componentSubscriptions?.forEach((subscription: Subscription): void => {
            subscription?.unsubscribe();
        });
    }

    public deleteDfsIllustrationByIndex(index: number): void {
        this.dfsIllustratorDeleted.emit(index);
    }

    public toggleDrawdownChartVisibility(): void {
        this.isDrawdownChartVisible = true;
        this.initiateDfsIllustratorRequest();
        this.dfsIllustratorForm.markAsPristine({ onlySelf: false });
    }

    public resetDfsIllustratorForm(): void {
        this.isScenarioRedrawn = false;
        this.isDrawdownChartVisible = false;
        this.calculateButtonText = ButtonText.CALCULATE_VALUES;
        this.dfsIllustratorForm?.reset(this.backupFormGroupForFormReset.getRawValue());
        this.dfsData.modelId = undefined;
        this.dfsDrawdownData = undefined;
        this.dfsIllustratorForm?.patchValue({ percentValue: null });
    }

    public get implementationMethod(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.IMPLEMENTATION_METHOD);
    }

    public get goalOrObjective(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.GOAL_OR_OBJECTIVE);
    }

    public get initialInvestment(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.INITIAL_INVESTMENT);
    }

    public get incomeNeeded(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.INCOME_NEEDED);
    }

    public get frequency(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.FREQUENCY);
    }

    public get adjustForInflation(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.ADJUST_FOR_INFLATION);
    }

    public get taxSensitivity(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.TAX_SENSITIVITY);
    }

    public get percentValue(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.PERCENT_VALUE);
    }

    public get advisorFee(): AbstractControl {
        return this.dfsIllustratorForm?.get(DfsFormControls.ADVISOR_FEE);
    }

    public isFormElementInvalid(formElement: AbstractControl): boolean {
        return formElement?.invalid && (formElement?.dirty || formElement?.touched) &&
            (formElement?.hasError(DfsFormErrorTypes.REQUIRED) || formElement?.hasError(DfsFormErrorTypes.MIN) ||
                formElement?.hasError(DfsFormErrorTypes.MIN_LENGTH) || formElement?.hasError(DfsFormErrorTypes.INVALID_AMOUNT));
    }

    public isFormInvalid(): boolean {
        return this.dfsIllustratorForm?.invalid;
    }

    public openCarouselMode(dfsIllustratorIndex: number): void {
        this.dfsIllustratorService.openCarouselMode(dfsIllustratorIndex);
    }

    private updateInflationFormElements(selectedInflationValue: number): void {
        if (selectedInflationValue === DfsInflationSelection.STANDARD_PERCENTAGE && !this.useStandardInflation) {
            this.percentValueFormElementPlaceholder = InflationPlaceholderValues.EMPTY_VALUE;
            this.percentValue.clearValidators();
            this.percentValue.disable();
            this.useStandardInflation = true;
            this.doNotIncludeInflation = false;
            this.specifyPercentage = false;
            this.dfsIllustratorForm?.patchValue({ percentValue: null });
        } else if (selectedInflationValue === DfsInflationSelection.DO_NOT_INCLUDE && !this.doNotIncludeInflation) {
            this.percentValueFormElementPlaceholder = InflationPlaceholderValues.PERCENT_ONLY;
            this.percentValue.clearValidators();
            this.percentValue.disable();
            this.useStandardInflation = false;
            this.doNotIncludeInflation = true;
            this.specifyPercentage = false;
            this.dfsIllustratorForm?.patchValue({ percentValue: InflationPlaceholderValues.ZERO_PERCENT });
        } else if (selectedInflationValue === DfsInflationSelection.CUSTOM_PERCENTAGE && !this.specifyPercentage) {
            this.percentValueFormElementPlaceholder = InflationPlaceholderValues.PERCENT_ONLY;
            this.percentValue.addValidators([Validators.required, this.dfsIllustratorService.validateAmountIsGreaterThanGivenAmount(0)]);
            this.percentValue.enable();
            this.useStandardInflation = false;
            this.doNotIncludeInflation = false;
            this.specifyPercentage = true;
        }
    }

    private initiateDfsIllustratorRequest(): void {
        this.isDfsIllustratorRequestInProgress = true;
        this.dfsDrawdownData = undefined;
        this.strategyPools = undefined;
        let dfsDrawdownRequest: DfsData;
        if (this.dfsData?.isNewScenario && this.dfsData?.isFormReadOnly) {
            const convertedAdvisorFee: number =
                Number(this.dfsIllustratorService.convertFormattedStringToNumber(this.advisorFee?.value, true).toFixed(2));
            dfsDrawdownRequest = this.dfsIllustratorService.createDrawdownRequestForModelDerivation(this.dfsData, convertedAdvisorFee);
        } else {
            dfsDrawdownRequest = this.dfsIllustratorService.createAnalyticsRequestFromFormGroupForSave(this.dfsIllustratorForm,
                this.dfsData?.modelId, this.dfsData?.firmId, this.dfsData?.isUserCreatedIllustration);
        }
        this.componentSubscriptions.push(
            this.dfsIllustratorService.getDfsDrawdownData(dfsDrawdownRequest)
                .subscribe({
                    next: (responseData: DistributionAnalyticsResponse) => {
                        this.dfsDrawdownData = responseData?.distributionDetails;
                        this.strategyPools = responseData?.modelDetail?.pools;
                        this.strategyName = responseData?.modelDetail?.modelName;
                        this.dfsData.modelId = responseData?.modelDetail?.modelId;
                        this.calculationError = false;
                        this.scenarioCardFormAndChartData.analyticsData = responseData;
                        this.isScenarioRedrawn = true;
                    },
                    error: () => {
                        this.calculationError = true;
                        this.isDfsIllustratorRequestInProgress = false;
                    },
                    complete: () => {
                        this.isDfsIllustratorRequestInProgress = false;
                    }
                })
        );
    }

    private setScenarioCardBorderColor(): void {
        if (this.dfsIllustratorIndex === 0) {
            this.scenarioCardBorderColor = ScenarioCardColors.SCENARIO_1_BLUE;
        } else if (this.dfsIllustratorIndex === 1) {
            this.scenarioCardBorderColor = ScenarioCardColors.SCENARIO_2_PINK;
        } else if (this.dfsIllustratorIndex === 2) {
            this.scenarioCardBorderColor = ScenarioCardColors.SCENARIO_3_PURPLE;
        } else {
            this.scenarioCardBorderColor = ScenarioCardColors.SCENARIO_1_BLUE;
        }
    }

    public addSeletedModelToAccount(): void {
        this.dfsIllustratorService.modelIdToBeAddedToAccount.next(this.dfsData?.modelId);
        const dfsRequestData: DfsData =
            this.dfsIllustratorService.createAnalyticsRequestFromFormGroupForSave(this.dfsIllustratorForm, this.dfsData?.modelId,
                this.dfsData?.firmId, this.dfsData?.isUserCreatedIllustration);
        this.dfsIllustratorService.dfsDataToPersistToProposalAccount.next(dfsRequestData);
    }
}
