/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */


import { DrawdownData, DrawdownProjection } from '@CarComponents/dfs-illustrator/model/dfs-illustrator.model';
import { DrawdownSeries } from '@CarComponents/dfs-illustrator/model/enums';
import { DfsIllustratorService } from '@CarComponents/dfs-illustrator/service/dfs-illustrator.service';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'sei-car-drawdown-chart',
    templateUrl: './drawdown-chart.component.html',
    standalone: true,
    imports: [CommonModule]
})
export class DrawdownChartComponent {
    @Input()
    set drawdownData(value: DrawdownData) {
        this._drawdownData = value;
        if (value) {
            this.minProjection = this.dfsIllustratorService.getProjectionFromDrawdownData(this.drawdownData, DrawdownSeries.MIN);
            this.distConfidenceProjection =
                this.dfsIllustratorService.getProjectionFromDrawdownData(this.drawdownData, DrawdownSeries.DIST_CONFIDENCE);
            this.avgProjection = this.dfsIllustratorService.getProjectionFromDrawdownData(this.drawdownData, DrawdownSeries.AVG);
            setTimeout(() => {
                this.createChart();
            });
        }

    }

    get drawdownData(): DrawdownData {
        return this._drawdownData;
    }

    public showChart: boolean = true;

    @ViewChild('chartContainer', { static: false })
    private chartContainer: ElementRef;

    private _drawdownData: DrawdownData;
    private minProjection: DrawdownProjection;
    private distConfidenceProjection: DrawdownProjection;
    private avgProjection: DrawdownProjection;
    private chartData: Highcharts.Options;
    private readonly CHART_FONT_SIZE: string = '14px';

    constructor(private dfsIllustratorService: DfsIllustratorService) { }

    private createChart(): void {
        this.createChartData();
        Highcharts.setOptions({
            lang: {
                thousandsSep: ','
            }
        });
        Highcharts.chart(this.chartContainer.nativeElement, this.chartData);
    }

    private formatSeriesDataForLine(marketValues: number[]): number[][] {
        if (marketValues?.length) {
            const firstNegativeNumIndex: number = this.getFirstNumBelowZeroIndex(marketValues);
            if (firstNegativeNumIndex > 0 && firstNegativeNumIndex < marketValues.length) {
                // Slice is exclusive of the end index, so we slice one past the value we want
                marketValues = marketValues.slice(0, firstNegativeNumIndex + 1);
            }
            return marketValues
                .map((marketValue: number, index: number) => {
                    return [index, Number(marketValue.toFixed(2))];
                });
        }
        return [];
    }

    private getFirstNumBelowZeroIndex(sortedValues: number[]) {
        if (sortedValues.some((value: number) => value <= 0)) {
            return sortedValues.filter((value: number) => value > 0).length;
        }
        return 0;
    }

    private createRangeData(lowData: number[][], highData: number[][]): Highcharts.PointOptionsObject[] {
        const rangeData: Highcharts.PointOptionsObject[] = [];
        highData.forEach((xyPair: number[]) => {
            const lowDataHasValueAtPoint: boolean = !!lowData?.at(xyPair.at(0));
            const data: Highcharts.PointOptionsObject =
            {
                x: xyPair.at(0),
                low: lowDataHasValueAtPoint ? lowData.at(xyPair.at(0)).at(1) : 0,
                high: xyPair.at(1)
            };
            rangeData.push(data);
        });
        return rangeData;
    }

    private createChartData(): void {
        const averageData: number[][] = this.formatSeriesDataForLine(this.avgProjection?.values);
        const distributionConfidenceData: number[][] = this.formatSeriesDataForLine(this.distConfidenceProjection?.values);
        const poorData: number[][] = this.formatSeriesDataForLine(this.minProjection?.values);
        this.chartData = {
            title: {
                text: ''
            },
            series: [
                {
                    name: 'Average Markets (50% Confidence)',
                    data: averageData,
                    dashStyle: 'LongDash',
                    color: '#66953D',
                    marker: {
                        enabled: true
                    },
                    type: 'line',
                    zIndex: 1
                },
                {
                    name: 'Range',
                    data: this.createRangeData(poorData, averageData),
                    color: '#173B6B',
                    marker: {
                        enabled: false
                    },
                    type: 'arearange',
                    linkedTo: ':previous',
                    fillOpacity: 0.25,
                    zIndex: 0,
                    lineWidth: 0
                },
                {
                    name: `Distribution Confidence (${this.distConfidenceProjection?.series} Confidence)`,
                    data: distributionConfidenceData,
                    color: '#FF8A00',
                    marker: {
                        enabled: true
                    },
                    type: 'line',
                    zIndex: 1
                },
                {
                    name: 'Poor Markets (99% Confidence)',
                    data: poorData,
                    color: '#91140F',
                    dashStyle: 'ShortDash',
                    marker: {
                        enabled: true
                    },
                    type: 'line',
                    zIndex: 1
                }
            ],
            credits: {
                enabled: false
            },
            xAxis: {
                gridLineWidth: 1,
                type: 'linear',
                title: {
                    text: 'Projected Time Horizon (Years)',
                    style: {
                        fontSize: this.CHART_FONT_SIZE,
                        color: '#1C1E22',
                        fontWeight: 'bold'
                    }
                },
                lineWidth: 2,
                min: 0.0,
                gridLineDashStyle: 'LongDash',
                labels: {
                    style: {
                        fontSize: this.CHART_FONT_SIZE
                    }
                }
            },
            yAxis: {
                minorGridLineDashStyle: 'Dash',
                type: 'linear',
                gridLineDashStyle: 'LongDash',
                title: {
                    text: 'Projected Portfolio Value',
                    style: {
                        fontSize: this.CHART_FONT_SIZE,
                        color: '#1C1E22',
                        fontWeight: 'bold'
                    }
                },
                gridLineWidth: 1,
                reversed: false,
                minorTicks: true,
                endOnTick: true,
                lineWidth: 2,
                min: 0,
                labels: {
                    formatter() {
                        if (Number(this.value) >= 1000000000) {
                            return '$' + (Number(this.value) / 1000000000) + 'B';
                        } else if (Number(this.value) as number >= 1000000) {
                            return '$' + Number(this.value) / 1000000 + 'M';
                        } else if (Number(this.value) >= 1000) {
                            return '$' + Number(this.value) / 1000 + 'K';
                        } else {
                            return '$' + this.value;
                        }
                    },
                    style: {
                        fontSize: this.CHART_FONT_SIZE
                    }
                }
            },
            chart: {
                plotBorderWidth: 0,
                height: 500
            },
            legend: {
                layout: 'horizontal',
                itemStyle: {
                    fontSize: this.CHART_FONT_SIZE,
                    margin: 'auto'
                },
                align: 'center',
                labelFormatter() {
                    const confidenceLevelPosition: number = this.name.indexOf('(');
                    const seriesNameSubstring: string = this.name.substring(0, confidenceLevelPosition).trim();
                    const confidenceLevelSubstring: string = this.name.substring(confidenceLevelPosition);
                    return `${seriesNameSubstring}<br/>${confidenceLevelSubstring}`;
                }
            },
            tooltip: {
                style: {
                    fontSize: this.CHART_FONT_SIZE
                },
                valuePrefix: '$',
                outside: false,
                headerFormat: '{point.key} Years<br/>',
                valueDecimals: 2
            }
        };
    }
}
