/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GlobalService } from '@sei/advisor-client-review-proposal-ux';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DfsData, DfsStrategyData, DistributionAnalyticsResponse, DrawdownData, DrawdownProjection, ScenarioCardFormAndChartData } from '../model/dfs-illustrator.model';
import { DfsFormControls, DfsInflationSelection, DrawdownSeries, Frequency, InflationPlaceholderValues } from '../model/enums';

@Injectable({
    providedIn: 'root'
})
export class DfsIllustratorService {

    constructor(private http: HttpClient, private globalService: GlobalService) { }

    public isDfsIllustratorModalVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public dfsIllustrationDetails: BehaviorSubject<DfsData> = new BehaviorSubject<DfsData>(undefined);
    public resizeScenarioCards: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public modelIdToBeAddedToAccount: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
    public dfsDataToPersistToProposalAccount: BehaviorSubject<DfsData> = new BehaviorSubject<DfsData>(undefined);
    public isCarouselModeActive: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
    public readonly MAX_ALLOWED_DFS_SCENARIOS: number = 3;
    public readonly DEFAULT_ADVISOR_FEE: number = 1;

    public closeDfsIllustratorModal(): void {
        this.isDfsIllustratorModalVisible.next(false);
    }

    public openDfsIllustratorModal(): void {
        this.isDfsIllustratorModalVisible.next(true);
    }

    public closeCarouselMode(): void {
        this.isCarouselModeActive.next(0);
    }

    public openCarouselMode(startingIndex: number): void {
        this.isCarouselModeActive.next(startingIndex);
    }

    public createNewDfsIllustrationWithDefaultValues(firmId: number, initialInvestment: number = 0, proposalAccountId: number,
        advisorFee: number): DfsData {
        return {
            modelId: undefined,
            firmId,
            initialInvestment,
            advisorFee: advisorFee || this.DEFAULT_ADVISOR_FEE,
            incomeNeed: 0,
            frequency: '',
            modelFilter: {
                implementationType: '',
                dfsStrategyType: '',
                taxSensitivity: undefined,
                adjustForInflation: undefined,
                customInflationRate: undefined
            },
            proposalAccountId,
            proposalAccountStrategyId: undefined,
            isFormReadOnly: false,
            isNewScenario: true,
            isUserCreatedIllustration: true
        };
    }

    public getProjectionFromDrawdownData(drawdownData: DrawdownData, type: DrawdownSeries): DrawdownProjection {
        return drawdownData.projections.find((projection: DrawdownProjection) => projection.series.includes(type));
    }

    public getDfsDrawdownData(dfsData: DfsData): Observable<DistributionAnalyticsResponse> {
        const url: string = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('getDfsAnalytics'));
        return this.http.post(url, dfsData).pipe(
            map((response: DistributionAnalyticsResponse) => {
                return response;
            })
        );
    }

    public getDfsStrategyData(firmId: number, modelId: number): Observable<DfsStrategyData> {
        const url: string = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('getDfsStrategyData'),
        [
            {
                key: 'firmId',
                value: firmId
            },
            {
                key: 'modelId',
                value: modelId
            }
        ]);
        return this.http.get(url).pipe(
            map((response: DfsStrategyData) => {
                return response;
            })
        );
    }

    public saveDfsFormDetailsToProposalAccountStrategy(formDataToSave: DfsData): Observable<DfsData> {
        const url: string = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('saveDfsForm'));
        return this.http.post(url, formDataToSave).pipe(
            map((response: DfsData) => {
                return response;
            })
        );
    }

    public areRequiredDfsIllustrationInputValuesValid(inputValues: DfsData): boolean {
        return inputValues?.firmId && inputValues?.modelId && inputValues?.initialInvestment > 0 &&
            inputValues?.frequency && inputValues?.incomeNeed > 0;
    }

    public areIncomeNeedOrFrequencyMissingFromModel(inputValues: DfsData): boolean {
        return inputValues?.modelId && (!inputValues?.frequency || !inputValues?.incomeNeed);
    }

    public createDrawdownRequestForModelDerivation(dfsData: DfsData, userProvidedAdvisorFee: number): DfsData {
        const advisorFee: number = userProvidedAdvisorFee >= 0 ? userProvidedAdvisorFee : 1;
        return {
            modelId: dfsData?.modelId,
            firmId: dfsData?.firmId,
            initialInvestment: dfsData?.initialInvestment,
            incomeNeed: dfsData?.incomeNeed,
            frequency: dfsData?.frequency,
            advisorFee
        };
    }

    public createFormGroupWithChartDataForScenarioCard(scenarioCardId: number, analyticsResponse: DistributionAnalyticsResponse,
        existingDfsData: DfsData, useDefaults: boolean): ScenarioCardFormAndChartData {
            const dfsData: DfsData = this.mapAnalyticsResponseToDfsData(analyticsResponse, existingDfsData, useDefaults);
            const dfsFormGroup: FormGroup = this.createDfsFormGroupFromDfsData(dfsData);
            return {
                scenarioCardId,
                dfsData,
                dfsFormGroup,
                analyticsData: analyticsResponse
            };
    }

    public mapAnalyticsResponseToDfsData(responseData: DistributionAnalyticsResponse, existingUserData: DfsData,
        useDefaults: boolean): DfsData {
            const adjustForInflation: boolean = responseData?.modelDetail?.adjustForInflation?.toUpperCase() === 'YES';
            const customInflationRate: number = adjustForInflation ? existingUserData?.modelFilter?.customInflationRate : undefined;
            const defaultFrequency: string = existingUserData?.modelId ? Frequency.ANNUALLY : '';
            return {
                modelId: responseData?.modelDetail?.modelId,
                modelName: responseData?.modelDetail?.modelName,
                firmId: existingUserData?.firmId,
                initialInvestment: existingUserData?.initialInvestment,
                advisorFee: existingUserData?.advisorFee || this.DEFAULT_ADVISOR_FEE,
                isUserCreatedIllustration: existingUserData?.isUserCreatedIllustration,
                frequency: useDefaults ? defaultFrequency : existingUserData?.frequency,
                incomeNeed: existingUserData?.incomeNeed,
                modelFilter: {
                    implementationType: responseData?.modelDetail?.implementationType,
                    dfsStrategyType: responseData?.modelDetail?.dfsStrategyType,
                    taxSensitivity: responseData?.modelDetail?.taxSensitivity,
                    adjustForInflation,
                    customInflationRate: customInflationRate ? Number((customInflationRate).toFixed(2)) : undefined
                },
                proposalAccountId: existingUserData?.proposalAccountId,
                proposalAccountStrategyId: existingUserData?.proposalAccountStrategyId,
                isFormReadOnly: !existingUserData?.isUserCreatedIllustration,
                isNewScenario: useDefaults,
                isDfsStrategyAlreadyPresentInAccount: existingUserData?.isDfsStrategyAlreadyPresentInAccount
            };
    }

    public createDfsFormGroupFromDfsData(dfsData: DfsData): FormGroup {
        if (dfsData) {
            const resolvedAdjustForInflationValue: number = this.getAdjustForInflationValue(dfsData);
            const dfsIllustratorForm = new FormGroup({
                implementationMethod: new FormControl<string>(dfsData.modelFilter?.implementationType, [Validators.required]),
                goalOrObjective: new FormControl<string>(dfsData.modelFilter?.dfsStrategyType, [Validators.required]),
                initialInvestment: new FormControl<string>(
                    this.sanitizeAndFormatToDollarAmount(dfsData.initialInvestment?.toString()),
                    [this.validateAmountIsGreaterThanGivenAmount(0), Validators.required]),
                incomeNeeded: new FormControl<string>(
                    this.sanitizeAndFormatToDollarAmount(dfsData?.incomeNeed?.toString()),
                    [this.validateAmountIsGreaterThanGivenAmount(0), Validators.required]),
                frequency: new FormControl<string>(dfsData?.frequency, [Validators.required]),
                adjustForInflation: new FormControl<number>(resolvedAdjustForInflationValue, [Validators.required]),
                taxSensitivity: new FormControl<boolean>(dfsData.modelFilter?.taxSensitivity, [Validators.required]),
                percentValue: new FormControl<string>({
                    value: this.getInflationRateValue(resolvedAdjustForInflationValue, dfsData.modelFilter?.customInflationRate),
                    disabled: this.isInflationInputDisabled(dfsData)
                },
                    this.setInflationPercentValidatorsIfNeeded(dfsData.modelFilter?.customInflationRate)),
                advisorFee: new FormControl<string>({
                    value: this.sanitizeAndFormatToPercent(dfsData.advisorFee?.toString()),
                    disabled: false
                }, [this.validateAmountIsGreaterThanGivenAmount(-1), Validators.required]
                )
            }, { updateOn: 'blur' });
            if (dfsData.isFormReadOnly) {
                this.disableFormControlsExceptForAdvisorFee(dfsIllustratorForm);
            }
            return dfsIllustratorForm;
        }
        return undefined;
    }

    public createAnalyticsRequestFromFormGroupForSave(formGroup: FormGroup, modelId: number, firmId: number,
        isUserCreatedIllustration: boolean): DfsData {
            if (formGroup && formGroup.getRawValue()) {
                const formValues = formGroup?.getRawValue();
                return {
                    modelId,
                    firmId,
                    initialInvestment: this.convertFormattedStringToNumber(formValues.initialInvestment, false),
                    advisorFee: this.convertFormattedStringToNumber(formValues.advisorFee, true),
                    isUserCreatedIllustration,
                    frequency: formValues.frequency,
                    incomeNeed: this.convertFormattedStringToNumber(formValues.incomeNeeded, false),
                    modelFilter: {
                        implementationType: formValues.implementationMethod,
                        dfsStrategyType: formValues.goalOrObjective,
                        taxSensitivity: formValues.taxSensitivity,
                        adjustForInflation: formValues.adjustForInflation === DfsInflationSelection.STANDARD_PERCENTAGE,
                        customInflationRate: formValues.adjustForInflation === DfsInflationSelection.DO_NOT_INCLUDE ?
                            undefined : this.convertFormattedStringToNumber(formValues.percentValue, true)
                    }
                };
            }
            return undefined;
    }

    public convertFormattedStringToNumber(amountAsString: string, isPercentageNeeded: boolean): number {
        if (!amountAsString) {
            return undefined;
        }
        const convertedNumber: number = Number(amountAsString?.replace(/[^\d.-]+/g, ''));
        if (isPercentageNeeded) {
            return Number((convertedNumber).toFixed(2));
        }
        return convertedNumber;
    }

    public getAnalyticsResponseForDfsData(dfsData: DfsData,
        distributionAnalyticsResponse: DistributionAnalyticsResponse): DistributionAnalyticsResponse {
            if (dfsData?.modelId === distributionAnalyticsResponse?.modelDetail?.modelId) {
                return distributionAnalyticsResponse;
            }
            return undefined;
    }

    public resetServiceState(): void {
        this.dfsIllustrationDetails.next(undefined);
        this.modelIdToBeAddedToAccount.next(undefined);
        this.isCarouselModeActive.next(undefined);
        this.dfsIllustrationDetails.next(undefined);
    }

    private getAdjustForInflationValue(dfsData: DfsData): number {
        if (dfsData?.isNewScenario && !dfsData?.isFormReadOnly && !dfsData?.modelId) {
            return DfsInflationSelection.DEFAULT_SELECT;
        } else if (dfsData?.modelFilter.adjustForInflation && (dfsData?.modelFilter?.customInflationRate === null ||
            dfsData?.modelFilter?.customInflationRate === undefined)) {
                return DfsInflationSelection.STANDARD_PERCENTAGE;
        } else if (dfsData?.modelFilter.adjustForInflation && dfsData?.modelFilter.customInflationRate >= 0) {
            return DfsInflationSelection.CUSTOM_PERCENTAGE;
        } else if (!dfsData?.modelFilter.adjustForInflation &&
            (dfsData?.modelFilter.customInflationRate === undefined || dfsData?.modelFilter.customInflationRate === null)) {
                return DfsInflationSelection.DO_NOT_INCLUDE;
        } else {
            return DfsInflationSelection.DEFAULT_SELECT;
        }
    }

    public sanitizeAndFormatToDollarAmount(valueToTransform: string): string {
        const currencyFormat: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });
        const stringConvertedToNumber: number = Number(valueToTransform?.replace(/[^\d.-]+/g, ''));
        return currencyFormat.format(stringConvertedToNumber);
    }

    public validateAmountIsGreaterThanGivenAmount(minimumAmount: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const convertedAmount: number = this.convertFormattedStringToNumber(control?.value, false);
            return convertedAmount <= minimumAmount ? { invalidAmount: true } : null;
        };
    }

    private getInflationRateValue(resolvedAdjustForInflationValue: number, userProvidedInflationRate: number): string {
        if (resolvedAdjustForInflationValue === DfsInflationSelection.STANDARD_PERCENTAGE) {
            return InflationPlaceholderValues.EMPTY_VALUE;
        } else if (resolvedAdjustForInflationValue === DfsInflationSelection.CUSTOM_PERCENTAGE) {
            return this.sanitizeAndFormatToPercent(userProvidedInflationRate.toString());
        } else if (resolvedAdjustForInflationValue === DfsInflationSelection.DO_NOT_INCLUDE) {
            return InflationPlaceholderValues.ZERO_PERCENT;
        } else {
            return InflationPlaceholderValues.PERCENT_ONLY;
        }
    }

    public sanitizeAndFormatToPercent(valueToTransform: string): string {
        if (!valueToTransform) {
            return undefined;
        }
        const stringConvertedToNumber: number = this.convertFormattedStringToNumber(valueToTransform, true);
        return stringConvertedToNumber + '%';
    }

    private isInflationInputDisabled(dfsData: DfsData): boolean {
        const resolvedInflationValue: number =
            this.getAdjustForInflationValue(dfsData);
        if (resolvedInflationValue !== DfsInflationSelection.CUSTOM_PERCENTAGE) {
            return true;
        }
        return false;
    }

    private setInflationPercentValidatorsIfNeeded(adjustForInflationValue: number): ValidatorFn[] {
        if (adjustForInflationValue && adjustForInflationValue > 0) {
            return [this.validateAmountIsGreaterThanGivenAmount(0), Validators.required];
        }
        return [];
    }

    private disableFormControlsExceptForAdvisorFee(dfsIllustratorForm: FormGroup): void {
        Object.keys(dfsIllustratorForm.controls)?.forEach((controlName: string) => {
            if (controlName !== DfsFormControls.ADVISOR_FEE) {
                dfsIllustratorForm?.get(controlName)?.disable();
            }
        });
    }

}
