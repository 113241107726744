<div #monteCarloDrawer
     class="drawer-container"
     [class.loading]="isLoading"
     [class.loading-complete]="!isLoading">
    <div *ngIf="isLoading; then loading else analytics"></div>
</div>

<ng-template #loading>
    <div class="loading-container">
        <sei-loading [showLoadingIcon]="isLoading"
                     [multiplier]="3"></sei-loading>
    </div>

</ng-template>
<ng-template #analytics>
    <div *ngIf="analyticsData">
        <div class="arrow">
            <div class="line"></div>
            <div class="pointer"></div>
        </div>
        <div class="form-group has-warning warning-message-container"
             *ngIf="displayWarningMessage">
            <div class="row">
                <div class="col-xs-12">
                    <sei-alert [type]="warningType"><span>This goal has been modified. To view an updated simulation, click 'Calculate Achievability' again.</span></sei-alert>
                </div>
            </div>
        </div>
        <div class="statistics-container">
            <div class="row">
                <div class="col-xs-4 col-sm-4 col-lg-3 statistic-card">
                    <span class="statistic">{{getGoalProbability()}}%</span>
                    <p class="statistic-label">Probability of Achieving Target</p>
                </div>
                <div class="col-xs-4 col-sm-4 col-lg-3 statistic-card">
                    <span class="statistic">{{getTargetAmount()}}</span>
                    <p class="statistic-label">Target Amount</p>
                </div>
                <div class="col-xs-4 col-sm-4 col-lg-2 statistic-card">
                    <span class="statistic">{{getTargetDateYear()}}</span>
                    <p class="statistic-label">Target Year</p>
                </div>
                <div class="col-xs-4 col-sm-4 col-lg-2 statistic-card">
                    <span class="statistic">{{getExpectedReturn()}}%</span>
                    <p class="statistic-label">Expected Return*</p>
                </div>
                <div class="col-xs-4 col-sm-4 col-lg-2 statistic-card">
                    <span class="statistic">{{getStandardDeviation()}}%</span>
                    <p class="statistic-label">Standard Deviation*</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 padding-top">
                    <p class="statistic-label disclaimer">*Based on Selected Model</p>
                </div>
            </div>
        </div>
        <div class="highcharts-container row">
            <div class="col-xs-12">
                <sei-car-monte-carlo-chart [monteCarloData]="analyticsData"
                                           [targetDateYear]="getTargetDateYear()"
                                           [leftAlignTargetAmount]="leftAlignTargetAmount()"></sei-car-monte-carlo-chart>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <span class="disclosure">Values displayed in the chart represent end-of-year amounts.<br /><br /><span class="disclosure-label">Important Information: </span>The probability distributions are generated using SEI's proprietary modeling tool and simulated capital market behavior.
                Capital market behavior is simulated for over 1,000 possible scenarios based on expected performance of each asset class and reflecting current economic conditions.
                We use these 1,000 plus scenarios to create over 1,000 output scenarios for each variable being considered.
                This projection is hypothetical in nature, does not reflect actual investment results and is not a guarantee of future results.</span>
            </div>
        </div>
    </div>
    <div *ngIf="!analyticsData">
        <div class="form-group has-warning error-message-container">
            <p class="help-block">Calculation failed. Please try again later.</p>
        </div>
    </div>
</ng-template>
