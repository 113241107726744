/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { AlertNotificationComponent } from './alert-notification/alert-notification.component';
import { AlertOptions } from './alert-notification/model/alert-styles';
import { CarouselComponent } from './carousel/carousel.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { DfsData, DfsStrategyData, DistributionAnalyticsResponse, ScenarioCardFormAndChartData } from './model/dfs-illustrator.model';
import { AlertMessages, AlertStatus, Frequency, ScenarioCardColors, SpinnerSizes } from './model/enums';
import { ScenarioCardComponent } from './scenario-card/scenario-card.component';
import { DfsIllustratorService } from './service/dfs-illustrator.service';

@Component({
    selector: 'sei-car-dfs-illustrator',
    templateUrl: './dfs-illustrator.component.html',
    styleUrls: ['./dfs-illustrator.component.scss'],
    standalone: true,
    imports: [ScenarioCardComponent, CommonModule, LoadingSpinnerComponent, CarouselComponent, AlertNotificationComponent]
})
export class DFSIllustratorComponent implements OnDestroy, OnInit {

    @Input() public dfsIllustratorModalTitle?: string = 'DFS Illustrator';
    @Input() public useSeiDefaultSpinner: boolean = true;
    @Input() public isYShareAllowedForDfsIllustrator: boolean = false;
    @Input() public defaultAdvisorFeePercentage: number = 1;

    @Output() public closeDFSIllustratorModal: EventEmitter<boolean> = new EventEmitter(false);
    @Output() public modelIdToBeAddedToAccount: EventEmitter<number> = new EventEmitter(undefined);

    @ViewChildren(ScenarioCardComponent, { read: ElementRef }) private scenarioCards: QueryList<ElementRef>;

    public dfsIllustratorScenarios: ScenarioCardFormAndChartData[] = [];
    public areMultipleScenariosPresent: boolean = false;
    public addScenarioCardBorderColor: string = ScenarioCardColors.SCENARIO_2_PINK;
    public showAddScenarioCard: boolean = true;
    public areErrorsPresent: boolean = false;
    public isDfsIllustrationDataLoading: boolean = true;
    public spinnerSizes: typeof SpinnerSizes = SpinnerSizes;
    public alertOptions: AlertOptions;
    public doesAccountAlreadyHaveADfsStrategy: boolean = false;
    public isCarouselModeActive: boolean = false;
    public carouselStartingIndex: number;
    public distributionAnalyticsResponse: DistributionAnalyticsResponse;
    public scenarioCardId: number = 1;
    public errorOnInitialLoad: boolean = false;
    public backupFormGroupForFormReset: FormGroup;

    private componentSubscriptions: Subscription[] = [];
    private userProvidedDfsData: DfsData;

    constructor(private dfsIllustratorService: DfsIllustratorService) { }

    public ngOnInit(): void {
        this.dfsIllustratorScenarios = [];
        this.configureModalBackdrop(true);
        const dfsDataPendingSaveToDatabase: DfsData = this.dfsIllustratorService.dfsDataToPersistToProposalAccount.value;
        const dfsDataFromInvestmentSummary: DfsData = this.dfsIllustratorService.dfsIllustrationDetails.value;
        this.userProvidedDfsData = dfsDataPendingSaveToDatabase ? dfsDataPendingSaveToDatabase : dfsDataFromInvestmentSummary;
        this.userProvidedDfsData.advisorFee = this.userProvidedDfsData?.isNewScenario ?
            this.defaultAdvisorFeePercentage : this.userProvidedDfsData.advisorFee;
        this.backupFormGroupForFormReset = this.createDfsDataWithDefaultFormGroupValues()?.dfsFormGroup;
        this.doesAccountAlreadyHaveADfsStrategy =
            dfsDataPendingSaveToDatabase ? true : this.userProvidedDfsData?.isDfsStrategyAlreadyPresentInAccount;
        if (this.dfsIllustratorService.areRequiredDfsIllustrationInputValuesValid(this.userProvidedDfsData)) {
            this.initializeFirstDfsScenarioCardWithStrategyData();
        } else if (this.dfsIllustratorService.areIncomeNeedOrFrequencyMissingFromModel(this.userProvidedDfsData)) {
            this.isDfsIllustrationDataLoading = true;
            const getDfsStrategyDataSubscription: Subscription =
                this.dfsIllustratorService
                    .getDfsStrategyData(this.userProvidedDfsData?.firmId, this.userProvidedDfsData?.modelId).subscribe({
                        next: (dfsStrategyData: DfsStrategyData): void => {
                            if (dfsStrategyData) {
                                this.isDfsIllustrationDataLoading = false;
                                const calculatedIncomeNeed: number =
                                    Number((dfsStrategyData?.dfsDistributionRate * .01 *
                                        this.userProvidedDfsData?.initialInvestment).toFixed(2));
                                this.userProvidedDfsData.incomeNeed = calculatedIncomeNeed;
                                this.userProvidedDfsData.frequency = Frequency.ANNUALLY;
                                this.initializeFirstDfsScenarioCardWithStrategyData();
                            }
                        },
                        error: (error: HttpErrorResponse): void => {
                            const alertOptions: AlertOptions = {
                                status: AlertStatus.ERROR,
                                message: this.errorOnInitialLoad ? AlertMessages.ERROR_RETRY_FAILED : AlertMessages.ERROR_FAILED_REQUEST,
                                showRefresh: !this.errorOnInitialLoad
                            };
                            this.handleErrorFromDrawdownService(error, alertOptions);
                            setTimeout(() => this.resizeCards());
                        }
                    });
            this.componentSubscriptions.push(getDfsStrategyDataSubscription);
        } else {
            if (!this.dfsIllustratorScenarios.length) {
                this.isDfsIllustrationDataLoading = false;
                setTimeout(() => this.resizeCards());
                this.addScenarioCardWithDefaultValues();
            }
        }
        const selectedModelIdSubscription: Subscription =
            this.dfsIllustratorService.modelIdToBeAddedToAccount.subscribe((selectedModelId: number): void => {
                if (selectedModelId) {
                    this.modelIdToBeAddedToAccount.emit(selectedModelId);
                    this.closeModal();
                }
            });
        this.componentSubscriptions.push(selectedModelIdSubscription);

        const carouselModeSubscription: Subscription =
            this.dfsIllustratorService.isCarouselModeActive.subscribe((startingIndex: number): void => {
                this.isCarouselModeActive = startingIndex + 1 > 0 && this.areMultipleScenariosPresent;
                this.carouselStartingIndex = startingIndex;
                if (!this.isCarouselModeActive && this.dfsIllustratorScenarios?.length) {
                    setTimeout(() => this.resizeCards());
                }
            });
        this.componentSubscriptions.push(carouselModeSubscription);
    }

    public initializeFirstDfsScenarioCardWithStrategyData(): void {
        this.isDfsIllustrationDataLoading = true;
        let drawdownRequest: DfsData;
        if (this.userProvidedDfsData?.isNewScenario) {
            drawdownRequest = this.dfsIllustratorService.createDrawdownRequestForModelDerivation(this.userProvidedDfsData,
                this.userProvidedDfsData?.advisorFee);
        } else {
            drawdownRequest = this.userProvidedDfsData;
        }
        const dfsIllustrationDetailsSubscription: Subscription =
            this.dfsIllustratorService.getDfsDrawdownData(drawdownRequest).subscribe({
                next: (analyticsResponse: DistributionAnalyticsResponse) => {
                    this.areErrorsPresent = false;
                    this.distributionAnalyticsResponse = analyticsResponse;
                    const dfsScenarioFormAndChart: ScenarioCardFormAndChartData =
                        this.dfsIllustratorService.createFormGroupWithChartDataForScenarioCard(this.scenarioCardId, analyticsResponse,
                            this.userProvidedDfsData, this.userProvidedDfsData?.isNewScenario);
                    dfsScenarioFormAndChart.dfsData.canStrategyBeAddedToAccount = !this.doesAccountAlreadyHaveADfsStrategy;
                    this.addNewScenarioCardAndIncrementCounter(dfsScenarioFormAndChart);
                },
                error: (error: HttpErrorResponse): void => {
                    const alertOptions: AlertOptions = {
                        status: AlertStatus.ERROR,
                        message: this.errorOnInitialLoad ? AlertMessages.ERROR_RETRY_FAILED : AlertMessages.ERROR_FAILED_REQUEST,
                        showRefresh: !this.errorOnInitialLoad
                    };
                    this.handleErrorFromDrawdownService(error, alertOptions);
                    setTimeout(() => this.resizeCards());
                },
                complete: (): void => {
                    this.isDfsIllustrationDataLoading = false;
                    setTimeout(() => this.resizeCards());
                }
            });
        this.componentSubscriptions.push(dfsIllustrationDetailsSubscription);
    }

    public getAnalyticsResponseForDfsData(dfsData: DfsData,
        distributionAnalyticsResponse: DistributionAnalyticsResponse): DistributionAnalyticsResponse {
        return this.dfsIllustratorService.getAnalyticsResponseForDfsData(dfsData, distributionAnalyticsResponse);
    }

    public ngOnDestroy(): void {
        this.configureModalBackdrop(false);
        this.componentSubscriptions?.forEach((subscription: Subscription): void => {
            subscription?.unsubscribe();
        });
        this.dfsIllustratorService.resetServiceState();
    }

    public closeModal(): void {
        this.dfsIllustratorService.openCarouselMode(undefined);
        this.dfsIllustratorService.closeDfsIllustratorModal();
    }

    public dfsIllustratorDeleted(index: number): void {
        this.dfsIllustratorScenarios.splice(index, 1);
        this.configureCardDisplayOptions();
        if (this.dfsIllustratorScenarios?.length === 1) {
            this.dfsIllustratorService.isCarouselModeActive.next(undefined);
        }
        setTimeout(() => this.resizeCards());
    }

    public onRefreshClick(): void {
        this.componentSubscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
        this.ngOnInit();
    }

    public showScenarios(): boolean {
        return !this.areErrorsPresent || (this.areErrorsPresent && this.errorOnInitialLoad);
    }

    private resizeCards() {
        this.scenarioCards?.forEach((element: ElementRef) => {
            const nativeElement: HTMLElement = element.nativeElement;
            const widthPercentage: string = (100 / this.dfsIllustratorScenarios.length).toFixed(0);
            nativeElement.style.width = `${widthPercentage}%`;
            nativeElement.style.opacity = '1';
        });
        this.dfsIllustratorService.resizeScenarioCards.next(true);
    }

    private handleErrorFromDrawdownService(error: HttpErrorResponse, alertOptions: AlertOptions): void {
        this.areErrorsPresent = true;
        this.errorOnInitialLoad = !this.errorOnInitialLoad;
        this.isDfsIllustrationDataLoading = false;
        this.alertOptions = alertOptions;
        if (this.dfsIllustratorScenarios.length === 0) {
            this.addScenarioCardWithDefaultValues();
        }
        this.dfsIllustratorScenarios.forEach((scenario: ScenarioCardFormAndChartData) => {
            scenario.dfsFormGroup.disable({ onlySelf: false });
            scenario.dfsData.isFormReadOnly = true;
        });
    }

    private configureModalBackdrop(isInitialize: boolean): void {
        document.body.style.overflow = isInitialize ? 'hidden' : 'auto';
        document.body.style.height = isInitialize ? '100%' : 'auto';
    }

    private configureCardDisplayOptions(): void {
        this.checkForMultipleScenarioCards();
        this.addScenarioCardBorderColor = this.setAddScenarioCardBorderColor(this.dfsIllustratorScenarios?.length);
        this.showAddScenarioCard = this.checkIfAddScenarioCardIsVisible(this.dfsIllustratorScenarios?.length);
    }

    private checkForMultipleScenarioCards(): void {
        this.areMultipleScenariosPresent = this.dfsIllustratorScenarios?.length > 1;
    }

    private setAddScenarioCardBorderColor(numberOfScenariosPresent: number): string {
        if (numberOfScenariosPresent === 1) {
            return ScenarioCardColors.SCENARIO_2_PINK;
        } else if (numberOfScenariosPresent === 2) {
            return ScenarioCardColors.SCENARIO_3_PURPLE;
        } else {
            return ScenarioCardColors.SCENARIO_2_PINK;
        }
    }

    private checkIfAddScenarioCardIsVisible(numberOfScenariosPresent: number): boolean {
        return numberOfScenariosPresent < this.dfsIllustratorService.MAX_ALLOWED_DFS_SCENARIOS;
    }

    private addScenarioCardWithDefaultValues(): void {
        if (this.dfsIllustratorScenarios.length < this.dfsIllustratorService.MAX_ALLOWED_DFS_SCENARIOS) {
            const dfsScenarioFormAndUndefinedChart: ScenarioCardFormAndChartData = this.createDfsDataWithDefaultFormGroupValues();
            dfsScenarioFormAndUndefinedChart.dfsData.canStrategyBeAddedToAccount = true;
            this.addNewScenarioCardAndIncrementCounter(dfsScenarioFormAndUndefinedChart);
            this.configureCardDisplayOptions();
            setTimeout(() => this.resizeCards());
        }
    }

    private createDfsDataWithDefaultFormGroupValues(): ScenarioCardFormAndChartData {
        const dfsDataWithDefaultValues: DfsData =
            this.dfsIllustratorService.createNewDfsIllustrationWithDefaultValues(this.userProvidedDfsData?.firmId,
                this.userProvidedDfsData?.initialInvestment, this.userProvidedDfsData?.proposalAccountId,
                    this.defaultAdvisorFeePercentage);
        const dfsScenarioFormAndUndefinedChart: ScenarioCardFormAndChartData =
            this.dfsIllustratorService.createFormGroupWithChartDataForScenarioCard(this.scenarioCardId, undefined,
                dfsDataWithDefaultValues, dfsDataWithDefaultValues?.isNewScenario);
        return dfsScenarioFormAndUndefinedChart;
    }

    private addNewScenarioCardAndIncrementCounter(scenarioCardFormAndChartData: ScenarioCardFormAndChartData): void {
        this.dfsIllustratorScenarios.push(scenarioCardFormAndChartData);
        this.scenarioCardId++;
    }
}
