<div class="spinner-container">
    <div *ngIf="useSeiDefaultSpinner">
        <span class="fa-lg fa-solid fa-spin fa-spinner" [ngStyle]="{'font-size': spinnerSize }"></span>
    </div>

    <div *ngIf="!useSeiDefaultSpinner">
        <mat-progress-spinner mode="indeterminate"
                              [diameter]="materialSpinnerDiameter"></mat-progress-spinner>
    </div>
</div>
