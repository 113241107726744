<div class="list-wrapper">
    <div class="navigation-container">
        <div class="navigation-controls">
            <i class="fa-sharp fa-solid fa-circle-arrow-left navigation-arrow-button" (click)="handleNavigation('left')"></i>
            <span class="navigation-dots-container">
                <i class="fa-sharp fa-solid fa-circle navigation-dot" *ngFor="let scenario of scenarioCards; let i = index;"
                     [ngStyle]="{'color': currentActiveIndex === i ? '#262a35' : '#cbcbcb'}"></i>
            </span>
            <i class="fa-sharp fa-solid fa-circle-arrow-right navigation-arrow-button" (click)="handleNavigation('right')"></i>
        </div>
    </div>
    <ul class="list">
        <li class="item"
            *ngFor="let scenarioCardFormAndChartData of scenarioCards; let i = index;">
            <sei-car-scenario-card [dfsIllustratorIndex]="i"
                                   [scenarioCardFormAndChartData]="scenarioCardFormAndChartData"
                                   [dfsIllustratorCrmRules]="null"
                                   [areMultipleScenariosPresent]="false"
                                   [distributionAnalyticsResponse]="scenarioCardFormAndChartData?.analyticsData"
                                   [doesAccountAlreadyHaveADfsStrategy]="doesAccountAlreadyHaveADfsStrategy"
                                   [isYShareAllowedForDfsIllustrator]="isYShareAllowedForDfsIllustrator"
                                   [backupFormGroupForFormReset]="backupFormGroupForFormReset"
                                   (dfsIllustratorDeleted)="deleteDfsIllustrationByIndex($event)">
            </sei-car-scenario-card>
        </li>
        <li class="item"
            *ngIf="scenarioCards?.length <= 2">
            <div id="add-scenario-button"
                 class="add-scenario-card"
                 (click)="addNewScenario()"
                 [ngStyle]="{'border-color': addScenarioCardBorderColor}">
                <span class="add-scenario-icon" [ngStyle]="{'color': addScenarioCardBorderColor}"><i class="fa-solid fa-square-plus"></i></span>
            </div>
        </li>
    </ul>
</div>
