/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountType } from '@CarInterfaces/account';
import { SeiPayload } from '@CarInterfaces/sei-payload';
import { OwnerRole } from '@CarModels/enums';
import { ExistingAccountAssetDetail, ExistingAccountData, ExistingAccountModelInvestment, ExistingAccountNonModelInvestment, ExistingAccountOwner, ExistingAccountPortfolioPurposeMappingTable, ExistingAccountsRequestBody, ExistingAccountsResponseBody, ExistingAndPreviousAccountData } from '@CarModels/existing-accounts';
import { AccountTypesService } from '@CarServices/account/account-types.service';
import { FeatureFlagService } from '@CarServices/feature-flag/feature-flag.service';
import { GlobalService } from '@CarServices/system/global.service';
import { Store } from '@ngrx/store';
import { Account, Client, CurrentAccountTypeMapping, EntityType, NonModelPortfolioTypeRequest, NonModelPortfolioTypeResponse, OwnerRoleDescription, Proposal, ProposalAccountService, ProposalService, Strategy, Type } from '@sei/advisor-client-review-proposal-ux';
import { FirmInfoSlice, IASCommonAppStore, Portfolio, PortfolioHolding, RiskTolerance } from '@sei/ias-applications-lib-ux';
import * as _ from 'lodash';
import moment from 'moment';
import { AccountTypeOptionSettings } from 'projects/advisor-client-review-proposal-ux/src/lib/model/account-type-options';
import { AccountFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/account-factory';
import { FeesFactory } from 'projects/advisor-client-review-proposal-ux/src/lib/model/factory/fees-factory';
import { ContactTypeUtilityService } from 'projects/advisor-client-review-proposal-ux/src/lib/service/contact-type-utility.service';
import { BehaviorSubject, map, Observable, of, take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ExistingAccountsService {

    public isExistingAccountCallInProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public existingAccountCallFailed: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isExistingAccountsForceRefreshRequired: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showNoModelFoundAlert: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showMappingModalForForceRefresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public doesProposalHaveMissingAssetPricingData: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isClientCallNeeded: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private readonly PME_MODEL_TYPE_STRATEGY = 5;
    private readonly PROPOSAL_MODEL_TYPE_STRATEGY = 2;
    private readonly PROPOSAL_MODEL_TYPE_UMA = 1;
    private readonly MAX_CURRENT_ACCOUNT_SELECTION_AMOUNT = 4;
    private currentAccountToProposedAccountTypeList: string[];
    public existingAccountsToBeAddedToProposal: BehaviorSubject<ExistingAccountData[]> = new BehaviorSubject([]);
    public _existingAccountsToBeAddedToProposalBackUp: ExistingAccountData[] = [];
    private existingAccountModifiedPortfolioPurposeMap: Map<number, Map<number, ExistingAccountPortfolioPurposeMappingTable>> = new Map();
    private readonly MONTH_DAY_YEAR: string = 'MM/DD/YYYY';

    constructor(private globalService: GlobalService,
        private httpClient: HttpClient,
        private contactTypeUtilityService: ContactTypeUtilityService,
        private featureFlagService: FeatureFlagService,
        private accountTypesService: AccountTypesService,
        private appStore: Store<IASCommonAppStore>,
        private proposalAccountService: ProposalAccountService,
        private proposalService: ProposalService) {
    }

    public retrieveExistingAccountsByIdList(
        clientIdsToRetrieve: number[],
        firmId: number,
        onBehalfOfUserId: number,
        scenarioId: number,
        proposalId: number,
        forceRefresh: boolean = false,
        proposal: Proposal = undefined): Observable<Map<number, ExistingAccountData[]>> {
        const url: string = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('retrieveExistingAccounts')
        );
        const accountsMap: Map<number, ExistingAccountData[]> = new Map();
        const retrieveClientData: boolean = clientIdsToRetrieve.length > 0 &&
            (this.proposalService.getExistingAccountsPickListFromCurrentProposal()?.size === 0 ||
                _.isNil(this.proposalService.getExistingAccountsPickListFromCurrentProposal()));
        if ((retrieveClientData || forceRefresh) && this.featureFlagService.checkExistingAccountsSectionEnabled()) {
            if (forceRefresh) {
                this.isExistingAccountsForceRefreshRequired.next(false);
            }
            this.isExistingAccountCallInProgress.next(true);
            const asOfDate: string = moment().format(this.MONTH_DAY_YEAR);
            const body: ExistingAccountsRequestBody = this.createExistingAccountRequestBody(clientIdsToRetrieve,
                firmId,
                onBehalfOfUserId,
                scenarioId,
                proposalId,
                forceRefresh,
                asOfDate
            );
            return this.httpClient.post(url, body)
                .pipe(
                    map((response: SeiPayload) => {
                        const existingAccountsResponseBody: ExistingAccountsResponseBody = {
                            accounts: response.data[0].currentAccounts,
                            asOfDate: response.data[0].asOfDate,
                            proposalRequiresRefresh: response.data[0].proposalRequiresRefresh
                        };
                        clientIdsToRetrieve.forEach((clientId: number) => {
                            const accountList: ExistingAccountData[] = existingAccountsResponseBody.accounts
                                .filter((account: ExistingAccountData) =>
                                    account.accountOwnerList.some((owner: ExistingAccountOwner) =>
                                        Number(owner.endClientId) === Number(clientId) && owner.primary)
                                );
                            this.setExistingAccountsEligibilityStatus(accountList);
                            accountsMap.set(Number(clientId), accountList);
                        });
                        this.isExistingAccountCallInProgress.next(false);
                        this.isExistingAccountsForceRefreshRequired.next(existingAccountsResponseBody.proposalRequiresRefresh);
                        this.proposalService.addExistingAccountsForPickListToProposal(accountsMap);
                        this.proposalService.updateCurrentAccountsAsOfDate(existingAccountsResponseBody.asOfDate);
                        this.mapExistingAccountTypeToProposalAccountType();
                        if (forceRefresh && proposal) {
                            const accountsMapFromProposal: Map<number, ExistingAccountData[]> =
                                this.proposalService.getCurrentProposal()?.scenarios[0]?.existingAccountsForPickList;
                            this.restorePreviouslySelectedCurrentAccounts(
                                this.convertExistingAccountsMapToArray(accountsMapFromProposal), proposal);
                            this.showMappingModalForForceRefresh.next(true);
                        }
                        return accountsMap;
                    })
                );
        } else {
            this.isExistingAccountCallInProgress.next(false);
            return of(this.proposalService.getExistingAccountsPickListFromCurrentProposal());
        }

    }

    public getPortfolioPurposes(request: NonModelPortfolioTypeRequest): Observable<NonModelPortfolioTypeResponse> {
        const url: string = this.globalService.configService.routeFormatter(
            this.globalService.configService.getEndPoint('retrieveNonModelPortfolioTypes'));
        const body: NonModelPortfolioTypeRequest = request;
        return this.httpClient.post<NonModelPortfolioTypeResponse>(url, body);
    }

    public updateModifiedExistingAccountPortfolioMap(value: Map<number, Map<number, ExistingAccountPortfolioPurposeMappingTable>>): void {
        if (value) {
            this.existingAccountModifiedPortfolioPurposeMap = _.cloneDeep(value);
        }
    }

    public retrieveClientIdListFromProposal(proposal: Proposal): number[] {
        const clientIds: number[] = proposal.clients
            .filter((client: Client) => client.entityType === EntityType.Client && Number(client.entityId) !== 9999)
            .map((client: Client) => client.entityId);
        return clientIds;
    }

    public removeUnusedClientAccounts(clients: Client[]): void {
        const accountsMap: Map<number, ExistingAccountData[]> = this.proposalService.getExistingAccountsPickListFromCurrentProposal();
        if (accountsMap) {
            const clientIdsInMap: number[] = [...accountsMap.keys()];
            clientIdsInMap.forEach((clientId: number) => {
                if (!clients.some((client: Client) => Number(client?.entityId) === clientId)) {
                    accountsMap.delete(clientId);
                }
            });
            this.proposalService.addExistingAccountsForPickListToProposal(accountsMap);
        }
    }

    public getExistingAccountById(accountId: number): ExistingAccountData {
        const accountsMap: Map<number, ExistingAccountData[]> = this.proposalService.getExistingAccountsPickListFromCurrentProposal();
        const listOfAccountLists: ExistingAccountData[][] = [...accountsMap.values()];
        let accountList: ExistingAccountData[] = [];
        listOfAccountLists.forEach((existingAccountList: ExistingAccountData[]) => {
            accountList = accountList.concat(existingAccountList);
        });
        return accountList.find((account: ExistingAccountData) => Number(account.accountId) === Number(accountId));
    }

    private createExistingAccountRequestBody(clientIds: number[],
        firmId: number,
        onBehalfOfUserId,
        scenarioId: number,
        proposalId: number,
        forceRefresh: boolean = false,
        asOfDate: string
    ):
        ExistingAccountsRequestBody {
        return { swpEndClientIds: clientIds, firmId, onBehalfOfUserId, scenarioId, proposalId, forceRefresh, asOfDate };
    }

    public getAccountTypeId(account: ExistingAccountData): number {
        const accountTypeOptionSettings: AccountTypeOptionSettings =
            this.contactTypeUtilityService.getContactTypeFromAccountTypeName(account.accountType);
        return accountTypeOptionSettings?.accountTypeId ?
            accountTypeOptionSettings?.accountTypeId :
            1; // TODO Temporary value. To be fixed when fixing account types without direct mapping to proposal account types
    }

    private getAccountType(accountTypeId: number): AccountType {
        const selectedAccountType: AccountType =
            this.accountTypesService?.accountTypes.find((accountType: AccountType) => accountType?.accountTypeId === accountTypeId);
        return selectedAccountType;
    }


    public createAccountFromCurrentAccount(proposal: Proposal,
        existingAccount: ExistingAccountData,
        accountId: number,
        investmentProgramId: number): Account {

        const account: Account = new AccountFactory().createObject(accountId);
        account.fees = new FeesFactory().createFees(0, 0, 0, 0, 0, 0, 0, this.getPlatformFeeBasisPoint());
        account.advisors = proposal?.advisors || proposal?.scenarios[0]?.accounts[0]?.advisors;
        account.balance = _.cloneDeep(existingAccount.actualCurrentMarketValue);
        account.currentAccountId = existingAccount.accountId;
        account.currentAccountNumber = existingAccount.accountNumber;
        account.name = existingAccount.accountName;
        const isInvestmentProgramChanged: boolean = investmentProgramId !== existingAccount.investmentProgramId;
        const existingOwner: ExistingAccountOwner =
            existingAccount.accountOwnerList.find((owner: ExistingAccountOwner) => owner.primary);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const client: any =
            proposal.clients.find((proposalClient: Client) => Number(proposalClient.entityId) === Number(existingOwner.endClientId));
        const primaryOwnerRole: Type = { id: OwnerRole.Primary, description: OwnerRoleDescription.Primary };
        client.ownerRole = primaryOwnerRole;
        client.name = client.labelName;
        account.investmentProgramId = existingAccount.investmentProgramId;
        account.owners = [client];
        account.strategies = isInvestmentProgramChanged ? [] : this.mapStrategiesFromExistingAccountData(existingAccount, accountId);
        account.isCurrentAccountInvestmentsModified = false;
        const accountType: AccountType = this.getAccountType(this.getAccountTypeId(existingAccount));
        account.type = {
            id: accountType.accountTypeId,
            accountTypeId: accountType.accountTypeId,
            description: accountType.accountTypeDescription,
            accountTypeDescription: accountType.accountTypeDescription,
            groupType: accountType.groupType,
            sortOrder: accountType.sortOrder,
            taxQualified: accountType.taxQualified,
            qualifiedPlan: accountType.qualifiedPlan,
            allowJointOwners: accountType.allowJointOwners,
            nonTaxableStatus: existingAccount.nonTaxableStatus
        };
        account.isNewAccount = true;
        account.sweepModelElection = {
            isFdic: existingAccount.fdic,
            currencySweepModelAllocDescription: existingAccount.sweepModelDescription,
            platformSweepModelId: existingAccount.sweepModelId
        };
        account.platformFeeCapDetails = existingAccount.platformFeeCapDetails;
        this.assignTemporaryIdToPortfolioItems(existingAccount.existingNonModelPortfolios);
        const filteredPortfolios: Portfolio[] = this.filterOutPortfoliosWithNoMarketValue(existingAccount?.existingNonModelPortfolios);
        account.portfolios = _.cloneDeep(filteredPortfolios);
        return account;
    }

    public filterOutPortfoliosWithNoMarketValue(portfolios: Portfolio[]): Portfolio[] {
        let filteredPortfolios: Portfolio[] = [];
        if (portfolios && portfolios.length) {
            filteredPortfolios = portfolios
                .filter((portfolioItem: Portfolio) => this.proposalAccountService.calculateValueOfNonModelPortfolio(portfolioItem) !== 0);
        }
        return filteredPortfolios;
    }

    private mapPortfolioPurposeChanges(existingAccount: ExistingAccountData, account: Account): Portfolio[] {
        const changedPortfolioMap: Map<number, ExistingAccountPortfolioPurposeMappingTable> =
            this.existingAccountModifiedPortfolioPurposeMap.get(existingAccount.accountId);
        const mappedPortfolios: Portfolio[] = _.cloneDeep(existingAccount.existingNonModelPortfolios);
        const finalPortfolios: Portfolio[] = [];
        mappedPortfolios.forEach((portfolio: Portfolio) => {
            if (changedPortfolioMap.has(portfolio.existingAccountPortfolioId)) {
                const changedPortfolio = changedPortfolioMap.get(portfolio.existingAccountPortfolioId);
                if (!changedPortfolio.isDeleted) {
                    portfolio.portfolioPurpose = changedPortfolio.selectedPortfolioPurpose;
                    finalPortfolios.push(portfolio);
                } else {
                    const portfolioTotal: number = this.proposalAccountService.calculateValueOfNonModelPortfolio(portfolio);
                    if (portfolioTotal > 0) {
                        account.isCurrentAccountInvestmentsModified = true;
                    }
                }
            } else {
                finalPortfolios.push(portfolio);
            }
        });
        return finalPortfolios;
    }

    private mapStrategiesFromExistingAccountData(existingAccount: ExistingAccountData, accountId): Strategy[] {
        const strategies: Strategy[] = [];
        existingAccount.modelInvestments.forEach((modelInvestment: ExistingAccountModelInvestment) => {
            const strategy: Strategy = {
                proposalAccountStrategyId: 0,
                proposalAccountId: accountId,
                swpStrategyId: undefined,
                cusipId: undefined,
                amount: modelInvestment.amount,
                proposalScenarioGoalId: undefined,
                strategyTypeId: undefined,
                strategyTypeName: undefined,
                fundTickerId: undefined,
                modelId: modelInvestment.modelId,
                customModelTypeId: undefined,
                customModelTypeName: undefined,
                customModelName: undefined,
                modelTypeId: modelInvestment.modelType === this.PME_MODEL_TYPE_STRATEGY ?
                    this.PROPOSAL_MODEL_TYPE_STRATEGY : this.PROPOSAL_MODEL_TYPE_UMA,
                modelTypeName: undefined,
                startingNode: undefined,
                nodeLevel: undefined,
                customStrategyId: undefined,
                customStrategy: undefined,
                investmentProgramId: undefined,
                investmentProgramName: undefined,
                strategyName: modelInvestment.modelName
            };
            strategies.push(strategy);
        });
        return strategies;
    }
    public assignTemporaryIdToPortfolioItems(portfolios: Portfolio[]): void {
        let id: number = 1;
        portfolios.forEach((portfolio: Portfolio) => {
            portfolio?.proposedPortfolioHoldingList?.forEach((holding: PortfolioHolding) => {
                if (!holding?.proposalPortfolioHoldingId) {
                    holding.proposalPortfolioHoldingId = id;
                    id++;
                }
            });
        });
    }

    public resetTemporaryPortfolioIdsForNewCurrentAccounts(proposal: Proposal): void {
        if (proposal) {
            proposal.scenarios[0]?.accounts?.forEach((account) => {
                if (account?.isNewAccount && account?.currentAccountNumber) {
                    account?.portfolios?.forEach((portfolio: Portfolio) => {
                        portfolio.proposalPortfolioId = null;
                        portfolio?.proposedPortfolioHoldingList?.forEach((holdingData: PortfolioHolding) => {
                            holdingData.proposalPortfolioHoldingId = null;
                        });
                    });
                }
            });
            this.proposalService.changedProposal(proposal);
        }
    }

    public filterDeletedPortfoliosAndHoldings(portfoliosToFilter: Portfolio[]): Portfolio[] {
        const filteredPortfolios =
            portfoliosToFilter.filter((portfolio: Portfolio) => portfolio.isExistingAccountPortfolioDeleted === false);
        filteredPortfolios.forEach((portfolio) => {
            const filteredHoldings =
                portfolio.proposedPortfolioHoldingList.filter((holding) => holding.isExistingAccountHoldingDeleted === false);
            portfolio.proposedPortfolioHoldingList = filteredHoldings;
        });
        return filteredPortfolios;
    }

    public resetExistingAccounts(): void {
        this.isExistingAccountsForceRefreshRequired.next(false);
    }

    private getPlatformFeeBasisPoint(): number {
        let platformFeeBasisPoint: number = 0;
        this.appStore.select((slice: IASCommonAppStore): FirmInfoSlice => slice.firmInfoSlice).pipe(take(1))
            .subscribe((slice: FirmInfoSlice) => {
                if (slice?.values?.PlatformFeeBasisPoints) {
                    platformFeeBasisPoint = Number(slice.values.PlatformFeeBasisPoints);
                }
            });
        return platformFeeBasisPoint;
    }

    private mapExistingAccountTypeToProposalAccountType(): void {
        const accountsMap: Map<number, ExistingAccountData[]> = this.proposalService.getExistingAccountsPickListFromCurrentProposal();
        if (accountsMap) {
            this.currentAccountToProposedAccountTypeList = Object.keys(CurrentAccountTypeMapping);
            accountsMap.forEach((value: ExistingAccountData[], key: number) => {
                if (value) {
                    value.forEach((account) => {
                        if (this.currentAccountToProposedAccountTypeList.includes(account.accountType)) {
                            let selectedAccountType: AccountType;
                            const enumItem: string[] = CurrentAccountTypeMapping[account.accountType].split(',');
                            if (enumItem.length === 1) {
                                selectedAccountType = this.accountTypesService.accountTypes.find((accountType: AccountType) =>
                                    accountType.accountTypeId.toString() === enumItem[0]);
                                account.accountType = selectedAccountType.accountTypeDescription;
                            }
                        }
                    });
                }
            });
            this.proposalService.addExistingAccountsForPickListToProposal(accountsMap);
        }
    }

    public getClientByEntityId(clients: Client[], entityId: number): Client {
        let client: Client;
        if (clients?.length && entityId) {
            const matchingClient: Client = clients.find((c: Client) => Number(c?.entityId) === entityId);
            if (matchingClient) {
                client = matchingClient;
            }
        }
        return client;
    }

    public removeExistingAccountFromPickList(existingAccount: ExistingAccountData): void {
        if (existingAccount && existingAccount?.accountNumber) {
            const currentPickList: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal?.value;
            const matchingAccount: ExistingAccountData =
                currentPickList.find((account: ExistingAccountData) => account?.accountNumber === existingAccount?.accountNumber);
            if (matchingAccount) {
                const filteredAccounts: ExistingAccountData[] =
                    currentPickList.filter((account: ExistingAccountData) => account?.accountNumber !== existingAccount?.accountNumber);
                this.existingAccountsToBeAddedToProposal.next(filteredAccounts);
                this.proposalService.removeCurrentAccountFromProposalByAccountNumber(matchingAccount.accountNumber);
            }
        }
    }

    public addExistingAccountsFromPickList(
        existingAccounts: ExistingAndPreviousAccountData[]): void {
        if (existingAccounts) {
            const currentProposal: Proposal = this.proposalService.getCurrentProposal();
            existingAccounts.forEach((accountObject: ExistingAndPreviousAccountData) => {
                if (accountObject?.existingAccount?.accountId) {
                    const existingAccount: ExistingAccountData = accountObject.existingAccount;
                    const previousAccountData: Account = accountObject.previousAccountData || undefined;
                    const currentPickList: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal.value;
                    currentPickList.push(existingAccount);
                    this.existingAccountsToBeAddedToProposal.next(currentPickList);
                    const doesAccountAlreadyExistOnProposal: boolean =
                        currentProposal?.scenarios[0]?.accounts
                            .some((account) => account.currentAccountNumber === existingAccount.accountNumber);
                    if (previousAccountData) {
                        currentProposal.scenarios[0].accounts.push(previousAccountData);
                    } else if (!doesAccountAlreadyExistOnProposal) {
                        const accountId: number = existingAccount.accountId;
                        const proposalAccount: Account =
                            this.createAccountFromCurrentAccount(currentProposal,
                                existingAccount,
                                accountId,
                                existingAccount.investmentProgramId);
                        currentProposal.scenarios[0].accounts.push(proposalAccount);
                    }
                }
            });
            this.proposalService.changedProposal(currentProposal);
        }
    }

    public createBackUpOfSelections(): void {
        this._existingAccountsToBeAddedToProposalBackUp = _.cloneDeep(this.existingAccountsToBeAddedToProposal.value);
    }

    public restoreSelectionsFromBackUp(): void {
        this.existingAccountsToBeAddedToProposal.next(_.cloneDeep(this._existingAccountsToBeAddedToProposalBackUp));
    }

    public isExistingAccountSelectedToBeAddedToProposal(existingAccount: ExistingAccountData): boolean {
        if (existingAccount) {
            const currentPickList: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal?.value;
            const isSelected: ExistingAccountData =
                currentPickList.find((account: ExistingAccountData) => account?.accountId === existingAccount?.accountId);
            if (isSelected) {
                return true;
            }
        }
        return false;
    }

    public removeExistingAccountsNotInProposal(proposal: Proposal) {
        const existingAccounts: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal?.getValue()
            ?.filter((existingAccount: ExistingAccountData) =>
                proposal?.scenarios[0]?.accounts?.some((account: Account) => account?.currentAccountId === existingAccount?.accountId));
        this.existingAccountsToBeAddedToProposal.next(existingAccounts);
        this._existingAccountsToBeAddedToProposalBackUp = _.cloneDeep(existingAccounts);
    }

    public addExistingAccountsFromProposal(proposal: Proposal) {
        const accountsMap: Map<number, ExistingAccountData[]> = this.proposalService.getExistingAccountsPickListFromCurrentProposal();
        const existingAccounts: ExistingAccountData[] = this.existingAccountsToBeAddedToProposal?.getValue();
        proposal?.scenarios[0]?.accounts?.forEach((account: Account) => {
            if (!existingAccounts?.some((existingAccount: ExistingAccountData) =>
                account?.currentAccountId === existingAccount?.accountId)) {
                const allExistingAccounts: ExistingAccountData[] = this.convertExistingAccountsMapToArray(accountsMap);
                const accountToBeSelected: ExistingAccountData = allExistingAccounts.find((accountData: ExistingAccountData) =>
                    accountData?.accountId === account?.currentAccountId);
                existingAccounts.push(accountToBeSelected);
            }
        });
        this.existingAccountsToBeAddedToProposal.next(existingAccounts);
        this._existingAccountsToBeAddedToProposalBackUp = _.cloneDeep(existingAccounts);
    }

    public convertExistingAccountsMapToArray(accountsMap: Map<number, ExistingAccountData[]>): ExistingAccountData[] {
        if (accountsMap) {
            let accountList: ExistingAccountData[] = [];
            const listOfAccountLists: ExistingAccountData[][] = Array.from(accountsMap?.values());
            // Add each list of accounts to the account list
            listOfAccountLists.forEach((list: ExistingAccountData[]) => {
                accountList = accountList.concat(list);
            });
            return accountList;
        }
        return [];
    }

    public deselectAllExistingAccounts(): void {
        const currentProposal: Proposal = this.proposalService.getCurrentProposal();
        const allAccountsOnProposal: Account[] = currentProposal?.scenarios[0]?.accounts;
        const filteredAccounts: Account[] = allAccountsOnProposal?.filter((account) => !account?.currentAccountId);
        currentProposal.scenarios[0].accounts = filteredAccounts;
        this.proposalService.changedProposal(currentProposal);
        this.existingAccountsToBeAddedToProposal.next([]);
    }

    public isPrimaryAdvisorInfoPresentInSF(existingAccount: ExistingAccountData): boolean {
        return existingAccount?.currentAccountPrimaryAdvisorInfo === undefined ? false :
            !existingAccount?.currentAccountPrimaryAdvisorInfo.isAdvisorPresentInSF;
    }

    public selectAllEligibleExistingAccounts(): void {
        const accountsMap: Map<number, ExistingAccountData[]> = this.proposalService.getExistingAccountsPickListFromCurrentProposal();
        const allExistingAccounts: ExistingAccountData[] = this.convertExistingAccountsMapToArray(accountsMap);
        const filteredExistingAccounts: ExistingAccountData[] =
            allExistingAccounts.filter(this.checkIfAccountIsEligibleForSelection.bind(this));
        this.existingAccountsToBeAddedToProposal.next(filteredExistingAccounts);
        const accounts: ExistingAndPreviousAccountData[] = filteredExistingAccounts.map((value: ExistingAccountData) => {
            return { existingAccount: value, previousAccountData: undefined };
        });
        this.addExistingAccountsFromPickList(accounts);
    }

    private checkIfAccountIsEligibleForSelection(existingAccount: ExistingAccountData): boolean {
        const doesAccountHaveMarketValue: boolean = existingAccount?.modelMarketValue > 0 ||
            existingAccount?.nonModelMarketValue > 0;
        return doesAccountHaveMarketValue && !this.isPrimaryAdvisorInfoPresentInSF(existingAccount)
            && existingAccount.isAccountEligibleForSelection && !this.doesAccountContainNonCashAssetWithNegativeBalance(existingAccount);
    }

    public checkIsMaxNumberOfCurrentAccountsSelected(): boolean {
        const isMaxCurrentAccountsSelected: boolean = this.existingAccountsToBeAddedToProposal?.value?.length ?
            this.existingAccountsToBeAddedToProposal?.value?.length >= this.MAX_CURRENT_ACCOUNT_SELECTION_AMOUNT : false;
        return isMaxCurrentAccountsSelected;
    }


    public doesAccountContainNonCashAssetWithNegativeBalance(existingAccountData: ExistingAccountData): boolean {
        return existingAccountData?.nonModelInvestments?.some((portfolio: ExistingAccountNonModelInvestment) =>
            portfolio?.assetDetailsList?.some((asset: ExistingAccountAssetDetail) => asset?.value * asset?.tradeDatedQuantity < 0)
        ) || existingAccountData?.modelInvestments?.some((model: ExistingAccountModelInvestment) =>
            model?.amount < 0 || (model?.doesCurrentAllocationsContainNegativeAsset && !model?.isUMAEligibleForSelection));
    }

    public removeExistingAccountsFromMapByClientId(clientId: number): ExistingAccountData[] {
        const accountsMap: Map<number, ExistingAccountData[]> = this.proposalService.getExistingAccountsPickListFromCurrentProposal();
        const currentAccountsMap: Map<number, ExistingAccountData[]> = _.cloneDeep(accountsMap);
        const accountsBackup: ExistingAccountData[] = accountsMap.get(clientId);
        currentAccountsMap.delete(clientId);
        this.proposalService.addExistingAccountsForPickListToProposal(currentAccountsMap);
        return accountsBackup;
    }

    public restoreExistingAccountsForClients(backupMap: Map<number, ExistingAccountData[]>): void {
        const accountsMap: Map<number, ExistingAccountData[]> = this.proposalService.getExistingAccountsPickListFromCurrentProposal();
        this.proposalService.addExistingAccountsForPickListToProposal(new Map([...accountsMap].concat([...backupMap])));
    }

    public restoreAccountsFromExistingAccountDataBackUp(proposal: Proposal): void {
        this.createBackUpOfSelections();
        const reconstructedAccounts: Account[] = [];
        const onlyProposedAccounts: Account[] = proposal?.scenarios[0]?.accounts?.filter((account) => !account?.currentAccountNumber);
        this._existingAccountsToBeAddedToProposalBackUp?.forEach((existingAccountData: ExistingAccountData, i: number) => {
            reconstructedAccounts.push(
                this.createAccountFromCurrentAccount(proposal, existingAccountData, i, existingAccountData?.investmentProgramId)
            );
        });
        proposal.scenarios[0].accounts = _.cloneDeep(onlyProposedAccounts.concat(reconstructedAccounts));
        this.proposalService.changedProposal(proposal);
    }

    public restorePreviouslySelectedCurrentAccounts(refreshedExistingAccountData: ExistingAccountData[], currentProposal: Proposal): void {
        if (refreshedExistingAccountData?.length) {
            const reconstructedAccounts: Account[] = [];
            const proposedAccountsFromProposal: Account[] =
                currentProposal?.scenarios[0]?.accounts?.filter((account: Account) => !account.currentAccountNumber);
            const currentAccountsFromProposal: Account[] =
                currentProposal?.scenarios[0]?.accounts?.filter((account: Account) => account.currentAccountNumber);
            const previouslySelectedExistingAccountNumbers: string[] =
                currentAccountsFromProposal?.map((currentAccount) => currentAccount?.currentAccountNumber);
            refreshedExistingAccountData?.forEach((incomingExistingAccount: ExistingAccountData, i: number) => {
                if (previouslySelectedExistingAccountNumbers?.includes(incomingExistingAccount?.accountNumber)) {
                    const reconstructedAccount: Account = this.createAccountFromCurrentAccount(currentProposal, incomingExistingAccount,
                        i, incomingExistingAccount?.investmentProgramId);
                    reconstructedAccount.riskTolerance =
                        this.reassignRiskToReconstructedAccount(reconstructedAccount, currentAccountsFromProposal);
                    reconstructedAccounts.push(reconstructedAccount);
                }
            });
            currentProposal.scenarios[0].accounts = _.cloneDeep(proposedAccountsFromProposal.concat(reconstructedAccounts));
            this.proposalService.changedProposal(currentProposal);
        }
    }

    private reassignRiskToReconstructedAccount(reconstructedAccount: Account, currentAccountsFromProposal: Account[]): RiskTolerance {
        if (reconstructedAccount && currentAccountsFromProposal.length) {
            const matchingAccountFromProposal: Account =
                currentAccountsFromProposal.find((account) => account?.currentAccountNumber === reconstructedAccount?.currentAccountNumber);
            if (matchingAccountFromProposal) {
                return matchingAccountFromProposal.riskTolerance;
            }
            return new AccountFactory().createRiskTolerance();
        }
    }

    private setExistingAccountsEligibilityStatus(existingAccounts: ExistingAccountData[]): void {
        if (existingAccounts && existingAccounts.length) {
            existingAccounts.forEach((existingAccount) => {
                existingAccount.isAccountEligibleForSelection =
                    existingAccount?.modelInvestments
                        ?.every((model) => model?.isModelAvailableInModelDetail || model?.isModelAvailableInModelDetail === null);
            });
        }
    }

    public applyMappedPortfoliosToProposalAccounts(): void {
        const currentProposal: Proposal = this.proposalService.getCurrentProposal();
        const proposalAccounts: Account[] = currentProposal?.scenarios[0]?.accounts;
        const allAvailableExistingAccounts: ExistingAccountData[] =
            this.convertExistingAccountsMapToArray(currentProposal?.scenarios[0]?.existingAccountsForPickList);
        if (proposalAccounts && proposalAccounts.length) {
            proposalAccounts.forEach((account: Account) => {
                const matchingSnapShot: ExistingAccountData =
                    allAvailableExistingAccounts.find((existing) => existing?.accountId === account?.currentAccountId);
                if (this.existingAccountModifiedPortfolioPurposeMap.has(account.currentAccountId)) {
                    const portfolios: Portfolio[] = this.mapPortfolioPurposeChanges(matchingSnapShot, account);
                    const filteredPortfolios: Portfolio[] = this.filterOutPortfoliosWithNoMarketValue(portfolios);
                    account.portfolios = _.cloneDeep(filteredPortfolios);
                }
            });
            this.proposalService.changedProposal(currentProposal);
        }
    }

    public resetSelections(): void {
        this.existingAccountsToBeAddedToProposal.next([]);
        this._existingAccountsToBeAddedToProposalBackUp = [];
        this.existingAccountModifiedPortfolioPurposeMap = new Map();
    }

    public getSelectedExistingAccountsFromProposal(): ExistingAccountData[] {
        const selectedExistingAccounts: ExistingAccountData[] = [];
        const currentProposal: Proposal = this.proposalService.getCurrentProposal();
        const proposedCurrentAccountNumbers: string[] =
            currentProposal?.scenarios[0]?.accounts?.filter((account: Account) => account.currentAccountNumber)
                ?.map((currentAccount) => currentAccount.currentAccountNumber);
        const existingAccounts: ExistingAccountData[] =
            this.convertExistingAccountsMapToArray(currentProposal?.scenarios[0]?.existingAccountsForPickList);
        existingAccounts.forEach((existingAccount) => {
            if (proposedCurrentAccountNumbers.includes(existingAccount?.accountNumber)) {
                selectedExistingAccounts.push(existingAccount);
            }
        });
        return selectedExistingAccounts;
    }

    public checkIfAnyCurrentAccountHasAssetsWithMissingPricing(proposal: Proposal): void {
        const proposedCurrentAccounts: Account[] =
            proposal?.scenarios[0]?.accounts?.filter((account) => account?.currentAccountNumber);
        if (proposedCurrentAccounts?.length) {
            proposedCurrentAccounts?.forEach((currentAccount) => {
                currentAccount?.portfolios?.forEach((portfolio) => {
                    portfolio?.proposedPortfolioHoldingList?.forEach((holdingData) => {
                        if (_.isNil(holdingData?.assetData?.estimatedValue)) {
                            this.doesProposalHaveMissingAssetPricingData.next(true);
                        }
                    });
                });
            });
        }
    }

}
