/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { BannerMessageConstants, WarningModalConstants } from '@CarModels/constants';
import { ExistingAccountData } from '@CarModels/existing-accounts';
import { ExistingAccountsService } from '@CarServices/existing-accounts/existing-accounts.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account, Client, CommandText, DelegationService, GenericErrorService, Proposal, ProposalAccountService, ProposalService, RightHandFooterFunctions, Scenario } from '@sei/advisor-client-review-proposal-ux';
import { BannerMessageType, ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'sei-car-existing-accounts-pick-list',
    templateUrl: './existing-accounts-pick-list.component.html',
    styleUrls: ['./existing-accounts-pick-list.component.scss']
})
export class ExistingAccountsPickListComponent extends ParentComponentSubscriptionManager implements OnInit {

    public existingAccountsNotAddedToProposal: Map<number, ExistingAccountData[]>;
    public proposal: Proposal;
    public footerFunction: string = RightHandFooterFunctions.SelectExistingAccounts;
    public totalNumberOfExistingAccounts: number = 0;
    public totalNumberOfProposedAccounts: number = 0;
    public isAccountTotalBelowThreshold: boolean = true;
    public selectedExistingAccounts: ExistingAccountData[] = [];
    public _selectedExistingAccountsBackup: ExistingAccountData[] = [];
    public isExistingAccountsCallInProgress: boolean = false;
    public unselectedAccountsModalMessage: WarningModalConstants = WarningModalConstants.MODAL_MESSAGE_UNSELECTED_ACCOUNTS;
    public showUnselectedAccountsModal: boolean = false;
    public addSelectedAccountClicked: boolean = false;
    public onPortfolioMappingComplete: boolean = false;
    public showMappingModal: boolean = false;
    public getHoldingsCallFailed: boolean = false;
    public typeWarning: BannerMessageType = BannerMessageType.warning;
    public getHoldingsCallFailedWarning: string = BannerMessageConstants.GET_HOLDINGS_CALL_FAILED;
    private _proposalAccountsBackup: Account[] = [];

    constructor(private existingAccountsService: ExistingAccountsService,
        private proposalService: ProposalService,
        private delegationService: DelegationService,
        private proposalAccountService: ProposalAccountService,
        private genericErrorService: GenericErrorService,
        private router: Router) {
        super('ExistingAccountsPickListComponent');
    }

    ngOnInit(): void {
        const proposalSubscription: Subscription = this.proposalService.currentProposal.pipe(
            map((proposal: Proposal, index: number): Proposal => {
                if (index === 0 && proposal) {
                    this._proposalAccountsBackup = _.cloneDeep(proposal?.scenarios[0]?.accounts);
                }
                return proposal;
            })
        ).subscribe({
            next: (proposal: Proposal): void => {
                if (proposal) {
                    this.proposalService.proposalBackup = _.cloneDeep(proposal);
                    this.proposal = proposal;
                    this.totalNumberOfProposedAccounts =
                        proposal?.scenarios[0]?.accounts?.filter((account: Account) => !account.currentAccountId).length;
                    this.isAccountTotalBelowThreshold =
                        this.proposalAccountService
                            .checkIfBelowMaximumNumOfAccounts(proposal?.scenarios[0]?.accounts);
                    setTimeout(() => {
                        this.proposal?.scenarios[0]?.accounts?.some((account: Account) => account.currentAccountId) ?
                            this.delegationService.publish(CommandText.AddSelectedAccountsButton) :
                            this.delegationService.publish(CommandText.SaveAndExitButton);
                    });
                    if (proposal?.scenarios[0]?.existingAccountsForPickList?.size > 0) {
                        this.existingAccountsNotAddedToProposal = proposal?.scenarios[0]?.existingAccountsForPickList;
                        this.totalNumberOfExistingAccounts =
                            this.existingAccountsService
                                .convertExistingAccountsMapToArray(this.existingAccountsNotAddedToProposal)?.length;
                    }
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(proposalSubscription);

        this.selectedExistingAccounts = this.existingAccountsService.existingAccountsToBeAddedToProposal.value;
        this._selectedExistingAccountsBackup = _.cloneDeep(this.existingAccountsService.existingAccountsToBeAddedToProposal.value);
        this.isAccountTotalBelowThreshold =
            this.proposalAccountService
                .checkIfBelowMaximumNumOfAccounts(this.proposal?.scenarios[0]?.accounts);
        if (this.selectedExistingAccounts.length) {
            this.delegationService.publish(CommandText.EnableProposedAccountsButton);
        } else {
            this.delegationService.publish(CommandText.DisableAddProposedAccountsButton);
        }

        const delegationSubscription: Subscription = this.delegationService.refresh().subscribe({
            next: (command: string): void => {
                switch (command) {
                    case CommandText.AddSelectedAccounts:
                        this.selectedExistingAccounts = this.existingAccountsService.existingAccountsToBeAddedToProposal.value;
                        this.showAccountRemovalModal();
                        this.addSelectedAccountClicked = true;
                        break;
                    case CommandText.CancelExistingAccountsPickListSelections:
                        this.existingAccountsService.existingAccountsToBeAddedToProposal.next(this._selectedExistingAccountsBackup);
                        this.restoreProposalAccounts();
                        break;
                    default:
                        return null;
                }
            },
            error: (error) => {
                this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
            }
        });
        this.subscriptions.push(delegationSubscription);

        const existingAccountCallStatusSubscription: Subscription =
            this.existingAccountsService.isExistingAccountCallInProgress.subscribe({
                next: (callStatus: boolean) => {
                    this.isExistingAccountsCallInProgress = callStatus;
                },
                error: (error) => {
                    this.genericErrorService.setGenericError({ code: '', description: `${error.message}` });
                }
            });
        this.subscriptions.push(existingAccountCallStatusSubscription);

        const existingAcconutsCallFailureStatus: Subscription =
            this.existingAccountsService.existingAccountCallFailed.subscribe({
                next: (failureStatus: boolean): void => {
                    this.getHoldingsCallFailed = failureStatus;
                }
            });
        this.subscriptions.push(existingAcconutsCallFailureStatus);
    }

    public getClientFromEntityId(clients: Client[], entityId: number): Client {
        return this.existingAccountsService.getClientByEntityId(clients, entityId);
    }

    public showAccountRemovalModal(): void {
        const currentSelections: ExistingAccountData[] = this.existingAccountsService.existingAccountsToBeAddedToProposal.value;
        const allAddedExistingAccountsStillSelected: boolean = this._proposalAccountsBackup
            ?.filter((account: Account) => account?.currentAccountId)
            ?.every((account: Account) => currentSelections
                ?.some((existingAccount: ExistingAccountData) => Number(existingAccount?.accountId) === Number(account?.currentAccountId)));
        if (allAddedExistingAccountsStillSelected) {
            this.showMappingModal = true;
        } else {
            this.showUnselectedAccountsModal = true;
        }
    }

    public onUnselectedAccountsModalContinue(): void {
        this.showUnselectedAccountsModal = false;
        const currentSelections: ExistingAccountData[] = this.existingAccountsService.existingAccountsToBeAddedToProposal.value;
        if (currentSelections?.length > 0) {
            this.showMappingModal = true;
        } else {
            this.router.navigate([`/Proposal/WIP/${this.proposal?.id}/Scenarios/${this.proposal?.scenarios?.at(0)?.id}/ProposedAccounts`]);
        }

    }

    public onUnselectedAccountsModalCancel(): void {
        this.proposalService.changedProposal(this.proposalService.proposalBackup);
        this.showUnselectedAccountsModal = false;
    }

    public resetMappingSemaphoreValues(): void {
        this.addSelectedAccountClicked = false;
        this.showMappingModal = false;
    }

    private restoreProposalAccounts(): void {
        const currentProposal: Proposal = this.proposalService.getCurrentProposal();
        currentProposal.scenarios[0].accounts = this._proposalAccountsBackup;
        this.proposalService.changedProposal(_.cloneDeep(currentProposal));
    }

    public getNewCurrentSelectedAccounts(): ExistingAccountData[] {
        const newAccountCurrentAccountIds: number[] = this.proposal?.scenarios
            ?.flatMap((scenario: Scenario) => scenario?.accounts
                ?.filter((account: Account) => account?.isNewAccount && account?.currentAccountId)
                ?.map((account: Account) => Number(account?.currentAccountId)));
        return this.existingAccountsService?.existingAccountsToBeAddedToProposal?.value
            ?.filter((existingAccountData: ExistingAccountData) =>
                newAccountCurrentAccountIds.includes(Number(existingAccountData.accountId)));
    }

}
