<div class="card-and-drawer-container">
    <div [id]="'card-contents-' + dfsIllustratorIndex"
         class="scenario-card-container"
         [ngStyle]="{'border-color': scenarioCardBorderColor}">
        <div class="scenario-card-header-container">
            <div [id]="'card-header-' + dfsIllustratorIndex"
                 class="scenario-card-header-title"
                 [ngStyle]="{'border-color': scenarioCardBorderColor}">
                <h2>Illustration {{ dfsIllustratorIndex + 1 }}</h2>
            </div>
            <div class="scenario-card-header-buttons">
                <div id="scenario-card-actions">
                    <div class="add-to-account-button-container"
                         [matTooltip]="toolTipMessages.RECALCULATE_TO_CONTINUE"
                         [matTooltipDisabled]="!dfsIllustratorForm.dirty"
                         matTooltipPosition="above"
                         *ngIf="dfsDrawdownData">
                        <span class="quick-actions"
                            [class.button-disabled]="!scenarioCardFormAndChartData?.dfsData?.canStrategyBeAddedToAccount && !isScenarioRedrawn || dfsIllustratorForm.dirty"
                            (click)="addSeletedModelToAccount()">
                                Add to Account
                        </span>
                        <span class="spacer"> | </span>
                    </div>
                    <span class="quick-actions" (click)="resetDfsIllustratorForm()">Clear Values <i class="fa-solid fa-arrow-rotate-left"></i></span>
                    <span class="spacer" *ngIf="(areMultipleScenariosPresent || showModifyCardOption || isCarouselModeActive)">|</span>
                    <i *ngIf="!isCarouselModeActive && areMultipleScenariosPresent" class="fa-solid fa-up-right-and-down-left-from-center quick-actions" (click)="openCarouselMode(dfsIllustratorIndex)"></i>
                    <i *ngIf="isCarouselModeActive" class="fa-solid fa-down-left-and-up-right-to-center quick-actions" (click)="openCarouselMode(undefined)"></i>
                    <span class="modify-card" *ngIf="showModifyCardOption">Modify <i class="fa-duotone fa-solid fa-pen"></i></span>
                </div>
                <span (click)="deleteDfsIllustrationByIndex(dfsIllustratorIndex)" class="delete-icon"
                    [ngStyle]="{'display': areMultipleScenariosPresent || isCarouselModeActive ? 'initial' : 'none', 'color': scenarioCardBorderColor}">
                    <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                </span>
            </div>
        </div>
        <form [formGroup]="dfsIllustratorForm">
            <div class="container">
                <div class="row">
                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-6' : 'col-xs-3'"
                         class="dfs-form-container">
                        <label class="element-label">Initial Investment</label>
                        <input class="form-control"
                               [class.border-red]="isFormElementInvalid(initialInvestment)"
                               [id]="'initial-investment-amount-' + dfsIllustratorIndex"
                               formControlName="initialInvestment" />
                        <span class="warning-text" *ngIf="isFormElementInvalid(initialInvestment)">
                            Initial Investment is required.
                        </span>
                    </div>

                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-6' : 'col-xs-3'"
                         class="dfs-form-container">
                        <label class="element-label">Implementation Method</label>
                        <mat-select id="implementation-method"
                                    name="implementationMethod"
                                    [id]="'implementation-method-' + dfsIllustratorIndex"
                                    formControlName="implementationMethod"
                                    class="dfs-illustrator-select"
                                    [disabled]="isFormReadOnly"
                                    [class.disabled]="isFormReadOnly"
                                    [class.border-red]="isFormElementInvalid(implementationMethod)"
                                    placeholder="Select">
                            <mat-option *ngIf="!isYShareAllowedForDfsIllustrator"
                                        [value]="implementationTypes.MF">{{ implementationTypes.MUTUAL_FUNDS }}</mat-option>
                            <mat-option [value]="implementationTypes.ETF">{{ implementationTypes.ETFS }}</mat-option>
                        </mat-select>
                        <span class="warning-text" *ngIf="isFormElementInvalid(implementationMethod)">
                            Implementation Method is required.
                        </span>
                    </div>

                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-6' : 'col-xs-3'"
                         class="dfs-form-container">
                        <label class="element-label">Objective</label>
                        <mat-select [id]="'goal-or-objective-' + dfsIllustratorIndex"
                                    name="goalOrObjective"
                                    formControlName="goalOrObjective"
                                    class="dfs-illustrator-select"
                                    [disabled]="isFormReadOnly"
                                    [class.disabled]="isFormReadOnly"
                                    [class.border-red]="isFormElementInvalid(goalOrObjective)"
                                    placeholder="Select">
                            <mat-option [value]="goalOrObjectiveOptions.GROWTH_AND_STABILITY">Growth & Stability</mat-option>
                            <mat-option [value]="goalOrObjectiveOptions.STABILITY">Stability</mat-option>
                            <mat-option [value]="goalOrObjectiveOptions.GROWTH_TILT">Growth</mat-option>
                        </mat-select>
                        <span class="warning-text" *ngIf="isFormElementInvalid(goalOrObjective)">
                            Goal/Objective is required.
                        </span>
                    </div>

                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-6' : 'col-xs-3'"
                         class="dfs-form-container">
                        <label class="element-label">Tax Sensitivity</label>
                        <mat-select [id]="'tax-sensitivity-' + dfsIllustratorIndex"
                                    name="tax-sensitivity"
                                    formControlName="taxSensitivity"
                                    class="dfs-illustrator-select"
                                    [disabled]="isFormReadOnly"
                                    [class.disabled]="isFormReadOnly"
                                    [class.border-red]="isFormElementInvalid(taxSensitivity)"
                                    placeholder="Select">
                            <mat-option [value]="true">Yes</mat-option>
                            <mat-option [value]="false">No</mat-option>
                        </mat-select>
                        <span class="warning-text" *ngIf="isFormElementInvalid(taxSensitivity)">
                            Tax Sensitivity is required.
                        </span>
                    </div>
                </div>

                <div class="row">
                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-6' : 'col-xs-3'"
                         class="dfs-form-container">
                        <label class="element-label">Income Needed</label>
                        <input class="form-control"
                               [class.border-red]="isFormElementInvalid(incomeNeeded)"
                               [id]="'income-needed-' + dfsIllustratorIndex"
                               formControlName="incomeNeeded">
                        <span class="warning-text" *ngIf="isFormElementInvalid(incomeNeeded)">
                            Income Needed is required.
                        </span>
                    </div>

                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-6' : 'col-xs-3'"
                         class="dfs-form-container">
                        <label class="element-label">Frequency</label>
                        <mat-select [id]="'frequency-' + dfsIllustratorIndex"
                                    name="frequency"
                                    formControlName="frequency"
                                    class="dfs-illustrator-select"
                                    [disabled]="isFormReadOnly"
                                    [class.disabled]="isFormReadOnly"
                                    [class.border-red]="isFormElementInvalid(frequency)"
                                    placeholder="Select">
                            <mat-option [value]="frequencyOptions.ANNUALLY">Annually</mat-option>
                            <mat-option [value]="frequencyOptions.SEMI_ANNUALLY">Semi-Annually</mat-option>
                            <mat-option [value]="frequencyOptions.QUARTERLY">Quarterly</mat-option>
                            <mat-option [value]="frequencyOptions.MONTHLY">Monthly</mat-option>
                        </mat-select>
                        <span class="warning-text" *ngIf="isFormElementInvalid(frequency)">
                            Frequency is required.
                        </span>
                    </div>
                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-3 padding-right' : 'col-xs-2'"
                         class="dfs-form-container">
                        <label class="element-label">Adjust for Inflation</label>
                        <mat-select [id]="'adjust-for-inflation-' + dfsIllustratorIndex"
                                    name="adjust-for-inflation"
                                    formControlName="adjustForInflation"
                                    class="dfs-illustrator-select"
                                    [disabled]="isFormReadOnly"
                                    [class.disabled]="isFormReadOnly"
                                    [class.border-red]="isFormElementInvalid(adjustForInflation)"
                                    placeholder="Select">
                            <mat-option [value]="inflationAdjustmentOptions.STANDARD_PERCENTAGE">Yes - Standard</mat-option>
                            <mat-option [value]="inflationAdjustmentOptions.CUSTOM_PERCENTAGE">Yes - Custom</mat-option>
                            <mat-option [value]="inflationAdjustmentOptions.DO_NOT_INCLUDE">No</mat-option>
                        </mat-select>
                        <span class="warning-text" *ngIf="isFormElementInvalid(adjustForInflation)">
                            Adjust for Inflation is required.
                        </span>
                    </div>

                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-3' : 'col-xs-2'"
                         class="dfs-form-container">
                        <label class="element-label">Inflation Rate <i [matTooltip]="toolTipMessages.STANDARD_INFLATION_RATE" matTooltipPosition="above" *ngIf="inflationAdjustmentOptions.STANDARD_PERCENTAGE === adjustForInflation?.value" class="info-icon fa-solid fa-circle-info"></i></label>
                        <input class="form-control"
                               [class.border-red]="isFormElementInvalid(percentValue)"
                               [id]="'percent-value-' + dfsIllustratorIndex"
                               name="percentValue"
                               formControlName="percentValue"
                               [readonly]="!adjustForInflation.value"
                               [placeholder]="percentValueFormElementPlaceholder">
                        <span class="warning-text" *ngIf="isFormElementInvalid(percentValue)">
                            Percent is required.
                        </span>
                    </div>

                    <div [ngClass]="areMultipleScenariosPresent ? 'col-xs-6' : 'col-xs-2'"
                         class="dfs-form-container">
                        <label class="element-label">Advisor Fee</label>
                        <input class="form-control"
                               [class.border-red]="isFormElementInvalid(advisorFee)"
                               [id]="'advisor-fee-' + dfsIllustratorIndex"
                               name="advisorFee"
                               formControlName="advisorFee"
                               placeholder="0%">
                        <span class="warning-text" *ngIf="isFormElementInvalid(advisorFee)">
                            Advisor Fee is required.
                        </span>
                    </div>
                </div>
            </div>
        </form>
        <div class="calculate-values-button-container">
            <button [disabled]="isFormInvalid() || isDfsIllustratorRequestInProgress || disableCalculateButton"
                    [class.calculation-disabled]="isFormInvalid() || isDfsIllustratorRequestInProgress || disableCalculateButton"
                    (click)="toggleDrawdownChartVisibility()"
                    class="calculate-values-button"
                    [ngStyle]="{'border-color': scenarioCardBorderColor, 'background-color': scenarioCardBorderColor}">
                {{ calculateButtonText }}
            </button>
        </div>
    </div>
    <sei-car-drawdown-chart-drawer [class.show]="isDrawdownChartVisible"
                                   [ngStyle]="{'border-color': scenarioCardBorderColor, 'overflow': isDrawdownChartVisible ? 'visible' : 'hidden'}"
                                   [scenarioCardBorderColor]="scenarioCardBorderColor"
                                   [drawdownData]="dfsDrawdownData"
                                   [strategyPools]="strategyPools"
                                   [strategyName]="strategyName"
                                   [doesAccountAlreadyHaveADfsStrategy]="doesAccountAlreadyHaveADfsStrategy"
                                   [canStrategyBeAddedToAccount]="scenarioCardFormAndChartData?.dfsData?.canStrategyBeAddedToAccount || isScenarioRedrawn"
                                   [calculationError]="calculationError"
                                   [isFormDirty]="dfsIllustratorForm.dirty"
                                   (addToProposalClicked)="addSeletedModelToAccount()"
                                   (clearValuesClicked)="resetDfsIllustratorForm()">
    </sei-car-drawdown-chart-drawer>
</div>
