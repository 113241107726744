<div class="dfs-modal">
    <div class="dfs-modal-container">
        <div class="dfs-modal-title">
            <span class="title-text">{{ dfsIllustratorModalTitle }}</span>
            <span class="close-modal-icon" (click)="closeModal()"><i class="fa-sharp fa-solid fa-xmark"></i></span>
        </div>

        <sei-car-dfs-loading-spinner *ngIf="isDfsIllustrationDataLoading"
                                     [useSeiDefaultSpinner]="useSeiDefaultSpinner"
                                     [spinnerSize]="spinnerSizes.XLARGE"
                                     class="dfs-modal-loading"></sei-car-dfs-loading-spinner>
        <div *ngIf="!isDfsIllustrationDataLoading && !isCarouselModeActive"
             class="dfs-modal-body">
            <div *ngIf="areErrorsPresent"
                 class="error-container">
                <sei-car-dfs-alert-notification [alertOptions]="alertOptions"
                                                (refreshClicked)="onRefreshClick()"></sei-car-dfs-alert-notification>
            </div>
            <div class="content-container"
                 *ngIf="showScenarios()">
                <div class="scenario-cards-container"
                     [ngStyle]="{'display': areMultipleScenariosPresent ? 'flex' : 'block', 'width': dfsIllustratorScenarios.length === 3 ? '100%' : '90%'}">
                    <sei-car-scenario-card *ngFor="let scenarioCardFormAndChartData of dfsIllustratorScenarios; let i = index"
                                           [dfsIllustratorIndex]="i"
                                           [scenarioCardFormAndChartData]="scenarioCardFormAndChartData"
                                           [isYShareAllowedForDfsIllustrator]="isYShareAllowedForDfsIllustrator"
                                           [areMultipleScenariosPresent]="areMultipleScenariosPresent"
                                           [backupFormGroupForFormReset]="backupFormGroupForFormReset"
                                           [distributionAnalyticsResponse]="scenarioCardFormAndChartData?.analyticsData"
                                           [doesAccountAlreadyHaveADfsStrategy]="doesAccountAlreadyHaveADfsStrategy"
                                           [disableCalculateButton]="areErrorsPresent && scenarioCardFormAndChartData?.dfsData?.isFormReadOnly"
                                           (dfsIllustratorDeleted)="dfsIllustratorDeleted($event)">
                    </sei-car-scenario-card>
                </div>
                <div *ngIf="showAddScenarioCard"
                     id="add-scenario-button"
                     class="add-scenario-card"
                     (click)="addScenarioCardWithDefaultValues()"
                     [ngStyle]="{'border-color': addScenarioCardBorderColor}">
                    <span class="add-scenario-icon" [ngStyle]="{'color': addScenarioCardBorderColor}"><i class="fa-solid fa-square-plus"></i></span>
                </div>
            </div>
        </div>

        <div *ngIf="!isDfsIllustrationDataLoading && isCarouselModeActive"
             class="dfs-modal-body">
            <sei-car-dfs-alert-notification *ngIf="areErrorsPresent"
                                            [alertOptions]="alertOptions"
                                            (refreshClicked)="onRefreshClick()"></sei-car-dfs-alert-notification>
            <sei-car-dfs-carousel *ngIf="showScenarios()"
                                  [isYShareAllowedForDfsIllustrator]="isYShareAllowedForDfsIllustrator"
                                  [scenarioCards]="dfsIllustratorScenarios"
                                  [distributionAnalyticsResponse]="distributionAnalyticsResponse"
                                  [doesAccountAlreadyHaveADfsStrategy]="doesAccountAlreadyHaveADfsStrategy"
                                  [addScenarioCardBorderColor]="addScenarioCardBorderColor"
                                  [backupFormGroupForFormReset]="backupFormGroupForFormReset"
                                  [startingIndex]="carouselStartingIndex"
                                  (newScenarioCardClicked)="addScenarioCardWithDefaultValues()"
                                  (dfsIllustratorDeleted)="dfsIllustratorDeleted($event)"></sei-car-dfs-carousel>
        </div>

        <div class="dfs-modal-footer">
            <button class="cancel-button"
                    (click)="closeModal()">Cancel</button>
        </div>
    </div>
</div>
