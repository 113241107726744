/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { SpinnerService } from '@CarServices/system/spinner.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserProfileService } from '@sei/advisor-client-review-proposal-ux';
import { ParentComponentSubscriptionManager } from '@sei/common-swp-components-lib-ux';
import { Subscription, tap } from 'rxjs';
import { WindowRef } from '../helper/window';
import { FeeAccount, FeeGroup, HudSummary, ManagementFeeBreakdown, ScenarioFeeSchedule } from '../model/account-fee';
import { CommandText, FeeAnalysisName } from '../model/enums';
import { AdvisorFeeFactory } from '../model/factory/advisor-fee-factory';
import { FeeAccountFactory } from '../model/factory/fee-account-factory';
import { Scenario } from '../model/proposal';
import { DelegationService } from '../service/delegation.service';
import { FeeScheduleGroupService } from '../service/fee-schedule-group.service';
import { GenericErrorService } from '../service/system/generic-error.service';


@Component({
    selector: 'sei-car-fees',
    templateUrl: './fees.component.html'
})
export class FeesComponent extends ParentComponentSubscriptionManager implements OnInit, OnDestroy {

    @Input()
    public scenario: Scenario;

    private _window: Window;
    public feeGroups: FeeGroup[] = [];
    public hiddenDropContainer: boolean = false;
    public hideExportFeeAnalysis: boolean = true;
    public newAccountFeeId: number = 0;
    public previousFeeGroupId: number = 0;
    public hudSummary: HudSummary;
    public scenarioFeeSchedules: ScenarioFeeSchedule[] = [];
    public managementFeeBreakdown: ManagementFeeBreakdown[];

    public saveFeeAnalysisService: Subscription;

    constructor(private delegationService: DelegationService,
        private feeScheduleGroupService: FeeScheduleGroupService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private readonly spinnerService: SpinnerService,
        private genericErrorService: GenericErrorService,
        private windowRef: WindowRef,
        private userProfileService: UserProfileService) {
        super('FeesComponent');
        this._window = this.windowRef.nativeWindow;
    }

    public proposalId: number;
    public isReadUser: boolean = false;

    public ngOnInit(): void {
        this.isReadUser = this.userProfileService.isReadUser;
        if (this.activatedRoute.parent) {
            this.proposalId = Number(this.activatedRoute.parent.snapshot.paramMap.get('proposalId'));
        }

        this.saveFeeAnalysisService = this.delegationService.refresh().subscribe((data: string) => {
            if (data === CommandText.SaveFeeGroup) {
                this.saveFeeAnalysis();
            }
        });

        this.subscriptions.push(this.saveFeeAnalysisService);
        this.spinnerService.start();
        this.subscriptions.push(this.feeScheduleGroupService.getFeeAnalysis(this.proposalId, this.scenario.id).subscribe(
            (response) => {
                if (response) {
                    this.hudSummary = response.hudSummary;
                    this.feeGroups = response.feeAnalysis.feeGroups;
                    this.managementFeeBreakdown = response?.feeAnalysis?.managementFeeBreakdown;
                    this.setDefaultFeeGroup();
                    this.verifyHiddenDropContainer();
                }
            },
            ((error) => {
                this.genericErrorService.setGenericError({ code: '500', description: error });
                this.spinnerService.stop();
            }),
            () => {
                this.spinnerService.stop();
            }
        ));

        this.getFeeSchedules();
    }

    public ngOnDestroy(): void {
        this.saveFeeAnalysisService.unsubscribe();
        this.delegationService.clearPublishedData();
    }

    private saveFeeAnalysis(): void {
        this.feeGroups.forEach((feeGroupItem: FeeGroup) => {
            if (feeGroupItem.advisorFee.isNew) {
                feeGroupItem.id = null;
            }
        });

        const feeAnalysis = {
            scenarioId: this.scenario.id,
            scenarioName: this.scenario.name,
            feeGroups: this.feeGroups
        };

        this.spinnerService.start();
        this.feeScheduleGroupService.saveFeeAnalysis(feeAnalysis, this.proposalId, this.scenario.id)
            .subscribe({
                next: (): void => {
                    this.delegationService.publish(CommandText.UpdateWipCheckList);
                    const navigateUrl = `/Proposal/WIP/${this.proposalId}`;
                    this.router.navigateByUrl(navigateUrl);
                },
                error: (error): void => {
                    this.genericErrorService.setGenericError({ code: '500', description: error });
                    this.spinnerService.stop();
                },
                complete: (): void => {
                    this.spinnerService.stop();
                }
            });
    }

    private setDefaultFeeGroup(): void {
        if (this.feeGroups.length === 0 && this.scenario.accounts) {

            const currentAccounts: FeeAccount[] = [];
            const nextFeeGroupId: number = this.feeGroups.length ?
                Math.max(...this.feeGroups.map((feeGroup) => feeGroup.id)) + 1 : 1;

            this.scenario.accounts.forEach((account) => {
                const feeAccount = new FeeAccountFactory().createObject(account.id);
                feeAccount.previousFeeGroupId = nextFeeGroupId;
                feeAccount.name = account.name;
                currentAccounts.push(feeAccount);
            });

            const nextNameId = this.feeGroups.length + 1;

            const newAccountFee = new AdvisorFeeFactory().createObject(true);
            this.feeGroups.push({
                id: nextFeeGroupId,
                name: FeeAnalysisName.AdvisorFee + ' ' + nextNameId,
                advisorFee: newAccountFee,
                accounts: currentAccounts
            });
        } else {
            this.feeGroups.forEach((feeGroup, index) => {
                if (feeGroup.name === '') {
                    feeGroup.name = FeeAnalysisName.AdvisorFee;
                    if (this.feeGroups.filter((item) => item.name === FeeAnalysisName.AdvisorFee).length > 1) {
                        feeGroup.name += ' ' + ((index === 0) ? (index + 2) : (index + 1));
                    }
                }
                feeGroup.accounts.forEach((feeAccount) => {
                    feeAccount.previousFeeGroupId = feeGroup.id;
                });
            });
        }
    }

    public onCancelNewAccountFee(event: FeeAccount): void {
        this.feeGroups.forEach((item, index) => {
            const foundAccount = item.accounts.find((account) => account.id === event.id);
            if (foundAccount) {
                item.accounts = item.accounts.filter((account) => account.id !== event.id);
                if (item.accounts.length === 0) {
                    this.feeGroups.splice(index, 1);
                }
                return;
            }
        });

        const foundFeeGroup = this.feeGroups.find((feeGroup) => feeGroup.id === event.previousFeeGroupId);
        if (foundFeeGroup) {
            foundFeeGroup.accounts.push(event);
        }

        this.onAccountEnter();
    }

    public onAccountDropped(event: FeeAccount): void {

        const nextFeeGroupdId: number = this.feeGroups.length ?
            Math.max(...this.feeGroups.map((feeGroup) => feeGroup.id)) + 1 : 1;
        const newAccountFee = new AdvisorFeeFactory().createObject(true);
        this.newAccountFeeId = nextFeeGroupdId;

        const nextNameId = this.feeGroups.length + 1;

        const transferAccounts: FeeAccount[] = [];
        transferAccounts.push(event);

        this.feeGroups.push({
            id: nextFeeGroupdId,
            name: FeeAnalysisName.AdvisorFee + ' ' + nextNameId,
            advisorFee: newAccountFee,
            accounts: transferAccounts
        });

        this.verifyHiddenDropContainer();
        // this.delegationService.publish(CommandText.RefreshFees);
    }

    public onAccountEnter(): void {
        this.feeGroups.forEach((accountFee) => {
            accountFee.accounts.forEach((account) => {
                account.previousFeeGroupId = accountFee.id;
            });
        });

        const foundFeeGroupWithNoAccounts = this.feeGroups.find((item) => item.accounts.length === 0);
        if (foundFeeGroupWithNoAccounts) {
            const feeGroupId = foundFeeGroupWithNoAccounts.id;
            this.feeGroups = this.feeGroups.filter((item) => item.id !== feeGroupId);
        }

        this.verifyHiddenDropContainer();
    }

    public onChangeAsDefault(event): void {
        this.feeGroups.forEach((feeGroup) => {
            feeGroup.advisorFee.isDefault = (feeGroup.id === event);
        });
    }

    public verifyHiddenDropContainer(): void {
        this.hiddenDropContainer = true;
        if (this.feeGroups.find((item) => item.accounts.length > 1)) {
            this.hiddenDropContainer = false;
        }
    }

    public onHudSummaryUpdate(event: HudSummary): void {
        const feeAnalysis = {
            scenarioId: this.scenario.id,
            scenarioName: this.scenario.name,
            feeGroups: this.feeGroups
        };
        this.spinnerService.start();
        this.feeScheduleGroupService.calculateAdvisorFees(feeAnalysis, this.proposalId, this.scenario.id).subscribe(
            (hudSummaryResponse: HudSummary) => {
                this.hudSummary = hudSummaryResponse;
            },
            (error) => {
                this.genericErrorService.setGenericError({ code: '500', description: error });
                this.spinnerService.stop();
            },
            () => {
                this.spinnerService.stop();
            }
        );

    }

    private getFeeSchedules(): void {
        this.feeScheduleGroupService.getFeeSchedulesList(this.proposalId, this.scenario.id)
            .pipe(tap(() => this.spinnerService.start()))
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.scenarioFeeSchedules = response;
                    }
                },
                error: (error) => {
                    this.genericErrorService.setGenericError(error);
                },
                complete: () => {
                    this.spinnerService.stop();
                }
            }
            );
    }

}
