/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { EndPoint } from '@sei/advisor-client-review-proposal-ux';


export const endPointBe2Routes: { [key: string]: EndPoint } = {
    accountTypes: {
        url: '/car/accountTypes/{advisorId}',
        groupName: 'imsaccounttypesservice',
        apiVersion: 'v4'
    },
    investmentPrograms: {
        url:
            '/car/investmentPrograms/firms/{firmId}/accountTypes/{accountTypeId}',
        groupName: 'imsinvestmentprogramsservice',
        apiVersion: 'v4'
    },
    accountCreate: {
        url: '/car/accounts/mrdc',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountSave: {
        url: '/car/accounts/{accountNumber}/mrdc',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountFetch: {
        url: '/car/accounts/{accountNumber}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountFetchFull: {
        url: '/car/accounts/{accountNumber}/full',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountDelete: {
        url: '/car/accounts/{accountNumber}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountOwnerDetailCreate: {
        url: '/car/accountOwners',
        groupName: 'imsaccountownersservice',
        apiVersion: 'v4'
    },
    accountOwnerDetailFetch: {
        url: '/car/accounts/{accountNumber}/accountOwners',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountOwnerDetailUpdate: {
        url: '/car/accounts/{accountNumber}/accountOwners/{accountOwnersId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountOwnerDetailDelete: {
        url: '/car/accounts/{accountNumber}/accountOwners/{accountOwnersId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    fundingSourcesFetch: {
        url: '/car/accounts/{accountNumber}/fundingSources',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    fundingSourcesUpdate: {
        url:
            '/car/accounts/{accountNumber}/fundingSources/{accountFundingSourceId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    fundingSourcesDelete: {
        url:
            '/car/accounts/{accountNumber}/fundingSources/{accountFundingSourceId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    fundingDirectionsFetch: {
        url: '/car/accounts/{accountNumber}/fundingDirections',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountDetailsFetch: {
        url: '/car/accounts/{accountNumber}/accountDetails',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountDetailsUpdate: {
        url: '/car/accounts/{accountNumber}/accountDetails/{accountDetailsId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountGoalsFetch: {
        url: '/car/accounts/{accountNumber}/goals',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountPendingFetch: {
        url:
            '/car/accounts/pending?advisorId={advisorId}&firmId={firmId}&numberOfDays={numberOfDays}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountGoalsUpdate: {
        url: '/car/accounts/{accountNumber}/goals',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountInvestmentProgramsAvailablesFetch: {
        url: '/car/accounts/{accountNumber}/availableStrategies',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    feeScheduleFetch: {
        url: '/car/feeSchedule/{firmId}',
        groupName: 'imsfeescheduleservice',
        apiVersion: 'v4'
    },
    feeScheduleTiersFetch: {
        url: '/car/feeSchedule/{scheduleId}/details',
        groupName: 'imsfeescheduleservice',
        apiVersion: 'v4'
    },
    riskToleranceFetch: {
        url: '/car/riskTolerance/{accountNumber}',
        groupName: 'imsrisktoleranceservice',
        apiVersion: 'v4'
    },
    overrideSuitabilityStatus: {
        url: '/car/riskTolerance/overrideStatus/{accountNumber}',
        groupName: 'imsrisktoleranceservice',
        apiVersion: 'v4'
    },
    sourceOfFunds: {
        url: '/car/referenceData/sourceOfFunds',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    countries: {
        url: '/car/referenceData/countries',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    suffixes: {
        url: '/car/referenceData/suffixes',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    achFrequency: {
        url: '/car/referenceData/{accountType}/achFrequency',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    startDateOption: {
        url: '/car/referenceData/achFrequency/startDateOptions',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    beneficiaryDesignations: {
        url: '/car/referenceData/{accountType}/beneficiaryDesignations',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    beneficiaryRelationshipTypes: {
        url: '/car/referenceData/beneficiaryRelationshipTypes',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    beneficiaryEntityTypes: {
        url: '/car/referenceData/beneficiaryEntityTypes',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    beneficiaryTypes: {
        url: '/car/referenceData/beneficiaryTypes',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    beneficiaryCategories: {
        url: '/car/referenceData/beneficiaryCategories',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    dcaContributionFrequencyOptions: {
        url: '/car/referenceData/dcaContributionFrequencyOptions',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    dcaScheduleOptions: {
        url: '/car/referenceData/dcaScheduleOptions',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    dcaStartDateOptions: {
        url: '/car/referenceData/dcaStartDateOptions',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    dcaResidualOptions: {
        url: '/car/referenceData/dcaResidualOptions',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    externalAccountTypes: {
        url: '/car/referenceData/externalAccountType',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    firms: {
        url: '/car/referenceData/firms',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    fundingSourceTransferType: {
        url: '/car/referenceData/fundingSourceTransferType',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    signatureFetch: {
        url: '/car/accountGroup/{accountNumber}/signature',
        groupName: 'imssignatureservice',
        apiVersion: 'v4'
    },
    signatureSave: {
        url: '/car/accountGroup/{accountNumber}/signature',
        groupName: 'imssignatureservice',
        apiVersion: 'v4'
    },
    statements: {
        url: '/car/referenceData/statements',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    dividendDistributionOption: {
        url: '/car/referenceData/dividendDistributionOption',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    accountContributionTypeOption: {
        url: '/car/referenceData/{accountType}/accountContributionType',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    paymentOfAdvisorFeesOption: {
        url: '/car/referenceData/paymentOfAdvisorFeesOption',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    payToRecipientDistributionOption: {
        url: '/car/referenceData/payToRecipientDistributionOption',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    goalTypes: {
        url: '/car/referenceData/goalTypes',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    payTo: {
        url: '/car/referenceData/payTo',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    prospectuses: {
        url: '/car/referenceData/prospectuses',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    proxyStatements: {
        url: '/car/referenceData/proxyStatements',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    states: {
        url: '/car/referenceData/states',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    distributionAccountType: {
        url: '/car/referenceData/distributionAccountType',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    fundingSourcesList: {
        url: '/car/referenceData/fundingSources',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    transferDirectionPrevailingAssumption: {
        url:
            '/car/referenceData/tdPrevailingAssumption/{investmentProgramId}/{fundingSourceTransferTypeId}',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    beneficiariesFetch: {
        url: '/car/accounts/{accountNumber}/beneficiaries',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    beneficiariesUpdate: {
        url:
            '/car/accounts/{accountNumber}/beneficiaries/{accountBeneficiaryId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    beneficiariesDelete: {
        url:
            '/car/accounts/{accountNumber}/beneficiaries/{accountBeneficiaryId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    powerOfAttorney: {
        url: '/car/accounts/{accountNumber}/powerOfAttorney',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    relatedParties: {
        url: '/car/accounts/{accountNumber}/accountRelatedParties',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    relatedPartiesUpdate: {
        url:
            '/car/accounts/{accountNumber}/accountRelatedParties/{accountRelatedPartyId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    relatedPartyRules: {
        url: '/car/relatedPartiesRules/{accountType}',
        groupName: 'imsrelatedpartiesrulesservice',
        apiVersion: 'v4'
    },
    assetsSearch: {
        url: '/car/assetSearch?tickerSymbol={tickerSymbol}',
        groupName: 'imsadvisorsservice',
        apiVersion: 'v4'
    },
    contactFileUpload: {
        url: '/car/contacts/{contactId}/uploadFiles',
        groupName: 'imscontactsservice',
        apiVersion: 'v4'
    },
    contactFileUploadDelete: {
        url: '/car/contacts/{contactId}/uploadFiles/{documentId}',
        groupName: 'imscontactsservice',
        apiVersion: 'v4'
    },
    accountFileUpload: {
        url: '/car/accounts/{accountNumber}/uploadFiles',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountFileUploadDelete: {
        url: '/car/accounts/{accountNumber}/uploadFiles/{documentId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountFileSubmit: {
        url: '/car/accounts/{accountNumber}/submitFiles',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    userFileUpload: {
        url: '/car/users/{smUniversalId}/uploadFiles',
        groupName: 'imsusersservice',
        apiVersion: 'v4'
    },
    userFileSubmit: {
        url: '/car/users/{smUniversalId}/submitFiles',
        groupName: 'imsusersservice',
        apiVersion: 'v4'
    },
    outsidePurchases: {
        url: '/car/accounts/{accountNumber}/outsidePurchases',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    investmentProgramsAvailableStrategies: {
        url: '/car/accounts/{accountNumber}/availableStrategies',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    investmentProgramsSelectedStrategies: {
        url: '/car/accounts/{accountNumber}/selectedStrategies',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    investmentProgramsAvailableStrategyAttributes: {
        url: '/car/accounts/{accountNumber}/availableStrategyAttributes',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    userProfile: {
        url: '/car/users/{userEntityId}',
        groupName: 'imsusersservice',
        apiVersion: 'v4'
    },
    wipSnapshot: {
        url: '/car/accounts/{accountNumber}/wipSnapshot',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    elasticSearch: {
        url: '/car/elasticSearch/{entityId}/relatedParties',
        groupName: 'imselasticsearchservice',
        apiVersion: 'v4'
    },
    strategyAssetAllocations: {
        url:
            '/car/accountStrategyDetails/{accountNumber}/strategyDetail/{strategyId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    contacts: {
        url: '/car/contacts/{contactId}',
        groupName: 'imscontactsservice',
        apiVersion: 'v4'
    },
    accountStatusFetch: {
        url: '/car/accounts/{accountNumber}/status',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountStatusReminderFlip: {
        url: '/car/accounts/{accountNumber}/status/{reminderId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    accountDocumentFetch: {
        url: '/car/accounts/downloadFile/{fileId}',
        groupName: 'imsaccountsservice',
        apiVersion: 'v4'
    },
    contactDocumentFetch: {
        url: '/car/contact/downloadFile/{fileId}',
        groupName: 'imscontactsservice',
        apiVersion: 'v4'
    },
    subFormsByAccountType: {
        url: '/car/referenceData/{accountTypeId}/additionalSubforms',
        groupName: 'imsreferencedataservice',
        apiVersion: 'v4'
    },
    documentCreate: {
        url: '/car/forms/{accountNumber}',
        groupName: 'imsformsservice',
        apiVersion: 'v4'
    },
    groupDocumentCreate: {
        url: '/car/forms',
        groupName: 'imsformsservice',
        apiVersion: 'v4'
    },
    documentFetch: {
        url: '/car/forms/documents/{accountNumber}',
        groupName: 'imsformsservice',
        apiVersion: 'v4'
    },
    documentGetStatus: {
        url: '/car/forms/status/{accountNumber}',
        groupName: 'imsformsservice',
        apiVersion: 'v4'
    },
    advisors: {
        url: '/car/advisors/firms/{firmId}?smUniversalId={smUniversalId}',
        groupName: 'imsadvisorsservice',
        apiVersion: 'v4'
    },
    accountOwnerCreate: {
        url: '/car/accountOwners/prospects',
        groupName: 'imsaccountownerservice',
        apiVersion: 'v4'
    },
    proposals: {
        url: '/car/proposals',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    proposal: {
        url: '/car/proposals/{proposalId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    availableStrategies: {
        url: '/car/proposalStrategies/{firmId}/availableStrategies',
        groupName: 'imsproposalstrategyservice',
        apiVersion: 'v4'
    },
    investmentProgramsByFirmId: {
        url: '/car/investmentPrograms/firms/{firmId}',
        groupName: 'imsinvestmentprogramsservice',
        apiVersion: 'v4'
    },
    getProposalAccountStrategy: {
        url: '/car/investmentPrograms/proposalStrategy/{strategyId}',
        groupName: 'imsproposalaccountstrategyservice',
        apiVersion: 'v4'
    },
    deleteProposalAccountStrategy: {
        url: '/car/accounts/{proposalAccountId}/proposalStrategy/{strategyId}',
        groupName: 'imsproposalaccountstrategyservice',
        apiVersion: 'v4'
    },
    addProposalAccountStrategy: {
        url: '/car/accounts/{proposalAccountId}/proposalStrategy/{strategyId}',
        groupName: 'imsproposalaccountstrategyservice',
        apiVersion: 'v4'
    },
    getQuestionnaire: {
        url: '/car/accounts/{proposalAccountId}/riskToleranceQuestionnaire',
        groupName: 'imsproposalaccountservice',
        apiVersion: 'v4'
    },
    retrieveActiveProposalDashboard: {
        url: '/car/proposalDashboard/v2/active',
        groupName: 'imsproposaldashboardservice',
        apiVersion: 'v4'
    },
    retrieveArchiveProposalDashboard: {
        url: '/car/proposalDashboard/v2/archived',
        groupName: 'imsproposaldashboardservice',
        apiVersion: 'v4'
    },
    updateStatusArchivedProposalDashboard: {
        url: '/car/proposalDashboard/{proposalId}/archived',
        groupName: 'imsproposaldashboardservice',
        apiVersion: 'v4'
    },
    reactivateProposalDashboard: {
        url: '/car/proposalDashboard/{proposalId}/reactive',
        groupName: 'imsproposaldashboardservice',
        apiVersion: 'v4'
    },
    implementProposalDashboard: {
        url: '/car/proposalDashboard/{proposalId}/implemented',
        groupName: 'imsproposaldashboardservice',
        apiVersion: 'v4'
    },
    getProposalDashboardTypeAhead: {
        url: '/car/proposalDashboard/searchText/{searchText}',
        groupName: 'imsproposaldashboardservice',
        apiVersion: 'v4'
    },
    addFeeSchedule: {
        url: '/car/proposals/{proposalId}/FeeGroup',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    getCompletedRiskAccounts: {
        url: '/car/accounts/{proposalAccountId}/completedRiskAccounts',
        groupName: 'imsproposalaccountservice',
        apiVersion: 'v4'
    },
    getCombinedStrategyRisk: {
        url: '/car/accounts/{proposalAccountId}/combinedStrategyRisk',
        groupName: 'imsproposalaccountservice',
        apiVersion: 'v4'
    },
    getRiskToleranceChoices: {
        url: '/car/accounts/{proposalAccountId}/riskToleranceChoices',
        groupName: 'imsproposalaccountservice',
        apiVersion: 'v4'
    },
    getCoverPageImages: {
        url: '/car/coverPageImages/themes/{proposalId}',
        groupName: 'imscoverpageimagesservice',
        apiVersion: 'v4'
    },
    wipChecklist: {
        url: '/car/proposals/wipChecklist',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    retrieveFeeAnalysis: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}/feeAnalysis',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    retrieveProposalFees: {
        url: '/car/proposals/{proposalId}/fees',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    updateProposalAccount: {
        url: '/car/accounts/{proposalAccountId}',
        groupName: 'imsproposalaccountservice',
        apiVersion: 'v4'
    },
    upsertPresentationSetting: {
        url: '/car/presentationSetting',
        groupName: 'imspresentationsettingservice',
        apiVersion: 'v4'
    },
    retrievePresentationSetting: {
        url: '/car/presentationSetting/{proposalId}/{advisorId}',
        groupName: 'imspresentationsettingservice',
        apiVersion: 'v4'
    },
    retrieveAccountFees: {
        url: '/car/accounts/proposalAccount/fees',
        groupName: 'imsproposalaccountservice',
        apiVersion: 'v4'
    },
    presentationChecklist: {
        url: '/car/presentationChecklist/{proposalId}',
        groupName: 'imspresentationchecklistservice',
        apiVersion: 'v4'
    },
    primaryAdvisorPreference: {
        url: '/car/presentationChecklist/{proposalId}/primaryAdvisorPreference',
        groupName: 'imspresentationchecklistservice',
        apiVersion: 'v4'
    },
    generateDocumentsPresentationChecklist: {
        url: '/car/proposalDocuments/create/presentation/{proposalId}/{firmGroupId}',
        groupName: 'imsproposaldocumentsservice',
        apiVersion: 'v4'
    },
    downloadProposalPresentation: {
        url: '/car/proposalDocuments/file/download/{proposalId}',
        groupName: 'imsproposaldocumentsservice',
        apiVersion: 'v4'
    },
    downloadPresentation: {
        url: '/car/proposalDocuments/presentation/download/{proposalId}/{fileName}',
        groupName: 'imsproposaldocumentsservice',
        apiVersion: 'v4'
    },
    previewPresentationPdf: {
        url: '/car/proposalDocuments/presentation/preview/{proposalId}/{fileName}',
        groupName: 'imsproposaldocumentsservice',
        apiVersion: 'v4'
    },
    getProposalStatus: {
        url: '/car/proposals/{proposalId}/getProposalStatus',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    getProposalFirmLogo: {
        url: '/car/proposals/{proposalId}/firmlogo',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    getFirmLogoDisplayName: {
        url: '/car/proposals/{proposalId}/getFirmLogoDisplayName',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    postProposalFirmLogo: {
        url: '/car/proposals/{proposalId}/firmlogo',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    postFeeAnalysis: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}/feeAnalysis',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    postFeeGroup: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}/feeGroup',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    getFeeSchedulesDetail: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}/feeSchedule/{scheduleId}',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    getFeeSchedules: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}/feeSchedules',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    updateGoalDetails: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}/goal',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    updateGoalDetailsMonteCarlo: {
        url: '/car/proposals/v2/{proposalId}/scenario/{scenarioId}/goal',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    updateProposalName: {
        url: '/car/proposals/{proposalId}/updateName',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    updateProposal: {
        url: '/car/proposals/{proposalId}/',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    updateProposalScenario: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    getErrorInfo: {
        url: '/car/proposals/getProposalErrorInfo/{error}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    retrievePreviewSectionImages: {
        url: '/car/proposalDocuments/preview/{proposalId}/{statementTypeId}',
        groupName: 'imsproposaldocumentsservice',
        apiVersion: 'v4'
    },
    retrievePreviewSectionImagesByProposalStatement: {
        url: '/car/proposalDocuments/preview/{proposalId}',
        groupName: 'imsproposaldocumentsservice',
        apiVersion: 'v4'
    },
    calculateAdvisorFee: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}/calculateAdvisorFee',
        groupName: 'imsproposalscenarioservice',
        apiVersion: 'v4'
    },
    firmInfo: {
        url: '/car/proposals/firmInfo/{firmId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    postCoverPageImages: {
        // eslint-disable-next-line max-len
        url: '/car/proposals/coverPageImages/{proposalId}?presentationType={presentationType}&sectionName={sectionName}&subSectionName={subSectionName}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    firmIdAndUserId: {
        url: '/car/users/{userEntityId}/firm/{swpFirmId}',
        groupName: 'imsusersservice',
        apiVersion: 'v4'
    },
    advisorCustomContent: {
        // eslint-disable-next-line max-len
        url: '/car/proposals/advisorCustomContent/{proposalId}?presentationType={presentationType}&sectionName={sectionName}&subSectionName={subSectionName}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    saveAccountDetails: {
        url: '/car/proposals/currentaccount',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    currentAccount: {
        url: '/car/proposals/currentaccount/{currentAccountId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    deleteAdvisorSectionContent: {
        // eslint-disable-next-line max-len
        url: '/car/proposals/deleteCustomContent/{proposalId}?presentationType={presentationType}&sectionName={sectionName}&subSectionName={subSectionName}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    asynchronousAddUpdate: {
        // eslint-disable-next-line max-len
        url: '/car/proposals/asynchronousAddOrUpdate/{proposalId}?documentId={documentId}&subSectionName={subSectionName}&operationType={operationType}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    asyncUpdateSortOrder: {
        url: '/car/proposals/updateAdvisorContentSortOrder/{proposalId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    fetchTaxTransitionAnalysis: {
        url: '/car/proposals/taxtransition/details/{proposalId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    saveExitTaxTransitionAnalysis: {
        url: '/car/proposals/taxanalysis/saveExit',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    submitTaxTransitionAnalysis: {
        url: '/car/proposals/taxanalysis/submit',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    taxtransitionAnalysis: {
        url: '/car/proposalDocuments/file/download/{proposalId}',
        groupName: 'imsproposaldocumentsservice',
        apiVersion: 'v4'
    },
    retrieveFile: {
        url: '/car/proposalDocuments/file/download',
        groupName: 'imsproposaldocumentsservice',
        apiVersion: 'v4'
    },
    retrieveAnalyticsBenchmarks: {
        url: '/car/presentationChecklist/analyticsBenchmarks',
        groupName: 'imspresentationchecklistservice',
        apiVersion: 'v4'
    },
    saveAdvisorDisplayPreferences: {
        url: '/car/presentationSetting/displayPreferences',
        groupName: 'imspresentationsettingservice',
        apiVersion: 'v4'
    },
    rtqByAccountGroup: {
        url: '/car/riskTolerance/accountGroup/{accountGroupId}',
        groupName: 'imsrisktoleranceservice',
        apiVersion: 'v4'
    },
    retrieveExistingAccounts: {
        url: '/car/proposals/accounts/swp/client',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    retrieveNonModelPortfolioTypes: {
        url: '/car/accountTypes/nonModelPortfolioTypes',
        groupName: 'imsaccounttypesservice',
        apiVersion: 'v4'
    },
    retrieveEnrichedAccountData: {
        url: '/car/proposals/calculateInvestmentCost/{proposalId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    createPendingAccounts: {
        url: '/car/proposals/accountOpen/initiate',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    retrieveMinDataProposal: {
        url: '/car/proposals/accountOpen/retrieve/{proposalId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    retrievePendingAccountStatus: {
        url: '/car/proposals/accountOpen/retrieve/status/{proposalId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    reactivateProposal: {
        url: '/car/proposals/accountOpen/reactivate/{proposalId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    monteCarloAnalysis: {
        url: '/car/proposals/goal/achievabilityAnalysis',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    getProposalScenarioGoals: {
        url: '/car/proposals/{proposalId}/scenario/{scenarioId}/goals',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    getDfsAnalytics: {
        url: '/car/proposals/distributionAnalytics',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    },
    getDfsStrategyData: {
        url: '/car/proposals/models/{firmId}/{modelId}',
        groupName: 'imsproposalservice',
        apiVersion: 'v4'
    }
};
