/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

export enum Consumers {
    PROPOSAL = 'Proposal',
    PORTFOLIO_EVALUATOR = 'Portfolio Evaluator',
    MMT = 'Model Management & Trading'
}

export enum ScenarioCardColors {
    SCENARIO_1_BLUE = '#254a5d',
    SCENARIO_2_PINK = '#e26fab',
    SCENARIO_3_PURPLE = '#604791'
}

export enum AdjustForInflationOptions {
    STANDARD_PERCENTAGE = 'Yes - Standard Percentage',
    SPECIFY_PERCENTAGE = 'Yes - Specify Percentage',
    NO = 'No'
}

export enum ImplementationTypes {
    MUTUAL_FUNDS = 'Mutual Funds',
    MF = 'MF',
    ETF = 'ETF',
    ETFS = 'ETFs'
}

export enum GoalOrObjective {
    STABILITY = 'Stability',
    GROWTH_AND_STABILITY = 'Growth & Stability',
    GROWTH_TILT = 'Growth Tilt'
}

export enum Frequency {
    ANNUALLY = 'Annually',
    SEMI_ANNUALLY = 'Semi-Annually',
    QUARTERLY = 'Quarterly',
    MONTHLY = 'Monthly'
}

export enum DfsInflationSelection {
    STANDARD_PERCENTAGE = 1,
    DO_NOT_INCLUDE = 2,
    CUSTOM_PERCENTAGE = 3,
    DEFAULT_SELECT = undefined
}

export enum InflationPlaceholderValues {
    ZERO_PERCENT = '0%',
    PERCENT_ONLY = '%',
    EMPTY_VALUE = ''
}

export enum DfsFormErrorTypes {
    REQUIRED = 'required',
    MIN = 'min',
    MIN_LENGTH = 'minlength',
    INVALID_AMOUNT = 'invalidAmount'
}

export enum DfsFormControls {
    IMPLEMENTATION_METHOD = 'implementationMethod',
    GOAL_OR_OBJECTIVE = 'goalOrObjective',
    INITIAL_INVESTMENT = 'initialInvestment',
    INCOME_NEEDED = 'incomeNeeded',
    FREQUENCY = 'frequency',
    ADJUST_FOR_INFLATION = 'adjustForInflation',
    TAX_SENSITIVITY = 'taxSensitivity',
    PERCENT_VALUE = 'percentValue',
    ADVISOR_FEE = 'advisorFee'
}

export enum DrawdownSeries {
    AVG = 'avg',
    MIN = 'min',
    DIST_CONFIDENCE = '%'
}

export enum SpinnerSizes {
    SMALL = '16px',
    MEDIUM = '24px',
    LARGE = '30px',
    XLARGE = '48px'
}

export enum ButtonText {
    CALCULATE_VALUES = 'Calculate Values',
    CALCULATED_VALUES = 'Calculated Values'
}

export enum StrategyBreakdownPoolType {
    AGGRESSIVE = 'Aggressive',
    MODERATE = 'Moderate',
    DEFENSIVE = 'Defensive'
}

export enum StrategyBreakdownPoolColor {
    AGGRESSIVE = '#AAD037',
    MODERATE = '#9B78E2',
    DEFENSIVE = '#46A5D6'
}

export enum AlertStatus {
    WARNING = 'Warning',
    NOTIFICATION = 'Notification',
    ERROR = 'Error',
    SUCCESS = 'Success'
}

export enum AlertAccentColors {
    ERROR_BACKGROUND_RED = '#f2dede',
    ERROR_BORDER_RED = '#ebccd1',
    ERROR_ICON_RED = '#a94442',
    NOTIFICATION_BACKGROUND_BLUE = '#d9edf7',
    NOTIFICATION_BORDER_BLUE = '#bce8f1',
    NOTIFICATION_ICON_BLUE = '#31708f',
    WARNING_BACKGROUND_YELLOW = '#fcf8e3',
    WARNING_BORDER_YELLOW = '#faebcc',
    WARNING_ICON_YELLOW = '#8a6d3b',
    SUCCESS_BORDER_GREEN = '#d6e9c6',
    SUCCESS_BACKGROUND_GREEN = '#dff0d8',
    SUCCESS_ICON_GREEN = '#3c763d'
}

export enum AlertIconClass {
    ERROR_ICON = 'fa-circle-xmark',
    WARNING_ICON = 'fa-triangle-exclamation',
    NOTIFICATION_ICON = 'fa-circle-info',
    SUCCESS_ICON = 'fa-circle-check'
}

export enum AlertMessages {
    ERROR_FAILED_REQUEST = 'Strategy Illustration failed',
    REPLACE_STRATEGY = 'By adding this strategy, the current strategy on the account will be removed.',
    ERROR_RETRY_FAILED = 'Strategy Illustration failed again. Please contact your SEI service representative.',
    WARNING_MODIFIED_FORM = 'This scenario has been modified. To view updated analytics, click \'Calculate Values\' again.'
}

export enum ToolTipMessages {
    STANDARD_INFLATION_RATE = 'Standard inflation rate is approximately 3%.',
    RECALCULATE_TO_CONTINUE = 'You must recalculate before adding this strategy.'
}
