/*
 * Copyright: This information constitutes the exclusive property of SEI
 * Investments Company, and constitutes the confidential and proprietary
 * information of SEI Investments Company.  The information shall not be
 * used or disclosed for any purpose without the written consent of SEI
 * Investments Company.
 */

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertStatus } from '../model/enums';
import { Alert, AlertOptions, AlertStyling } from './model/alert-styles';

@Component({
    selector: 'sei-car-dfs-alert-notification',
    templateUrl: './alert-notification.component.html',
    styleUrls: ['./alert-notification.component.scss'],
    standalone: true,
    imports: [CommonModule]
})
export class AlertNotificationComponent {

    @Input() public set alertOptions(value: AlertOptions) {
        this._alertOptions = value;
        this.setAlertAccentColorsBasedOnAlertStatus(value?.status);
    }

    public get alertOptions(): AlertOptions {
        return this._alertOptions;
    }

    @Output() public refreshClicked: EventEmitter<boolean> = new EventEmitter();

    public alertStyling: AlertStyling;
    private _alertOptions: AlertOptions;

    public onRefreshClick(): void {
        this.refreshClicked.emit(true);
    }

    private setAlertAccentColorsBasedOnAlertStatus(status: string): void {
        switch (status) {
            case AlertStatus.ERROR:
                this.alertStyling = Alert.error;
                break;
            case AlertStatus.WARNING:
                this.alertStyling = Alert.warning;
                break;
            case AlertStatus.SUCCESS:
                this.alertStyling = Alert.success;
                break;
            default:
                this.alertStyling = Alert.default;
                break;
        }
    }

}
